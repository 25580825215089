import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import validator from 'validator';
import productListActions from '../../redux/productList/actions';
import listCategoriesActions from '../../redux/listCategories/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';
import Select from 'react-select';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	padding: grid * 2,
	margin: `0`,
	background: isDragging ? "#0061bd" : "white",
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "lightgrey" : "white",
	//padding: grid,
	width : 'auto',
	padding: 0
});

const getItemClass = isDragging => {
	return isDragging ? 'draggable__item draggable__item--dragging' : 'draggable__item';
}


class ListDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			categories: {},
			categoryName: "",
			isDragDisabled: false,
			removalPopup : false
		}
	}

    componentDidMount() {
		if(this.props.match.params.id && this.props.productLists) {
			this.initData();
		}
    }

	componentDidUpdate(prevProps, prevState) {
		if (this.props.productLists !== prevProps.productLists) {
			this.initData();
		}
	}

	initData = () => {
		const { productLists } = this.props;
		const listIdParam = this.props.match.params.id;
		const productList = productLists.find(item => item.id === listIdParam);

		if( productList ) {
			this.setState({
				listId: listIdParam,
				productList: productList,
				categories: productList.categories ? productList.categories : {}
			});
		} else {

			// @TODO Reroute user
		}
	}

	_changeConfigurationQuantity = (value, configuration) => {
		console.log(this.state.categories);
		if ( value > 0 ) {
			let configurations = [...this.state.productList.configurations];
			let index = configurations.findIndex(element => configuration.id === element.id);
			configurations[index].quantity = value;

			let categories = this.state.categories;
			for ( const categoryID in categories ) {
				if ( categories.hasOwnProperty(categoryID) ) {
					if ( categories[categoryID].configurations ) {
						if ( categories[categoryID].configurations.length ) {
							const categoryIndex = categories[categoryID].configurations.findIndex(element => configuration.id === element.id);
							if (typeof categoryIndex == 'number' && categoryIndex >= 0 ) {

								categories[categoryID].configurations[categoryIndex].quantity = value;
							}
						}
					}
				}
			}

			this.setState({
				...this.state,
				categories : categories,
				productList : {
					...this.state.productList,
					configurations: configurations,
					categories : categories
				}
			})

			this.props.actions.updateConfigurationsInList({
				listId: this.state.listId,
				configurations : configurations,
				categories : categories,
				type : 'quantity'
			})
		}
	}

	_removeConfigurationFromList = (e, configuration) => {
		const configurations = this.state.productList.configurations || [];
		let index = configurations.findIndex(element => configuration.id === element.id);
		let newConfigurations = [];
		for ( let i = 0; i <= configurations.length; i ++ ) {
			if ( configurations[i] ) {
				if ( index !== i ) {
					newConfigurations.push(configurations[i]);
				}
			}
		}

		let categories = {...this.state.categories};
		for ( const categoryID in categories ) {
			if ( categories.hasOwnProperty(categoryID) ) {
				if ( categories[categoryID].configurations ) {
					if ( categories[categoryID].configurations.length ) {
						const categoryIndex = categories[categoryID].configurations.findIndex(element => configuration.id === element.id);
						if (typeof categoryIndex == 'number' && categoryIndex >= 0 ) {
							let newCategoryConfigurations = [];

							for ( let i = 0; i <= categories[categoryID].configurations.length; i ++ ) {
								if ( categories[categoryID].configurations[i] ) {
									if ( categories[categoryID].configurations[i].id !== configuration.id ) {
										newCategoryConfigurations.push(categories[categoryID].configurations[i]);
									}
								}
							}
							categories[categoryID].configurations = newCategoryConfigurations;
						}
					}
				}
			}
		}

		this.setState({
			...this.state,
			categories : categories,
			productList : {
				...this.state.productList,
				configurations: configurations
			}
		})
		this.props.actions.updateConfigurationsInList({
			listId: this.state.listId,
			configurations : newConfigurations,
			categories : categories,
			type : 'removal'
		})
	}

	_toggleRemovalPopup = (itemId) => {
		console.log(itemId);
		this.setState({
			...this.state,
			removalPopup : itemId
		})
	}

	formatItem = (item) => {
		const configurations = this.state.productList.configurations || [];
		const configuration = configurations.find(element => item.id === element.id);
		if ( !configuration ) {
			return false
		}
		return(
			<React.Fragment>
				<div className="lists__detail__overview__category__item">
					<span className="lists__detail__overview__category__item__title">
						<div className="title">{item.ch}</div>
						<div className="delete" onClick={(e) => this._toggleRemovalPopup(item.id)}></div>
						<div className={"removal-confirmation " + (this.state.removalPopup === item.id ? 'visible' : '')} >
							<div>{__.fs('Lists.ListDetail.remove-configuration-confirm')}</div>
							<button onClick={(e) => this._removeConfigurationFromList(e, configuration)}>{__.fs('Lists.ListDetail.remove-configuration-yes')}</button>
							<button onClick={(e) => this._toggleRemovalPopup(false)}>{__.fs('Lists.ListDetail.remove-configuration-no')}</button>
						</div>
					</span>
					<span className="lists__detail__overview__category__item__products">{item.products.join(', ')}</span>
					<span className="lists__detail__overview__category__item__quantity">{__.fs('Lists.ListDetail.quantity')}
						<div className="quantity-grid">
							<input type="number" value={configuration.quantity} onChange={(e) => this._changeConfigurationQuantity(e.target.value, configuration)} />
							<div className="plus" onClick={() => this._changeConfigurationQuantity((parseInt(configuration.quantity) + 1) , configuration)}></div>
							<div className={"minus " + (configuration.quantity === 1 ? 'disabled' : '')} onClick={() => this._changeConfigurationQuantity((parseInt(configuration.quantity) - 1) , configuration)}></div>
						</div>
					</span>
				</div>
			</React.Fragment>
		);
	}

	mapCategories = () => {
		const { listCategories } = this.props;
		let categories = [];

		if(listCategories){
			categories = Object.keys(listCategories).map(key => {
				return {
					value: key,
					label: listCategories[key].name
				};
			});
		}

		return categories;
	}

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSelectChange = (selectedOption) => {
		this.setState({
			selectedCategory: selectedOption
		});
	}

	reorderLists = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	moveBetweenLists = (source, destination, droppableSource, droppableDestination) => {
	    const sourceClone = Array.from(source);
	    const destClone = Array.from(destination);
	    const [removed] = sourceClone.splice(droppableSource.index, 1);

	    destClone.splice(droppableDestination.index, 0, removed);

	    const result = {};
	    result[droppableSource.droppableId] = sourceClone;
	    result[droppableDestination.droppableId] = destClone;

	    return result;
	}

	onDragEnd = (result) => {

		const { source, destination } = result;
		let categories = {...this.state.categories};

        //-- User dropped item outside of list
        if (!destination) {
            return;
        }

        // -- Item moved within list
        if (source.droppableId === destination.droppableId) {

            const items = this.reorderLists(
                categories[source.droppableId].configurations,
                source.index,
                destination.index
            );

            categories[source.droppableId].configurations = items;

        }

        // -- Item moved between lists
        else {
            const result = this.moveBetweenLists(
                categories[source.droppableId].configurations,
                categories[destination.droppableId].configurations,
                source,
                destination
            );

            categories[source.droppableId].configurations = result[source.droppableId];
            categories[destination.droppableId].configurations = result[destination.droppableId];

        }

        if(this.state.productList.locked === false){
	        this.props.actions.updateProductCategories({
	        	categories: categories,
	        	listId: this.state.listId
	        });
        }

        this.setState({
        	categories
        });

	}

	addCategory = () => {
		let error = false;
		const nameIsValid = !validator.isEmpty(this.state.categoryName);
		const { listCategories } = this.props;

		const category = Object.keys(listCategories).find(key => {
			return listCategories[key].name.toLowerCase() === this.state.categoryName.toLowerCase();
		});

		if(category){
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.ListDetail.category-exists'),
				type: 'error'
			})
		}

		if(!nameIsValid) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.ListDetail.add-name'),
				type: 'error'
			})
		}

		if(!error){
			this.props.actions.addListCategoryFirebase({
				name: this.state.categoryName
			});
		}
	}

	addCategoryToList = () => {
		if(this.state.selectedCategory && this.state.selectedCategory.value){
			this.props.actions.addCategoryToList({
				category: this.state.selectedCategory,
				listId: this.state.listId
			});
		}
	}

	renderDroppableCategory = (categoryId, items) => {
		return(
			<Droppable droppableId={categoryId}>
				{(provided, snapshot) => (
					<div

						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
					<div className={"container lists__detail__overview__container" + (items.length === 0 ? ' lists__detail__overview__container--empty' : '' )}>

						{items.map((item, index) => (
						<Draggable key={categoryId + "_" + item.id} draggableId={categoryId + "_" + item.id} index={index} isDragDisabled={this.state.isDragDisabled}>
							{(provided, snapshot) => (
								<div
									className={getItemClass(snapshot.isDragging)}
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									style={getItemStyle(
									snapshot.isDragging,
									provided.draggableProps.style
									)}
								>
									{this.formatItem(item)}
								</div>
							)}
						</Draggable>
						))}
						{provided.placeholder}

					</div>
					</div>
				)}
			</Droppable>
		);
	}

	render() {
		return (
			<div className="lists__detail">
				<div className="container">
					<h1 style={{marginBottom: '30px'}}>{__.fs('Lists.ListDetail.product-list')}: {this.state.productList ? this.state.productList.name : ""}</h1>

					<div className="lists__detail__category-forms">
		      			<div className="lists__detail__category-forms__add-new">
				      		<h2 >{__.fs('Lists.ListDetail.add-new-category')}</h2>
				      		<input type="text" onChange={this.handleInputChange} id="categoryName" name="categoryName" placeholder={__.fs('Lists.ListDetail.name')} />
							<button className="button"  onClick={this.addCategory} type="submit">{__.fs('Lists.ListDetail.add')}</button>
		      			</div>
		      			<div className="lists__detail__category-forms__add-category">
		      				<h2>{__.fs('Lists.ListDetail.add-category-to-list')}</h2>
				      		<div className="form-group" >
								<Select
									onChange={this.handleSelectChange}
									options={this.mapCategories()}
									placeholder={__.fs('Lists.ListDetail.select-category')}
								/>
							</div>
							<button className="button" onClick={this.addCategoryToList} type="submit">{__.fs('Lists.ListDetail.add')}</button>
		      			</div>
		      		</div>
		      	</div>
      			<div className="lists__detail__overview">
					<DragDropContext onDragEnd={this.onDragEnd}>
		      			<div className="lists__detail__categories">
							{Object.keys(this.state.categories).map((key, index) => (
								<div key={key} className="lists__detail__overview__category" style={{marginTop: '30px'}}>
									<div className="container">
										<h2 className="lists__detail__category__title">{this.state.categories[key].name || __.fs('Lists.ListDetail.without-category')}</h2>
									</div>
									{this.state.categories[key] && this.renderDroppableCategory(key, this.state.categories[key].configurations)}
								</div>
							))}
		      			</div>
					</DragDropContext>
      			</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
    return {
    	defaultSettings : state.DefaultSettings,
    	user : state.User,
    	productLists: state.ProductLists.productLists,
    	listCategories: state.ListCategories.listCategories
    }
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({...productListActions, ...listCategoriesActions, ...messageActions}, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListDetail))
