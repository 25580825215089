import React from 'react';
import __ from '../../../helpers/language';
const AdjustFrequency = (props) => {
    return (
        <div className={"single__configuration__frequency"}>
            <div className="input">
                <label className="input__label">{__.fs('Configuration.GraphsComponents.AdjustFrequency.frequence')}</label>
                <input className="input__field" type="number" onChange={props.changeInput}  value={props.defaultValue} />
            </div>
        </div>
    )
}

export default AdjustFrequency;
