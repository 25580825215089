import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


import actions from '../../redux/configurations/actions';
import {bindActionCreators} from 'redux';

class MakeFilterTree extends Component {

    componentDidMount() {
            this.props.actions.buildFullFilterTree();
    }

    render() {
        return (
          	<div>

       		</div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
    	configurations : state.Configurations.configurations,
        defaultSettings: state.User.default_settings,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MakeFilterTree))
