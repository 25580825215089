import { round, findClosestQ } from '../graphCalculationHelpers';
import React from 'react';
import CustomizedRect from './GraphComponents/CustomizedRect';

class PowerSeries {
    constructor(ratedPolynomialValues, variablePolynomialValues, returnQOpt, QH) {
        this._returnQOpt = returnQOpt;
        this.QH = QH;

        this.ratedPolynomialValues = ratedPolynomialValues;
        this.variablePolynomialValues = variablePolynomialValues;
    }

    _getPowerValues = () => {

        return {
            rated : this.ratedPolynomialValues.map(this._mapValues),
            variable : this.variablePolynomialValues.map(this._mapValues)
        }
    }

    _mapValues = (item, key) => {
        return {
            q : parseFloat(item.q),
            p: parseFloat(item.p),
        }
    }

    _getPowerSeries = (actualDutyPoint) => {

        let returnSeries = [
            {
                name: 'Rated P2',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 1,
                data: this._getPowerValues().rated
            },
            {
                name: 'Variable P2',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 2,
                data: this._getPowerValues().variable
            }
        ];

        if ( this._returnQOpt ) {
            const optSeries = this._getOptValues();
            if ( optSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        q : optSeries.q,
                        p : parseFloat(optSeries.p)
                    }]
                })
            }

        }

        if ( actualDutyPoint ) {
            const actualSeries = this._getActualValues(actualDutyPoint);
            if ( actualSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        q : parseFloat(actualSeries.q),
                        p : parseFloat(actualSeries.p)
                    }]
                })
            }
        }

        return returnSeries;
    }

    _getPowerOpt = (actualDutyPoint) => {
        if ( actualDutyPoint ) {
            const actualSeries = this._getActualValues(actualDutyPoint);
            if ( actualSeries ) {
                return actualSeries.p;
            }

            return false;
        }

        return false;
    }

    _getActualValues = (actualDutyPoint) => {

       if( actualDutyPoint.q ) {

           const values = this._getPowerValues().variable;
           const index = findClosestQ(actualDutyPoint, 'q', values);

           return {
               q : values[index].q,
               p : values[index].p
           }
       }
    }



    _getOptValues = ( isQH = false) => {
        const QHOpt = this.QH._getQHOpt ( true );
        if ( !QHOpt ) {
            return false;
        }

        if( QHOpt.q ) {
            for ( let i = 0; i <= this._getPowerValues().rated.length; i++ ){
                if ( this._getPowerValues().rated[i] ) {
                    if ( round(parseFloat(this._getPowerValues().rated[i].q), 2) === round(QHOpt.q, 1) ) {
                        return {
                            q : this._getPowerValues().rated[i].q,
                            p : this._getPowerValues().rated[i].p
                        }
                    }
                }
            }
        }
    }
}

export default PowerSeries;
