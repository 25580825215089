import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import validator from 'validator';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import __ from '../../helpers/language';
import productListActions from '../../redux/productList/actions';
import messageActions from '../../redux/message/actions';
import ConfirmPopup from '../Common/ConfirmPopup';
import PriceRequestPopup from './PriceRequestPopup';
import OrderRequestPopup from './OrderRequestPopup';
const getItemStyle = (isDragging, draggableStyle, invert) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	margin: 0,

	background: isDragging ? '#0072df' : (invert ? 'white' : '#f8f8f8'),
	color : isDragging ? 'white' : 'black',

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "transparent" : "transparent",
	padding: 0,
	width : '100%'
});

class Lists extends Component {

	state = {
		items: [],
		filteredItems: [],
		listName: "",
		searchTerm: "",
		isDragDisabled: false,
		itemToDelete : false,
    	priceRequestPopup: {
    		visible: false
		},
		exportType : 'CSV',
		toggleOrderPopUp:  {
			visible: false
		}
	};

    componentDidMount() {
    	if (this.props.productLists) {
    		this.setItems();
    	}
    }

	componentDidUpdate(prevProps) {
		if (this.props.productLists !== prevProps.productLists) {
			this.setItems();
		}
	}

	setItems = () => {
		const items = this.prepareItems(this.props.productLists);


		this.setState({
			items: items,
			filteredItems: items
		});
	}

	changeExportType = (e, key) => {

		e.persist();
		this.setState({
			...this.state,
			exportType : {
				...this.state.exportType,
				[key] : e.target.value
			}
		})
	}

	prepareItems = (items) => {
		Object.keys(items).map(key => {

			items[key].content =
				<div className="container">
					<div className="lists__overview__list">
						<span className="lists__overview__list__title"> {items[key].name} <br /> {items[key].configurations ? items[key].configurations.length : 0} {__.fs('Lists.index.products')}</span>
						<form target="_blank"  method="POST" action={global.serverURL + "list/download"}>
							<input type="hidden" name="listdata" value={JSON.stringify(items[key])} />
							<i className="fa fa-arrow-down"></i>
							<select name="exportAs" onChange={(e) => this.changeExportType(e, key)} defaultValue={(this.state.exportType[key] ? this.state.exportType[key] : 'CSV')}>
								<option value="CSV">CSV</option>
								<option value="XLS">Excel</option>
							</select>
							<button type="submit" target="_BLANK" className="lists__overview__list__button" >  {__.fs('Lists.index.export')}</button>
						</form>
						{items[key].locked === false ?
							(<React.Fragment>
								<span className={"lists__overview__list__button"} onClick={e => this.deleteItem(e, items[key].id)}><i className="fa fa-times"></i>  {__.fs('Lists.index.delete')}</span>
								{ (items[key].configurations && items[key].configurations.length && items[key].locked === false ) ? <NavLink to={'/profile/lists/'+ items[key].id}><span className={"lists__overview__list__button"}><i className="fa fa-pencil-alt"></i>  {__.fs('Lists.index.edit')}</span></NavLink> : <span className={"lists__overview__list__button lists__overview__list__button--disabled"}><i className="fa fa-pencil-alt"></i>  {__.fs('Lists.index.edit')}</span>}
								<span className="lists__overview__list__button" onClick={e => this._togglePriceRequestPopup(e, items[key])}><i className="fa fa-tasks"></i>  {__.fs('Lists.index.price-request')}</span>
							</React.Fragment>)
						:
							<React.Fragment>
								<span className={"lists__overview__list__button lists__overview__list__button--disabled"}><i className="fa fa-times"></i>  {__.fs('Lists.index.delete')}</span>
								{ (items[key].configurations && items[key].configurations.length && items[key].locked === false) ? <NavLink to={'/profile/lists/'+ items[key].id}><span className={"lists__overview__list__button"}><i className="fa fa-pencil-alt"></i>  {__.fs('Lists.index.edit')}</span></NavLink> : <span className={"lists__overview__list__button lists__overview__list__button--disabled"}><i className="fa fa-pencil-alt"></i>  {__.fs('Lists.index.edit')}</span>}
								<span className="lists__overview__list__button lists__overview__list__button--disabled"><i className="fa fa-tasks"></i>  {__.fs('Lists.index.price-request')}</span>
							</React.Fragment>
						}
						{(items[key].can_be_ordered === true && items[key].configurations && items[key].configurations.length)&&
							<span className="lists__overview__list__button" onClick={e => this._toggleOrderPopUp(e, items[key])}><i className="fa fa-shopping-cart"></i>  {__.fs('Lists.index.order')}</span>
						}
					</div>
				</div>;
			return key;
		});

		return items;
	}

	// reorder items
	reorderLists = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	deleteItem = (e, listId) => {
		e.preventDefault();
		this.setState({
			itemToDelete : listId
		})
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = this.reorderLists(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.props.actions.changeListOrderFirebase(items);

		this.setState({
			items: items,
			filteredItems: items
		});
	}

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	filterItems = (event) => {

		const searchTerm = event.target.value;
		let items = [...this.state.items];
		let isDragDisabled = false;

		if(event.target.value){
			items = items.filter(item => {
				return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
			});

			isDragDisabled = true;
		}

		this.setState({
			filteredItems: items,
			isDragDisabled
		});
	}

	addList = () => {
		let error = false;
		let items = [...this.state.items];
		const name = this.state.listName;
		const nameIsValid = !validator.isEmpty(name);

		const item = items.find(item => {
			return item.name.toLowerCase() === name.toLowerCase()
		});

		if(!nameIsValid) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.ListOverview.add-name-for-list'),
				type: 'error'
			})
		}

		if(item) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.ListOverview.list-already-exists'),
				type: 'error'
			})
		}

		if(!error){
			this.props.actions.addListFirebase({name: name});
		}
	}

	_deleteList = (confirmed) => {

		if ( confirmed ) {
			const listId = this.state.itemToDelete;
			this.props.actions.deleteList({listId});
		}

		this.setState({
			itemToDelete : false
		})
	}

	_togglePriceRequestPopup = (e, list) => {

		e.preventDefault();

		this.setState({
			priceRequestPopup : {
				visible : true,
				title : `Prijs/levertijd verzoek - ${list.name}`,
				listId: list.id
			}
		})
	}

	_toggleOrderPopUp = (e, list) => {

		e.preventDefault();

		this.setState({
			toggleOrderPopUp : {
				visible : true,
				title : `Lijst bestellen - ${list.name}`,
				list: list
			}
		})
	}

	_closePriceRequestPopup = (e) => {
		this.setState({
			priceRequestPopup : {
				visible: false
			}
		})
	}
	_closeOrderPopup = (e) => {
		this.setState({
			toggleOrderPopUp : {
				visible: false
			}
		})
	}

	render() {

		let invert = false;
      	return(
      		<div className="lists">
      			<div className="container">
	      			<div className="input" style={{marginBottom: '30px'}}>
			      		<label className="input__label">{__.fs('Lists.index.add-list')}</label>
						<input type="text" onChange={this.handleInputChange} id="listName" name="listName" className="input__field" placeholder={__.fs('Lists.index.name')} />
						<button style={{marginLeft: '20px'}} className="button" onClick={this.addList} type="submit">{__.fs('Lists.index.add')}</button>
	      			</div>
	      			<div className="input" style={{marginBottom: '30px'}}>
		      			<label className="input__label">{__.fs('Lists.index.search-list')}</label>
						<input type="text" onChange={this.filterItems} id="name" className="input__field" name="name" placeholder={__.fs('Lists.index.name')} />
	      			</div>
	      		</div>
      			<div className="lists__overview">
  					<div className="lists__overview__draggable">
  					{this.state.filteredItems.length ?
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									className="lists__overview__draggable__wrap"
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{this.state.filteredItems.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={this.state.isDragDisabled}>

											{(provided, snapshot) => (
													<div
														className={"lists__overview__draggable__row " + (snapshot.isDragging ? 'lists__overview__draggable__row--drag' : '')}
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style,
														!invert
														)}
													>
														{item.content}
														{invert = !invert}
													</div>
											)}

									</Draggable>
								))}
								{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					:
					<div className="lists__overview__draggable__wrap">
						<div className="lists__overview__draggable__row ">
							<div className="container">
								<div className="lists__overview__list">
									<span className="lists__overview__list__title"> {__.fs('Lists.index.no-results')} </span>
									</div>
								</div>
							</div>
						</div>


					 }
					</div>
      			</div>
      			{this.state.itemToDelete ? <ConfirmPopup denyButton={__.fs('Lists.index.cancel')} confirmButton={__.fs('Lists.index.confirm')} title={__.fs('Lists.index.are-you-sure')} confirm={() => this._deleteList(true)} deny={() => this._deleteList(false)}/> : null }
                {this.state.priceRequestPopup.visible ?
                	<PriceRequestPopup closePopup={this._closePriceRequestPopup} data={this.state.priceRequestPopup} />
                : null }
				 {this.state.toggleOrderPopUp.visible ?
                	<OrderRequestPopup closePopup={this._closeOrderPopup} data={this.state.toggleOrderPopUp} />
                : null }
      		</div>
      	);
	}
}


const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token ? true : false,
        productLists: state.ProductLists.productLists
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...messageActions, ...productListActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lists))
