import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getToken } from '../../helpers/utility';
import actions from './actions';
import messageActions from '../message/actions';
import FirebaseHelper from '../../helpers/firebase';
import __ from '../../helpers/language';
const { rsfFirestore } = FirebaseHelper;


export function* requestOrder() {
	yield takeEvery(actions.REQUEST_ORDER, function*(action) {

		const { reference, delivery_date, company, street_number, zipcode_city, country, listConfigurations, listId } = action.payload;
		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		if(user_id){
			let order_request_data = {
				reference,
				delivery_date,
				company,
				street_number,
				zipcode_city,
				country,
			}

			yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'ordered', true);
			yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, order_request_data);

			order_request_data["listConfigurations"] = listConfigurations;
			try {
				yield call(fetch, global.serverURL +'order_request/sendRequest', {
					'method': 'POST',
					body: JSON.stringify(order_request_data)
				});


			    yield put({
					type: messageActions.MESSAGE,
					payload: {
						message: __.fs('redux.orderList.saga.order-place-successfully'),
						type: 'success'
					}
			    });
			}
			catch(error) {
				yield put({
					type: messageActions.MESSAGE,
					payload: {
						message: __.fs('redux.orderList.saga.something-went-wrong'),
						type: 'error'
					}
			    });
			}

		}

	});
}

export default function* rootSaga() {
	yield all([
		fork(requestOrder),
		//fork(syncPriceRequestsCollection)
	]);
}
