import actions from './actions';

const initState = { products: [] };

export default function ProductsReducer(state = initState, action) {
	switch (action.type) {		
		case actions.SET_PRODUCTS:
		return { 
				...state, 
				products: action.payload
			};	
		default:
			return state;
	}
}
 