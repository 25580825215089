import actions from './actions';
import { REHYDRATE } from 'redux-persist/lib/constants';
const initState = {
	user: null,
	user_profile: {

	},
	clientID : false,
	logged_in : false,

	duty_point : {
		q : false,
		h : false,
	},

	filter_groups : [],

	default_settings : {
		kwh_electricity: 0.23,
		water_usage : 65,
		frequency : {
			label : '50Hz',
			value : '50Hz',
		},

		motor_poles : {
			label : '2 Polen',
			value : '2P',
		},
		language : 'NL',
		units : {
			Q : "M",
			H : "M"
		}
	},

	user_profile_data : {
		company : '',
		street_number : '',
		zipcode_city : '',
		country : ''
	}
};

export default function userReducer(state = initState, action) {
	switch (action.type) {
		case REHYDRATE:
           return action.payload ? action.payload.defaultSettingsReducer ? action.payload.defaultSettingsReducer : state : state;

		case actions.SET_USER_PROFILE_DATA:
			return {
				...state,
				user_profile_data : {
					...action.payload
				}
			}
		case actions.SET_SETTINGS:
			return {
				...state,
				default_settings : {
					...state.default_settings,
					...action.payload
				}
			}
		case actions.SET_CLIENT_ID:
			return {
				...state,
				clientID : action.payload
			}
		case actions.SET_LOW_NPSH:
			return {
				...state,
				low_npsh : action.payload
			}

		case actions.SET_USER_PROFILE:
			return {
				...state,
				user_profile: action.payload

			}

		case actions.SET_USER_LOGGED_IN:
			return {
				...state,
				logged_in : action.payload
			}

		case actions.SET_TCO_VALUE:
			return {
				...state,
				[action.payload.field] : action.payload.value
			}

		case actions.SET_DUTY_POINT:
			return {
				...state,
				duty_point : action.payload
			}

		case actions.ADD_FILTER_GROUP:

			let filter_groups = state.filter_groups;
			filter_groups.push(action.payload);
			return {
				...state,
				filter_groups
			}

		case actions.SET_FILTER_GROUPS:
			return {
				...state,
				filter_groups : action.payload
			}

		case actions.LOGOUT:
			return initState;


		default:
			return state;
	}
}
