import actions from './actions';

const initState = { message: null, type: null };

export default function messageReducer(state = initState, action) {

	switch (action.type) {

		case actions.MESSAGE:
			return { 
				...state, 
				message: action.payload.message,
				type: action.payload.type
			};
		default:
			return state;
	}
}
