import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageHeaderWithContent from '../Common/PageHeaderWithContent';
import authActions from '../../redux/auth/actions';
import __ from '../../helpers/language';
import messageActions from '../../redux/message/actions';

class ForgotPassword extends Component {

	state = {
		email: "",
	}

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	submitForm = (e) => {
		if( e ) {
			e.preventDefault();
		}
		let error = false;
		const emailIsValid = validator.isEmail(this.state.email);

		if(!emailIsValid) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Authenticate.index.e-mail-invalid'),
				type: 'error'
			})
		}

		if(!error){
			this.props.actions.resetPassword(this.state);
		}
	}

	render() {
      	return(
      		<div className="loginpage">
      			<PageHeaderWithContent title={__.fs('ForgotPassword.index.title')} alignCenter={true}>
      				<form className="loginform" onSubmit={this.submitForm}>
			      		<div className="container">
			      			<div className="loginform__group">
				      			<label>{__.fs('ForgotPassword.index.emailaddress')}</label>
								<input type="text" onChange={this.handleInputChange} id="email" name="email" placeholder={__.fs('ForgotPassword.index.emailaddress')} />
							</div>
							<button className="button" onClick={this.submitForm} type="submit">{__.fs('ForgotPassword.index.submit')}</button>
			      		</div>
			      	</form>
      			</PageHeaderWithContent>

      		</div>
      	);
	}
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...authActions, ...messageActions}, dispatch) })

export default withRouter(connect(false, mapDispatchToProps)(ForgotPassword))
