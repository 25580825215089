import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../redux/user/actions';
import __ from '../../helpers/language';
import { convertHeadToOtherUnit, convertQToOtherUnit } from '../../helpers/conversion';

class Settings extends Component {

    state = {
    	defaultSettings : {
    		frequency : null,
    		motor_poles : null,
            units : {
                Q : 'M',
                H : 'M'
            }
    	}
    }

    componentDidMount() {
    	this.setState({
    		defaultSettings : {
    			frequency : this.props.defaultSettings.frequency.value,
    			motor_poles : this.props.defaultSettings.motor_poles.value,
    			language : this.props.defaultSettings.language,
                units : {
                    Q : this.props.defaultSettings.units.Q,
                    H : this.props.defaultSettings.units.H
                }
    		}
    	})
    }

    componentDidUpdate(prevProps) {
    	if(this.props.defaultSettings !== prevProps.defaultSettings ) {

    		this.setState({
	    		defaultSettings : {
	    			frequency : this.props.defaultSettings.frequency.value,
	    			motor_poles : this.props.defaultSettings.motor_poles.value,
	    			language : this.props.defaultSettings.language,
                    units : {
                        Q : this.props.defaultSettings.units.Q,
                        H : this.props.defaultSettings.units.H
                    }
	    		}
	    	})
    	}

        //if(this.props.defaultSettings !== prevProps.defaultSettings) {
    }

    _setSetting = (property, value) => {
    	this.setState({
    		defaultSettings : {
    			...this.state.defaultSettings,
    			[property] : value
    		}
    	})
    }

    setUnit = (e, unit) => {
        this.setState({
            ...this.state,
            defaultSettings : {
                ...this.state.defaultSettings,
                units : {
                    ...this.state.defaultSettings.units,
                    [unit] : e.target.value
                }
            }

        })
    }

    _updateSettings = () => {
        console.log(this.state);
    	this.props.actions.updateSettings(this.state.defaultSettings);
    }

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

    _mapProperties = (item, key, unit) => {
        return (
            <option value={key}>{item.label}</option>
        )
    }

	render() {
        console.log(this.props);
      	return(
      		<div className="settings">

	      		<div className="container">
		      		<div className="settings">
		   				<ul className="settings__list settings__list--inline">
		   					<li className="settings__list__label">{__.fs('Settings.index.frequence')}</li>
		   					<li onClick={() => this._setSetting('frequency', '50Hz')} className={"settings__list__item " + (this.state.defaultSettings.frequency === '50Hz' ? 'settings__list__item--active' : null)}>
								<span>50Hz</span>
							</li>
							<li onClick={() => this._setSetting('frequency', '60Hz')} className={"settings__list__item " + (this.state.defaultSettings.frequency === '60Hz' ? 'settings__list__item--active' : null)}>
								<span>60Hz</span>
							</li>
		   				</ul>
		   				{/*}
		   				<ul className="settings__list settings__list--inline">
		   					<li className="settings__list__label">{__.fs('Settings.index.frequence')}</li>
		   					<li onClick={() => this._setSetting('height', 'feet')} className={"settings__list__item " + (this.state.defaultSettings.height === 'feet' ? 'settings__list__item--active' : null)}>
								<span>Feet</span>
							</li>
							<li onClick={() => this._setSetting('height', 'meters')} className={"settings__list__item " + (this.state.defaultSettings.height === 'meters' ? 'settings__list__item--active' : null)}>
								<span>Meters</span>
							</li>
		   				</ul>*/}

		   				<ul className="settings__list settings__list--inline">
		   					<li className="settings__list__label">{__.fs('Settings.index.number-of-poles')}</li>
		   					<li onClick={() => this._setSetting('motor_poles', '2P')} className={"settings__list__item " + (this.state.defaultSettings.motor_poles === '2P' ? 'settings__list__item--active' : null)}>
								<span>2 {__.fs('Settings.index.poles')} </span>
							</li>
							<li onClick={() => this._setSetting('motor_poles', '4P')} className={"settings__list__item " + (this.state.defaultSettings.motor_poles === '4P' ? 'settings__list__item--active' : null)}>
								<span>4 {__.fs('Settings.index.poles')} </span>
							</li>
		   				</ul>

		   				<ul className="settings__list settings__list--inline">
		   					<li className="settings__list__label">{__.fs('Settings.index.language')}</li>
		   					<li onClick={() => this._setSetting('language', 'NL')} className={"settings__list__item " + (this.state.defaultSettings.language === 'NL' ? 'settings__list__item--active' : null)}>
								<span>{__.fs('Settings.index.dutch')} </span>
							</li>
							<li onClick={() => this._setSetting('language', 'EN')} className={"settings__list__item " + (this.state.defaultSettings.language === 'EN' ? 'settings__list__item--active' : null)}>
								<span>{__.fs('Settings.index.english')} </span>
							</li>
		   				</ul>

                        <ul className="settings__list settings__list--inline">
		   					<li className="settings__list__label">{__.fs('Settings.index.units')}</li>
		   					<li className={"settings__list__select "}>
								<span>{__.fs('Settings.index.units-Q')} </span>
                                <select onChange={(e) => this.setUnit(e, 'Q')} value={this.state.defaultSettings.units.Q}>
                                    { Object.keys(convertQToOtherUnit).map((key) => this._mapProperties(convertQToOtherUnit[key], key, 'Q'))}
                                </select>
							</li>
							<li className={"settings__list__select "}>
								<span>{__.fs('Settings.index.units-H')} </span>
                                <select onChange={(e) => this.setUnit(e, 'H')} value={this.state.defaultSettings.units.H}>
                                    { Object.keys(convertHeadToOtherUnit).map((key) => this._mapProperties(convertHeadToOtherUnit[key], key, 'H'))}
                                </select>
							</li>
		   				</ul>

		   				<button className="button" onClick={this._updateSettings} type="submit">{__.fs('Settings.index.save')} </button>
		   			</div>
		   		</div>
		   	</div>
      	);
	}
}


const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token ? true : false,
        defaultSettings : state.User.default_settings
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings))
