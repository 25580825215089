import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import userActions from '../user/actions';
import messageActions from '../message/actions';
import FirebaseHelper from '../../helpers/firebase';
import { errorCodes } from '../../helpers/auth/errorMessage';
import listActions from '../productList/actions';
import compareActions from '../compare/actions';
import _ from 'lodash/collection';
import __ from '../../helpers/language';
const { rsf } = FirebaseHelper;

export function* loginRequest() {

	yield takeEvery(actions.LOGIN_REQUEST, function*(action) {
		const { email, password, custom_token } = {...action.payload};
		let error = false;
		let loginMessage = {
			type: messageActions.MESSAGE,
			payload: {
				message: null,
				type: null
			}
		}


		let promise = null;
		if(email && password) {
			promise = yield FirebaseHelper.login(FirebaseHelper.EMAIL, { email, password }).catch(result => {
				error = true
				loginMessage.payload = errorCodes[result.code];
			});
		}

		if(custom_token) {

			promise = yield FirebaseHelper.login(FirebaseHelper.CUSTOM_TOKEN, { custom_token }).catch(result => {
				error = true
				loginMessage.payload = errorCodes[result.code];
			});
		}

		if(!error && promise) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				user_id: promise.user.uid,
				user: promise.user
			});

			loginMessage.payload = {
				message: __.fs('redux.auth.saga.login-success'),
				type: 'success'
			}

			yield put(push('/'));
		}

		yield put(loginMessage);

	});
}


export function* resetPassword() {

	yield takeEvery(actions.RESET_PASSWORD, function*(action) {
		const { email } = {...action.payload};
		let resetPasswordMessage = {
			type: messageActions.MESSAGE,
			payload: {
				message: null,
				type: null
			}
		}


		yield FirebaseHelper.resetPassword(email).catch(result => {

		});

		resetPasswordMessage.payload = {
			message: __.fs('redux.auth.saga.password-sent'),
			type: 'success'
		}


		yield put(resetPasswordMessage);
		yield put(push('/login'));
	});
}


export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
		yield localStorage.setItem('user_id', payload.user_id);
		yield put({
			type : userActions.SET_USER_LOGGED_IN,
			payload : true
		})
		yield put({
			type: actions.FETCH_ALL_DATA,
			payload: {
				user: payload.user,
				user_id: payload.user_id
			}
		})

		yield put({
			type: listActions.SYNC_PRODUCT_LISTS
		})



	});
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function*() {
		clearToken();

		/*
		yield put({
			type : userActions.SET_USER_LOGGED_IN,
			payload : false
		})*/

		 yield put({
			type: messageActions.MESSAGE,
			payload: {
				message: __.fs('redux.auth.saga.logged-out'),
				type: 'error'
			}
	    });
		//yield put(push('/'));
	});
}

// naar helpers
function onAuthStateChanged() {
	return new Promise((resolve, reject) => {
		FirebaseHelper.auth().onAuthStateChanged((user) => {
			if (user) {
				resolve(user);
			} else {
				reject(new Error('User check failed!'));
			}
		});
	});
}


export function* checkAuthorization() {

	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {

		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		const userProfile = localUserData.get('userProfile');

		let isValid = false;
		if(userProfile) {
			const userObject = FirebaseHelper.getProfile(userProfile);
			isValid = FirebaseHelper.checkExpired(userObject);

		}

		if (isValid) {

			yield put({
				type: actions.LOGIN_SUCCESS,
				user_id,
				user : userProfile,
			});

		} else {
			if ( !isValid && userProfile !== null) {

				yield put({
					type: actions.LOGOUT
				})
			}

		}

		try {
			yield call(onAuthStateChanged);
		}
		catch(error) {

			if (userProfile !== null ) {
				// -- If userprofile exists in localstorage, execute. Otherwise, we can safely assume the user has been logged out before.
				yield put({
					type: actions.LOGOUT
				})

				if(userProfile) {
				    yield put({
						type: messageActions.MESSAGE,
						payload: {
							message: __.fs('redux.auth.saga.reauthenticate-required'),
							type: 'error'
						}
				    });
				}
			}


			//yield put(push('/login'));
		}

	});
}

export function* fetchAllData() {
	yield takeEvery(actions.FETCH_ALL_DATA, function*(payload) {
		let user = payload.payload.user;
		const user_id = payload.payload.user_id;

		yield put({
			type: userActions.SET_USER_PROFILE,
			payload: user
		})

		yield put({
			type: compareActions.SYNC_COMPARE_ITEMS
		})

		yield put({
			type: userActions.FETCH_USER_PROPERTIES,
			payload: user_id
		})
	});
}

export function* checkUrl() {
	yield takeEvery(actions.CHECK_URL, function*(payload) {

		// -- fetch uid and get routes
		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		const snapshot = yield call(rsf.firestore.getDocument, 'users/'+user_id);
  		const user = snapshot.data();

  		const userTypePermissionsSnapshot =  yield call(rsf.firestore.getCollection, user.user_type_permissions);
  		const routes = userTypePermissionsSnapshot.data().routes;


  		// -- fetch route from array
  		let validUrl = _.find(routes, function(r) { return r.route === payload.payload });

  		// -- URL not in routes, then logout with nice message
  		if(!validUrl) {

  			yield put({
				type: actions.LOGOUT
			})

		    yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('redux.auth.saga.url-does-not-exist-reauthenticate'),
					type: 'error'
				}
		    });
  		}
	});
}

export function* resetPermissions() {
	yield takeEvery(actions.RESET_PERMISSIONS, function*(payload) {


	})
}


export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(checkUrl),
		fork(loginRequest),
		fork(loginSuccess),
		fork(logout),
		fork(fetchAllData),
		fork(resetPermissions),
		fork(resetPassword)
	]);
}
