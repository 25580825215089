import React, {Component} from 'react';
import PreFilterFile from './PreFilters.json';
import { getUniqueArray, findFilterExists, findCategoryExists, rebuildFilterArray, countKeys, findParentExistsButIsNotProperty, lowerAndCleanString } from '../../../helpers/utility';
import {withRouter} from 'react-router-dom';
import actions from '../../../redux/configurations/actions';
import {connect} from 'react-redux';
import DefaultSettingsPopup from '../../ConfiguredProducts/DefaultSettingsPopup';
import {bindActionCreators} from 'redux';
import __ from '../../../helpers/language';
import Checkbox from '../../Common/checkbox';
import * as Styled from './styled';
import ReactTooltip from 'react-tooltip';
import { convert } from '../../../helpers/conversion';
import { convertHeadToOtherUnit, convertQToOtherUnit } from '../../../helpers/conversion';
class PreFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFilters: [],
            activeCategoryCount: false,
            openedItem: false,
            filterType: 'filter',
            settingPopupOpen: false,
            submitted: false,
            visibleClasses: {
                qh: {
                    button: '',
                    filters: ''
                },
                filter: {
                    button: 'button--inverted--active',
                    filters: 'pre__filters__type--visible'
                }
            },
            qhFilterValues: {
                Q: '',
                QValue : '',
                H: '',
                HValue : '',
                plus: '10',
                minus: '0'
            }
        }
    }

    componentDidMount() {
        this.props.actions.resetConfigurations();
        const preFilters = PreFilterFile.preFilters;

        let filterCategoryCount = countKeys(preFilters);

        this.setState({
            ...this.state,
            availableFilters: preFilters,
            filterCategoryCount
        })

        let filterType = 'filter';
        if (!sessionStorage.getItem('filterType')) {
            sessionStorage.setItem('filterType', 'filter');
        } else {
            this._setFilterType(sessionStorage.getItem('filterType'), false);
            filterType = sessionStorage.getItem('filterType');
        }

        if ( filterType === 'filter' ) {
             if ( sessionStorage.getItem('activeFilters') ) {
                this._setActiveFilters(JSON.parse(sessionStorage.getItem('activeFilters')));
            }
        } else {
            if ( sessionStorage.getItem('qhFilterValues') ) {
                this.setState({
                    qhFilterValues : JSON.parse(sessionStorage.getItem('qhFilterValues'))
                }, this._submitPreFilters);
            }
        }
    }

    _filterClicked = (event, filterCategory, filterProperty) => {
        event.preventDefault();
        let activeFilters = [...this.state.activeFilters];
        if (activeFilters) {

            const filterIndex = findFilterExists(filterCategory, filterProperty, [...this.state.activeFilters]);

            const categoryExistsIndex = findCategoryExists(filterCategory, [...this.state.activeFilters]);

            // -- Filter does not exists in current active filter list, add it.
            if (filterIndex === -1) {
                activeFilters.push({filterCategory: filterCategory, filterProperty: filterProperty})
            } else {
                // -- Does exist, remove it.
                activeFilters = rebuildFilterArray(activeFilters, filterIndex);
            }

            if (categoryExistsIndex !== -1) {
                activeFilters = rebuildFilterArray(activeFilters, categoryExistsIndex);
            }
        }

        this.props.actions.resetConfigurations();

        sessionStorage.setItem('activeFilters', JSON.stringify(activeFilters));
        this._setActiveFilters(activeFilters);
    }

    _mapFilterProperties = (parent, item) => {

        let activeClass = 'filters__list__item--active';
        let greyedOutClass = '';

        const filterIndex = findFilterExists(parent, item, [...this.state.activeFilters]);

        if (filterIndex === -1) {
            activeClass = '';
        }

        if (findParentExistsButIsNotProperty(parent, item, [...this.state.activeFilters])) {
            greyedOutClass = 'filters__list__item--greyed filters__list__item--greyed--selectable';
        }

        return (<li key={item} className={"filters__list__item " + activeClass + ' ' + greyedOutClass}>
            <a data-parent={parent} data-child={item} onClick={(event) => this._filterClicked(event, parent, item)} href="/#">{item}
            </a>
        </li>)
    }

    _setActiveFilters = (activeFilters) => {

        activeFilters = getUniqueArray(activeFilters);

        let filterCategories = {};
        for (let i = 0; i <= activeFilters.length; i++) {
            if (activeFilters[i]) {
                filterCategories = {
                    ...filterCategories,
                    [activeFilters[i]['filterCategory']]: true
                }
            }
        }

        let filterCategoryCount = countKeys(filterCategories);
        this.setState({activeFilters: activeFilters, activeCategoryCount: filterCategoryCount, submitted: false})
    }

    _openDropdown = (e, key) => {
        e.preventDefault();
        let openedItem = key;
        if (this.state.openedItem === key) {
            openedItem = false;
        }
        this.setState({openedItem})
    }

    _renderAvailableFilters = (item, key) => {
        let filter = PreFilterFile.preFilters[item];

        let parentClass = '';
        let onClick = (e) => this._openDropdown(e, key);

        let itemLength = Object.keys(filter).length;
        if (itemLength >= 5) {
            parentClass = 'filters__list__category--large';
        }

        let openedClass = '';

        if (this.state.openedItem === key) {
            openedClass = 'filters__list__category--opened';
        }
        return (<li onClick={onClick} className={"filters__list__category " + parentClass + ' ' + openedClass} key={key}>{item}
            <i className="fas fa-arrow-down"></i>
            <ul>{Object.keys(filter).map((filterProp) => this._mapFilterProperties(item, filterProp))}</ul>
        </li>)
    }

    _buildPostData = () => {
        const activeFilters = [...this.state.activeFilters];
        let formData = new FormData();

        activeFilters.forEach((value, index) => {
            const filterKey = "pre_filters.PRE_FILTER." + value.filterCategory;
            formData.append(value.filterCategory, value.filterProperty);
        });

        // -- default postdata for now.
        formData.append("category_id", this.props.match.params.category_id);
        formData.append("frequency", lowerAndCleanString(this.props.defaultSettings.frequency.value));
        formData.append("poles", lowerAndCleanString(this.props.defaultSettings.motor_poles.value));
        formData.append("limit", 500);
        formData.append("from", 0);


        return formData;
    }

    _submitPreFilters = (e) => {
        if (e) {
            e.preventDefault();
        }

        const postData = this._buildPostData();

        if (this.state.filterType === 'filter') {
            this.props.actions.fetchPreconfigConfigurationsWithQuery({
                postData: postData,
                low_npsh : 'both'
            });
        } else {

            sessionStorage.setItem('qhFilterValues', JSON.stringify(this.state.qhFilterValues));
            const qhQueryData = {
                categoryId: this.props.match.params.category_id,
                frequency: lowerAndCleanString(this.props.defaultSettings.frequency.value),
                motorPoles: lowerAndCleanString(this.props.defaultSettings.motor_poles.value),
                qhFilterValues: this.state.qhFilterValues
            }
            this.props.actions.fetchPreconfigConfigurationsWithQH({
                qhQueryData : qhQueryData,
                low_npsh : 'both'
            });
        }
    }

    _handleKeyPress = (e) => {
         if (this.state.filterType !== 'filter') {
            if (this.state.qhFilterValues.Q !== '' && this.state.qhFilterValues.H !== '' && this.state.qhFilterValues.plus !== '' && this.state.qhFilterValues.minus !== '') {
                 if (e.which === 13 || e.keyCode === 13) {
                    this._submitPreFilters();
                }
            }
        }

    }


    componentDidUpdate(prevProps) {
        if (this.state.filterType === 'filter') {
            if (this.state.activeCategoryCount !== false) {
                if (this.state.activeCategoryCount === this.state.filterCategoryCount) {
                    if (!this.state.submitted) {
                        this.setState({
                            ...this.state,
                            submitted: true
                        })
                        this._submitPreFilters();
                    }
                }
            }
        }
    }

    _renderSubmitButton = () => {
        let shouldReturn = false;
        
        if (this.state.filterType === 'filter') {} else {
            if (this.state.qhFilterValues.Q !== '' && this.state.qhFilterValues.H !== '' && this.state.qhFilterValues.plus !== '' && this.state.qhFilterValues.minus !== '') {
                shouldReturn = true;
            }
        }

        if (shouldReturn) {
            return <div className="pre__filters__submit"><input type="submit" className="button" value={__.fs('Filters.PreFilters.search')} onClick={this._submitPreFilters}/></div>
        }
    }

    _setFilterType = (type, resetFilterValues = true) => {

        sessionStorage.setItem('filterType', type);
        if (type === this.state.filterType) {
            return;
        }
        let secondType = 'qh';
        if (type === 'qh') {
            secondType = 'filter';
        }

        let stateToSet = {
            activeCategoryCount: 0,
            filterType: type,
            visibleClasses: {

                [type]: {
                    button: 'button--inverted--active',
                    filters: 'pre__filters__type--visible'
                },
                [secondType]: {
                    button: '',
                    filters: ''
                }
            }
        }

        if ( resetFilterValues ) {
            if ( type === 'filter') {
                sessionStorage.removeItem('qhFilterValues');
            } else {
                sessionStorage.removeItem('activeFilters');
            }
            stateToSet = {
                ...stateToSet,
                activeFilters: [],
                qhFilterValues: {
                    Q: '',
                    H: '',
                    QValue: '',
                    HValue: '',
                    plus: '10',
                    minus: '0'
                }
            }
        }
        this.setState({
            ...this.state,
            ...stateToSet
        });

        this.props.actions.resetConfigurations();

    }

    _setQHFilterValue(e, type) {

        if ( type === 'H' ) {
            console.log(convert(e.target.value,  this.props.defaultSettings.units.H, 'h', true));
            this.setState({
                qhFilterValues: {
                    ...this.state.qhFilterValues,
                    H: convert(e.target.value,  this.props.defaultSettings.units.H, 'h', true),
                    HValue: e.target.value
                }
            })
        } else if ( type === 'Q' ) {
            console.log(convert(e.target.value,  this.props.defaultSettings.units.Q, 'q', true));
            this.setState({
                qhFilterValues: {
                    ...this.state.qhFilterValues,
                    Q: convert(e.target.value,  this.props.defaultSettings.units.Q, 'q', true),
                    QValue: e.target.value
                }
            })
        } else{
            if (e.target.value >= 0 || e.target.value === '') {
                this.setState({
                    qhFilterValues: {
                        ...this.state.qhFilterValues,
                        [type]: e.target.value
                    }
                })
            }
        }

    }

    _openDefaultSettings = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            settingPopupOpen: !this.state.settingPopupOpen
        })
    }

    _changeFilterType = () => {
        if (this.state.filterType === 'qh') {
            this._setFilterType('filter');
        } else {
            this._setFilterType('qh');
        }
    }

    render() {

        return (<div className={"pre__filters full__width filters"}>
            <DefaultSettingsPopup togglePopup={this._openDefaultSettings} popupVisible={this.state.settingPopupOpen}/>
            <div className="pre__filters__default-settings">

                {__.fs('Filters.PreFilters.defaultSettings')}: {this.props.defaultSettings.frequency.label}&nbsp;
                | {this.props.defaultSettings.motor_poles.label}&nbsp;
                |
                &nbsp;
                <React.Fragment>

                <span data-tip data-for="tip_edit" onClick={this._openDefaultSettings}>
                    <i className="fas fa-pencil-alt"></i>
                </span>
                <ReactTooltip id="tip_edit" className='tooltip'>
                    <span>{__.fs('Filters.PreFilters.edit-default-settings')}</span>
                </ReactTooltip>
                </React.Fragment>
            </div>
            <div className="pre__filters__select-type">

                <Styled.FilterType active={(
                        this.state.filterType === 'qh'
                        ? true
                        : false)} onClick={() => this._setFilterType('qh')}>
                    {__.fs('Filters.PreFilters.searchByQH')}
                </Styled.FilterType>
                <Checkbox onChange={() => this._changeFilterType()} value={(
                        this.state.filterType === 'qh'
                        ? false
                        : true)}/>
                <Styled.FilterType active={(
                        this.state.filterType === 'filter'
                        ? true
                        : false)} onClick={() => this._setFilterType('filter')}>
                    {__.fs('Filters.PreFilters.searchByFilters')}
                </Styled.FilterType>
            </div>

            <div className={"pre__filters__type pre__filters__type--filter " + this.state.visibleClasses.filter.filters}>
                {/* <p className="alert alert--info">{__.fs('Filters.PreFilters.selectAll')}</p> */}
                <ul className={"filters__list filters__list--categorized filters__list--prefilters"}>
                    {Object.keys(PreFilterFile.preFilters).map(this._renderAvailableFilters)}
                </ul>
            </div>

            <div className={"pre__filters__type pre__filters__type--qh " + this.state.visibleClasses.qh.filters}>
                {/* <p className="alert alert--info">{__.fs('Filters.PreFilters.allFieldsRequired')}</p> */}
                <ul className="filters__input filters__input--grid filters__input--grid--4">
                    <li className="filters__input__Q">
                        <div className="input">
                            <label className="input__label input__label--after" data-attr={'In ' + convertQToOtherUnit[this.props.defaultSettings.units.Q].label}>Q</label>
                            <input onKeyPress={this._handleKeyPress} className="input__field" type="number" onChange={(e) => this._setQHFilterValue(e, 'Q')} value={this.state.qhFilterValues.QValue}/>
                        </div>
                    </li>
                    <li className="filters__input__H">
                        <div className="input">
                            <label className="input__label input__label--after" data-attr={'In ' + convertHeadToOtherUnit[this.props.defaultSettings.units.H].label}>H</label>
                            <input onKeyPress={this._handleKeyPress} className="input__field" type="number" onChange={(e) => this._setQHFilterValue(e, 'H')} value={this.state.qhFilterValues.HValue}/>
                        </div>
                    </li>
                    <li className="filters__input__PLUS">
                        <div className="input">
                            <label className="input__label input__label--after" data-attr={__.fs('Filters.Prefilters.index.plus-over')}>+</label>
                            <input onKeyPress={this._handleKeyPress} className="input__field" type="number" onChange={(e) => this._setQHFilterValue(e, 'plus')} value={this.state.qhFilterValues.plus}/>
                        </div>
                    </li>
                    <li className="filters__input__MINUS">
                        <div className="input">
                            <label className="input__label input__label--after" data-attr={__.fs('Filters.Prefilters.index.minus-over')}>-</label>
                            <input onKeyPress={this._handleKeyPress} className="input__field" type="number" onChange={(e) => this._setQHFilterValue(e, 'minus')} value={this.state.qhFilterValues.minus}/>
                        </div>
                    </li>
                </ul>
            </div>

            {this._renderSubmitButton()}
        </div>)
    }
}

/*

if ( this.props.defaultSettings.units_distance ) {
    if ( this.props.defaultSettings.units_distance == 'FT') {
        value = value * 0.3048;
    }
}
*/

const mapStateToProps = (state, ownProps) => {
    return {defaultSettings: state.User.default_settings, configurations : state.Configurations.configurations}
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreFilters))
