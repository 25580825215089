const actions = {
	FETCH_CONFIGURED_PRODUCTS: 'FETCH_CONFIGURED_PRODUCTS',
	SET_CONFIGURED_PRODUCTS: 'SET_CONFIGURED_PRODUCTS',
	SET_RANDOM_PRODUCTS: 'SET_RANDOM_PRODUCTS',

	
	fetchConfiguredProducts : () => ({
		type: actions.FETCH_CONFIGURED_PRODUCTS
	}),

	setRandomConfiguredProducts : (payload) => ({
		type: actions.SET_RANDOM_PRODUCTS,
		payload: payload
	}),
	
	setConfiguredProducts : (payload) => ({
		type: actions.SET_CONFIGURED_PRODUCTS,
		payload:payload
	})

};

export default actions;
