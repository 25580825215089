import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// -- App functions
import actions from '../../redux/compare/actions';
import __ from '../../helpers/language';
import AddToListPopup from '../Lists/AddToListPopup';

class AddTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addToListPopup: {
        		visible: false
        	}
        }
    }
    _toggleAddToListPopup = (e, item) => {

        if ( e ) {
            e.preventDefault();
        }
		

		this.setState({
			addToListPopup : {
				visible : true,
				title : "Voeg toe aan productlijst",
				productData: item
			}
		})
	}

    _closeAddToListPopup = (e) => {
        if ( e ) {
            e.preventDefault();
        }
		this.setState({
			addToListPopup : {
				visible: false
			}
		})
	}

    _addToCompare = (e) => {
        e.preventDefault();

        this.props.actions.addToCompare({...this.props.configuration});
    }

    render() {
        if ( this.props.isLoggedIn ) {
            return (
                <React.Fragment>
                    <li className={"tabs__list__item"}>
                        <a  href="/#" onClick={(e) => this._addToCompare(e)}>
                            {__.fs('Configuration.AddTo.compare')}
                        </a>
                    </li>
                    <li className={"tabs__list__item"}>
                        <a href="/#" onClick={(e) => this._toggleAddToListPopup(e, {...this.props.configuration})}>
                            {__.fs('Configuration.AddTo.list')}
                        </a>
                    </li>

                    {this.state.addToListPopup.visible ?
	                	<AddToListPopup closePopup={this._closeAddToListPopup} data={this.state.addToListPopup} />
	                : null }
                </React.Fragment>
            );
        } else {
            return null;
        }

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn : state.User.logged_in,
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps,mapDispatchToProps)(AddTo)
