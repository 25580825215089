import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import messageActions from '../message/actions';
import FirebaseHelper from '../../helpers/firebase';
import { getToken } from '../../helpers/utility';
import __ from '../../helpers/language';
const { rsfFirestore } = FirebaseHelper;

async function fetchConfigurationsFromServer(postData, action = 'preFilters')
{
		const url = global.serverURL + 'elasticsearch/'+ action;

		let resp = false;

		try {
			let data = await fetch(url, {method: 'POST', body: postData,});
			resp = { data: await data.json() };
		}
		catch (e) {
			resp = { err: e.message };
		}
		return resp;


}



export function* removeFromCompare() {
	yield takeEvery(actions.REMOVE_FROM_COMPARE, function*(payload) {
	    const currentState = yield select();
	    let currentConfigurations = currentState.Compare.configurations;

	    const removeKey = payload.payload;


	    let configurations = {};

	    for ( let key in currentConfigurations ) {
	    	if ( currentConfigurations.hasOwnProperty( key ) ) {

	    		if ( key !== removeKey ) {
	    			configurations = {
	    				...configurations,
	    				[key] : currentConfigurations[key]
	    			}
	    		}
	    	}
	    }

	    yield put ({
	    	type : actions.SET_COMPARE_ITEMS,
	    	payload : configurations
	    })

	    yield put({
			type : actions.SET_COMPARE_ITEMS_FIREBASE,
			payload : configurations
		})


	    yield put({
			type: messageActions.MESSAGE,
			payload: {
				message: __.fs('redux.compare.saga.configuration-deleted-successfully'),
				type: 'success'
			}
	    });
	})
}

export function* addToCompare() {
	yield takeEvery(actions.ADD_TO_COMPARE, function*(payload) {

	    const currentState = yield select();
		console.log(payload);
	    const key = payload.payload.products.join('-');

	    let configurations = currentState.Compare.configurations;

	    if ( Object.keys(configurations).length < 3 ) {

		    let configuration = payload.payload;
		    if ( configuration.configurations ) {
		    	configuration.configurations = false;
		    }
		    configurations = {
		    	...configurations,
		    	[key] : configuration
		    }

		    yield put ({
		    	type : actions.SET_COMPARE_ITEMS,
		    	payload : configurations
		    })

			yield put({
				type : actions.SET_COMPARE_ITEMS_FIREBASE,
				payload : configurations
			})

		    yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('redux.compare.saga.configuration-added-successfully'),
					type: 'success'
				}
		    });
	    } else {
	    	yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('redux.compare.saga.maximum-reached'),
					type: 'error'
				}
		    });
	    }

	})
}

export function* setCompareItemsFirebase() {
	yield takeEvery(actions.SET_COMPARE_ITEMS_FIREBASE, function*(payload) {

	    const comparisonConfigurations = {
	    	comparisonConfigurations : payload.payload
	    }

	    const localUserData = getToken();
	    const user_id = localUserData.get('userId');
		yield call(rsfFirestore.updateDocument, 'users/'+user_id, comparisonConfigurations)
	})
}

export function* setSyncedCompareItems() {
	yield takeEvery(actions.SET_SYNCED_COMPARE_ITEMS, function*(payload) {
	   if ( payload.payload.comparisonConfigurations ) {
	   		yield put ({
		    	type : actions.SET_COMPARE_ITEMS,
		    	payload : payload.payload.comparisonConfigurations
		    })
	   }
	})
}



export function* syncCompareItems() {

	yield takeEvery(actions.SYNC_COMPARE_ITEMS, function*(payload) {
		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		if ( user_id ) {
			// -- Sync user document and set user properties with snapped data on change.
			yield fork(
				rsfFirestore.syncDocument,
				'users/'+user_id,
				{
					successActionCreator: (snap) => actions.setSyncedCompareItems(snap.data())
				}
			);
		}
	})
}



// const user_id = localUserData.get('userId');
// yield call(rsfFirestore.setDocument, 'users/'+action.payload.user_id, userData)

export default function* rootSaga() {
	yield all([
		fork(syncCompareItems),
		fork(removeFromCompare),
		fork(addToCompare),
		fork(setCompareItemsFirebase),
		fork(setSyncedCompareItems)
	]);
}
