import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import  actions  from '../../redux/auth/actions';

import LogoUrl from '../../assets/img/logo.jpg';
import __ from '../../helpers/language';


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpened : false
		}
	}

	componentDidUpdate(prevProps) {
		if(prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				menuOpened : false
			});
		}
	}

	_toggleMenu = (e) => {
		this.setState({
			menuOpened : !this.state.menuOpened
		});
	}


	render() {

		return (
			<header className="header header--main">
				<div className="container">
					<div className="grid--desktop--header">
						<div className="grid__column header--hamburger-logo">
							<NavLink to="/">
								<img alt="Logo Duijvelaar Pompen" src={LogoUrl} className="header__logo" />
							</NavLink>
							<div className={"button__hamburger " + (this.state.menuOpened ? 'button__hamburger--active' : null)} onClick={this._toggleMenu}>
								<span className="bar"></span>
								<span className="bar"></span>
								<span className="bar"></span>
							</div>
						</div>
						<div className="grid__column">
							<ul className={"header__menu " + (this.state.menuOpened ? 'header__menu--opened' : null)}>
								{/*<li className="header__menu__item">{__.fs('Layout.Header.catalog')}</li>
							<li className="header__menu__item">{__.fs('Layout.Header.replacement')}</li>*/}
								{Object.keys(this.props.compareConfigurations).length ? <li className="header__menu__item"><NavLink activeClassName="header__menu__item__link--active" className="header__menu__item__link" to="/compare">{__.fs('Layout.Header.compare')} ({Object.keys(this.props.compareConfigurations).length})</NavLink></li> : null}
								{Object.keys(this.props.productLists).length ? <li className="header__menu__item"><NavLink activeClassName="header__menu__item__link--active" className="header__menu__item__link" to="/profile/lists">{__.fs('Layout.Header.lists')} ({Object.keys(this.props.productLists).length})</NavLink></li> : null}
								<li className="header__menu__item header__menu__item--auth">
									{this.props.isLoggedIn
										?
											<span>
												<NavLink  className="header__menu__item__link" activeClassName="header__menu__item__link--active" to="/profile/settings">{__.fs('Layout.Header.welcome')}, {this.props.userProfile.displayName ? this.props.userProfile.displayName : this.props.userProfile.email}</NavLink>
												<NavLink activeClassName="header__menu__item__link--active" className="header__menu__item__link" title={__.fs('Layout.Header.Logout')} to="/logout"> <i className="fas fa-sign-out-alt"></i></NavLink>
											</span>
										:
											<NavLink activeClassName="header__menu__item__link--active" className="header__menu__item__link" title={'Inloggen'} to="/login">
											{__.fs('Layout.Header.Login')} <i className="fas fa-sign-in-alt"></i>
											</NavLink>
										}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
		)


	}
}

const mapStateToProps = (state, ownProps) => {

    return {
        compareConfigurations : state.Compare.configurations ? state.Compare.configurations : {},
        routes: state.Auth.routes,
        isLoggedIn : state.User.logged_in,
        userProfile : state.User.user_profile,
        defaultSettings : state.User.default_settings,
		productLists: state.ProductLists.productLists
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
