import { all, takeEvery, put, fork, select, call, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import userActions from '../user/actions';
import __ from '../../helpers/language';
import layoutActions from '../layout/actions';
import { fetchData } from '../../helpers/fetch';
import  PreconfigHelper  from '../../helpers/preconfig';
import _ from 'lodash';
import messageActions from '../message/actions';
var elasticsearch = require('elasticsearch');

var client = new elasticsearch.Client({
	host : 'http://149.210.163.93:9200',
	requestTimeout: 60000,
});

async function fetchConfigurationsFromServer(postData, action = 'preFilters')
{
		const url = global.serverURL + 'elasticsearch/'+ action;

		let resp = false;

		try {
			let data = await fetch(url, {method: 'POST', body: postData,});
			resp = { data: await data.json() };
		}
		catch (e) {
			resp = { err: e.message };
		}
		return resp;


}



function* fetchAndHandleConfigurationsWithQuery(query, low_npsh) {

	// -- Get first result with polynomial values (preconfig)
	//
	// result = yield client.search( getRegularQuery(1, query, true) );
	// const preconfig = result;
	// let offSet = 0;
	// yield put({
	// 	type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
	// 	payload: {
	// 		hits:  result.hits.hits,
	// 		low_npsh : low_npsh,
	// 		setToField : 'preconfig',
	// 		preconfig: preconfig.hits.hits
	// 	}
	// })


	let result = false;
	let offSet = 0
	query.append("from", offSet);
	query.append("limit", 10);
	query.append("initial_count", 'true')
	result = yield call(fetchConfigurationsFromServer,query,'afterPreFilters');
	offSet += 10

	const totalHits = result.data[0].configurations.length;
	if ( totalHits === 0 ) {
		yield put({
			type: messageActions.MESSAGE,
			payload: {
				message: __.fs('Redux.Configurations.Saga.no-results'),
				type: 'error'
			}
		});
	}

	// -- Set the configurations already found
	if ( result.data[0].configurations ) {

		yield put({
			type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
			payload: {
				hits:  [result.data[0].preconfig],
				low_npsh : low_npsh,
				user_has_chosen_lownpsh: low_npsh === true ? true : false,
				setToField : 'preconfig',
				preconfig: [result.data[0].preconfig]
			}
	    })

		yield put({
			type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
			payload: {
				hits:  result.data[0].configurations,
				low_npsh : low_npsh,
				user_has_chosen_lownpsh: low_npsh === true ? true : false,
				setToField : 'configurations',
				preconfig: [result.data[0].preconfig]
			}
	    })
	} else {
		yield put({
			type: messageActions.MESSAGE,
			payload: {
				message: __.fs('Redux.Configurations.Saga.no-results'),
				type: 'error'
			}
		});
	}

	const totalCount = result.data[0].totalCount

	for ( let i = 0; i <= totalCount; i += 500 ) {

		query.set("initial_count", 'false')
		query.set("scroll", '1m')
		query.set("scroll_id", result.data[0].scroll_id)
		
		result = yield call(fetchConfigurationsFromServer,query,'afterPreFilters');

		if (result.data[0].configurations.length > 0 ) {
			yield put({
				type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
				payload: {
					hits:  result.data[0].configurations,
					low_npsh : low_npsh,
					user_has_chosen_lownpsh: low_npsh === true ? true : false,
					setToField : 'configurations',
					preconfig: [result.data[0].preconfig]
				}
			})
		}
		

	}

}

export function* fetchConfigurationsWithQuery() {
	yield takeEvery(actions.FETCH_CONFIGURATIONS_WITH_QUERY, function*(action) {
		yield put({
			type : layoutActions.SET_WORKING_OVERLAY,
			payload : true
		})



		try {
			yield fetchAndHandleConfigurationsWithQuery(action.payload.query, action.payload.low_npsh);



		} catch (e) {


			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.cluster-unresponsive'),
					type: 'error'
				}
			});
		}

		yield put({
			type : layoutActions.SET_WORKING_OVERLAY,
			payload : false
		})
	})
}

/**
 * Fetch preconfig with prefilter size and number of stages
 */
export function* fetchPreconfigConfigurationsWithQuery() {

	yield takeEvery(actions.FETCH_PRECONFIG_CONFIGURATIONS_WITH_QUERY, function*(action) {

		yield put({
			type : layoutActions.SET_WORKING_OVERLAY,
			payload : true
		})

		let result = false;
		try {



			result = yield call(fetchConfigurationsFromServer,action.payload.postData, 'preFilters');

			if ( !result ) {
				yield put({
					type: messageActions.MESSAGE,
					payload: {
						message: __.fs('Redux.Configurations.Saga.no-results'),
						type: 'error'
					}
				});

					yield put({
					type : layoutActions.SET_WORKING_OVERLAY,
					payload : false
				})
			} else {
				if ( result.data[0].length ) {
					yield put({
						type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
						payload: {
							hits:  result.data[0],
							low_npsh: action.payload.low_npsh,
							user_has_chosen_lownpsh: action.payload.low_npsh === true ? true : false,
							set_as_preconfigs: true
						}
					})
				} else {
					yield put({
						type : layoutActions.SET_WORKING_OVERLAY,
						payload : false
					})
					yield put({
						type: messageActions.MESSAGE,
						payload: {
							message: __.fs('Redux.Configurations.Saga.no-results'),
							type: 'error'
						}
					});
				}

			}
		} catch (e) {
			yield put({
				type : layoutActions.SET_WORKING_OVERLAY,
				payload : false
			})
			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.cluster-unresponsive'),
					type: 'error'
				}
			});
		}
	})

}


export function* fetchConfigurationsWithQH() {
	yield takeEvery(actions.FETCH_CONFIGURATIONS_WITH_QH, function*(action) {
		const {qhFilterValues, categoryId, frequency, motorPoles } = {...action.payload.qhQueryData};
		const low_npsh = action.payload.low_npsh;
		let result = false;
		let offSet = 0
		let query = new FormData();
		query.append("from", offSet);
		query.append("limit", 10);
		query.append("initial_count", true)
		query.append("ch", qhFilterValues.CH);
		query.append("category_id", categoryId);
		query.append("frequency", frequency);
		query.append("poles", motorPoles);

		result = yield call(fetchConfigurationsFromServer,query,'qHAfterPreConfig');
		offSet += 10

		const totalHits = result.data[0].configurations.length;
		if ( totalHits === 0 ) {
			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.no-results'),
					type: 'error'
				}
			});
		}

		// -- Set the configurations already found
		if ( result.data[0].configurations ) {

			yield put({
				type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
				payload: {
					hits:  [result.data[0].preconfig],
					low_npsh : low_npsh,
					user_has_chosen_lownpsh: low_npsh === true ? true : false,
					setToField : 'preconfig',
					preconfig: [result.data[0].preconfig]
				}
			})

			yield put({
				type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
				payload: {
					hits:  result.data[0].configurations,
					low_npsh : low_npsh,
					user_has_chosen_lownpsh: low_npsh === true ? true : false,
					setToField : 'configurations',
					preconfig: [result.data[0].preconfig]
				}
			})
		} else {
			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.no-results'),
					type: 'error'
				}
			});
		}

		const totalCount = result.data[0].totalCount

		for ( let i = 0; i <= totalCount; i += 500 ) {


			query.set("initial_count", 'false')
			query.set("scroll", '1m')
			query.set("scroll_id", result.data[0].scroll_id)

			result = yield call(fetchConfigurationsFromServer,query,'qHAfterPreConfig');

			if (result.data[0].configurations.length > 0 ) {
				yield put({
					type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
					payload: {
						hits:  result.data[0].configurations,
						low_npsh : low_npsh,
						user_has_chosen_lownpsh: low_npsh === true ? true : false,
						setToField : 'configurations',
						preconfig: [result.data[0].preconfig]
					}
				})
			}
		

		}
	});
}


export function* fetchPreconfigConfigurationsWithQH() {
	yield takeEvery(actions.FETCH_PRECONFIG_CONFIGURATIONS_WITH_QH, function*(action) {

		const {qhFilterValues, categoryId, frequency, motorPoles } = {...action.payload.qhQueryData};
		const polynomialKey = "general.polynomial_values.head.h" + Math.round(qhFilterValues.Q * 10);

		const state = yield select();
		let H = qhFilterValues.H;
		// if ( heightIn === 'feet' ) {
		// 	H = feetToMeters(qhFilterValues.H);
		// }

		const lowerLimit = H * (1 - (qhFilterValues.minus / 100));
		const upperLimit = H * (1 + (qhFilterValues.plus / 100));


		yield put({
			type : layoutActions.SET_WORKING_OVERLAY,
			payload : true
		})
		yield put({
			type: userActions.SET_DUTY_POINT,
			payload: {
				q : qhFilterValues.Q,
				h: qhFilterValues.H
			}
		})

		let formData = new FormData();
		formData.append('from', 0);
		formData.append('limit', 5000);
		formData.append('frequency', frequency);
		formData.append('category_id', categoryId);
		formData.append('poles', motorPoles);
		formData.append('upper_limit', upperLimit.toFixed(2));
		formData.append('lower_limit', lowerLimit.toFixed(2));
		formData.append('polynomial_key', polynomialKey);

		let result = false;
		try {
			result = yield call(fetchConfigurationsFromServer,formData,'qHPreConfig');

			if ( !result ) {
				yield put({
					type: messageActions.MESSAGE,
					payload: {
						message: __.fs('Redux.Configurations.Saga.no-results'),
						type: 'error'
					}
				});

					yield put({
					type : layoutActions.SET_WORKING_OVERLAY,
					payload : false
				})
			} else {

				yield put({
					type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
					payload: {
						hits:  result.data[0],
						low_npsh: action.payload.low_npsh,
						user_has_chosen_lownpsh: action.payload.low_npsh === true ? true : false,
						set_as_preconfigs: true
					}
				})
			}
		} catch (e) {
			yield put({
				type : layoutActions.SET_WORKING_OVERLAY,
				payload : false
			})
			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.cluster-unresponsive'),
					type: 'error'
				}
			});
		}
	})
}

export function* fetchConfigurationById() {
	yield takeEvery(actions.FETCH_CONFIGURATION_BY_ID, function*(action) {

		let result = false;
		try {

			let query = new FormData();
			const products_string = action.payload.id.toLowerCase();
			query.append("products_string", products_string);


			// now we split is all
			const productArray = products_string.split('-')
			query.append("ch", productArray[0]);

			result = yield call(fetchConfigurationsFromServer,query,'fetchSingleConfiguration');

			if(result) {
				yield put({
					type: actions.PROCESS_CONFIGURATIONS_ELASTIC_SINGLE,
					payload: {
						hits: [result.data[0].singleconfiguration],
						low_npsh: action.payload.low_npsh
					}
				})

				yield put({
					type: actions.PROCESS_CONFIGURATIONS_ELASTIC,
					payload: {
						hits:  [result.data[0].preconfig],
						low_npsh: action.payload.low_npsh,
						user_has_chosen_lownpsh: action.payload.low_npsh === true ? true : false,
						setToField : 'preconfig',
						preconfig: [result.data[0].preconfig]
					}
				})
			}
		} catch (e) {
			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.Configurations.Saga.cluster-unresponsive'),
					type: 'error'
				}
			});
		}



	})
}

export function* processConfigurationsElasticSingle() {

	yield takeEvery(actions.PROCESS_CONFIGURATIONS_ELASTIC_SINGLE, function*(action) {

		const data = action.payload.hits;
		const low_npsh = action.payload.low_npsh;
		let configuration = {};

		if ( !low_npsh ) {
			for( let i = 0; i <= data.length; i++ ){

				if(data[i]) {
					let chunk = data[i];

					let source = chunk['_source'];
					source = {
						...source,
						id : chunk['_id'],
						is_low_npsh: false

					}
					configuration = source;
				}
			}
		} else {
			let lowNPSHPushed = false;
			for( let i = 0; i <= data.length; i++ ){

				if(data[i]) {
					let chunk = data[i];

					let source = chunk['_source'];
					if ( source.low_npsh ) {

						if ( source.low_npsh.additional_costs ) {
							for ( let property in source.low_npsh.additional_costs ) {
								if ( !lowNPSHPushed ) {
									if ( property.includes('LN') ) {
										lowNPSHPushed = true;

										let products = [...source.products];
										products.push(property);
										let description = {
											...source.description,
											[property] : {}
										}

										source = {
											...source,
											products: products,
											description : description,
											is_low_npsh: true
										}
									}
								}
							}
						}
					}
					source = {
						...source,
						id : chunk['_id']

					}
					configuration = source;
				}
			}
		}

		yield put({
		 	type: actions.SET_SINGLE_CONFIGURATION,
		 	payload: configuration
		})

	})
}

export function* processConfigurationsElastic() {

	yield takeEvery(actions.PROCESS_CONFIGURATIONS_ELASTIC, function*(action) {

		const data = (action.payload.hits ? action.payload.hits : []);
		const low_npsh = action.payload.low_npsh;
		// -- if this is set, we get preconfig configurations.

		const set_as_preconfigs =  action.payload.set_as_preconfigs === undefined ? false : action.payload.set_as_preconfigs;
		const user_has_chosen_lownpsh = action.payload.user_has_chosen_lownpsh;
		let preconfig = false;



		if ( action.payload.preconfig ) {
			if ( action.payload.preconfig.length ) {
				
				preconfig = {...action.payload.preconfig[0]._source};
				// -- get artnr.
				preconfig['artnr'] = PreconfigHelper._preConfigArtNr(preconfig.ch);
				
			}
		}

		


		let configurations = [];
		for( let i = 0; i <= data.length; i++ ){
			if(data[i]) {

				let chunk = data[i];
				let source = chunk['_source'];
				source = {
					...source,
					id : chunk['_id'],
					is_low_npsh: false,
				}


				// -- fetch artnr. for preconfigs. But only when preconfigs are fetched
				if(set_as_preconfigs) {
					// -- get artnr.
					source['artnr'] = PreconfigHelper._preConfigArtNr(source.ch);
					
				}

				if ( low_npsh === false || low_npsh === 'both') {
					configurations.push(source);
				}


				let lowNPSHSource = {
					...source,
					is_low_npsh: true
				};



				// -- set preconfig as lowNPSHSource
				if(!set_as_preconfigs && user_has_chosen_lownpsh)
				{
					lowNPSHSource = preconfig;
				}



				// -- set applied filters and products, else it won't change
				lowNPSHSource['applied_filters'] = source.applied_filters;
				lowNPSHSource['products'] = source.products;
				lowNPSHSource['pre_filters'] = source.pre_filters;

				let lowNPSHProductPushed = false;

				if ( lowNPSHSource && (set_as_preconfigs || user_has_chosen_lownpsh)) {
					if ( lowNPSHSource.low_npsh.additional_costs ) {
						for ( let property in lowNPSHSource.low_npsh.additional_costs ) {
							if ( !lowNPSHProductPushed ) {
								if ( property.includes('LN') ) {
									lowNPSHProductPushed = true;

									let products = [...lowNPSHSource.products];
									let description = {
										...lowNPSHSource.description,
										[property] : {}
									}
									products.push(property);
									lowNPSHSource = {
										...lowNPSHSource,
										products: products,
										description : description

									}
								}
							}
						}
					}
					if ( low_npsh === true || low_npsh === 'both') {

						configurations.push(lowNPSHSource);
					}
				}

			}
		}

		if ( action.payload.set_as_preconfigs ) {
			yield put({
			 	type: actions.SET_PRECONFIG_CONFIGURATIONS,
			 	payload: configurations
			})
		} else {

			yield put({
			 	type: actions.SET_CONFIGURATIONS,
			 	payload: {
					configurations: configurations,
					setToField : action.payload.setToField
				},

			})
		}

		yield put({
			type : layoutActions.SET_WORKING_OVERLAY,
			payload : false
		})
	})
}

export function* createFilterObject() {
	yield takeLatest(actions.CREATE_FILTER_TREE, function*(action) {
		const configurations = action.payload;
		yield fork(parseFilters, configurations);
	})
}

function* parseFilters(args){

	const result = yield call(window.workerInstance.createFilterTree, args);
	yield put({
		type: actions.SET_FULL_FILTER_TREE,
		payload: result
	})


}

export function* downloadProductSheet() {
	yield takeEvery(actions.DOWNLOAD_PRODUCT_SHEET, function*(action) {
		const configuration_data = action.payload;

		let formData = new FormData();
		formData.append('configuration_data', JSON.stringify(configuration_data));

	 	yield call(
			fetchData,
			'http://127.0.0.1:8000/productsheet/download',
			formData
		)
	});
}

export function* buildFullFilterTree() {
	yield takeEvery(actions.BUILD_FULL_FILTER_TREE, function*(action) {
		const pagesize = 100;
		let filterTree = {};
		const query = {
			size: 1,
			from: 1,
			index: global.index,
			body:{
				query: {
					bool: {
						must: [
							{
								term : {
									"main_category_id" : 1
								}
							},

						]
					}
				}
			}
		};

		let result = false;
		result = yield client.search( query );
		const pages = parseInt(result.hits.total / pagesize);

		for ( let i = 0; i <= pages; i++ ) {
			const pageQuery = {
				size: pagesize,
				from: (pagesize * i),
				index: global.index,
				body:{
					query: {
						bool: {
							must: [
								{
									term : {
										"main_category_id" : 1
									}
								},

							]
						}
					}
				}
			};

			result = yield client.search( pageQuery );

			const built = yield call(window.workerInstance.createFilterTreeFromConfigurations, result.hits.hits);

			_.merge(filterTree, built);

		}

		const fileName = "file";
		const json = JSON.stringify(filterTree);
		const blob = new Blob([json],{type:'application/json'});
		const href = yield URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = fileName + ".json";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

	})
}


export default function* rootSaga() {
	yield all([
		fork(processConfigurationsElastic),
		fork(fetchConfigurationsWithQuery),
		fork(fetchConfigurationsWithQH),
		fork(fetchPreconfigConfigurationsWithQuery),
		fork(fetchPreconfigConfigurationsWithQH),
		fork(fetchConfigurationById),
		fork(processConfigurationsElasticSingle),
		fork(createFilterObject),
		fork(downloadProductSheet),
		fork( buildFullFilterTree ),
		//fork(fetchConfigurationsFromServer)
	]);
}
