import { round, findClosestQ } from '../graphCalculationHelpers';
import React from 'react';
import CustomizedRect from './GraphComponents/CustomizedRect';

class EfficiencySeries {
    constructor(ratedPolynomialValues, variablePolynomialValues, returnQOpt, QH) {
        this._returnQOpt = returnQOpt;
        this.QH = QH;

        this.ratedPolynomialValues = ratedPolynomialValues;
        this.variablePolynomialValues = variablePolynomialValues;

    }

    _getEfficiencyValues = () => {

        return {
            rated : this.ratedPolynomialValues.map(this._mapValues),
            variable : this.variablePolynomialValues.map(this._mapValues)
        }
    }

    _mapValues = (item, key) => {
        return {
            q : parseFloat(item.q),
            eff: parseFloat(item.eff),
        }
    }

    _getEffSeries = ( actualDutyPoint ) => {

        let returnSeries = [
            {
                name: 'Rated Efficiency',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 1,
                data: this._getEfficiencyValues().rated
            },
            {
                name: 'Variable Efficiency',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 2,
                data: this._getEfficiencyValues().variable
            }
        ];

        if ( this._returnQOpt ) {
            const optSeries = this._getOptValues();

            if ( optSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        q : parseFloat(optSeries.q),
                        eff : parseFloat(optSeries.eff)
                    }]
                })
            }

        }

        if ( actualDutyPoint ) {

            const actualSeries = this._getActualValues(actualDutyPoint);
            if ( actualSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        q : parseFloat(actualSeries.q),
                        eff : parseFloat(actualSeries.eff)
                    }]
                })
            }
        }

        return returnSeries;
    }

    _getEffOpt = (actualDutyPoint) => {
        if ( actualDutyPoint ) {
            const actualSeries = this._getActualValues(actualDutyPoint);
            if ( actualSeries ) {
                return actualSeries.eff;
            }

            return false;
        }

        return false;
    }

    _getActualValues = (actualDutyPoint) => {

       if( actualDutyPoint.q ) {

           const values = this._getEfficiencyValues().variable;
           const index = findClosestQ(actualDutyPoint, 'q', values);

           return {
               q : values[index].q,
               eff : values[index].eff
           }
       }
    }

    _getOptValues = () => {
        const QHOpt = this.QH._getQHOpt ( true );

        if ( !QHOpt ) {
            return false;
        }

        if( QHOpt.q ) {
            for ( let i = 0; i <= this._getEfficiencyValues().rated.length; i++ ){
                if ( this._getEfficiencyValues().rated[i] ) {
                    if ( round(parseFloat(this._getEfficiencyValues().rated[i].q), 2) === round(QHOpt.q, 1) ) {
                        return {
                            q : this._getEfficiencyValues().rated[i].q,
                            eff : this._getEfficiencyValues().rated[i].eff
                        }
                    }
                }
            }
        }
    }

}

export default EfficiencySeries;
