import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
	width: 45px;
	height: 25px;
	background: #1b2947;
	border-radius: 25px;
	cursor: pointer;
	input {
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		cursor: pointer;
	}
`;

export const Checkbox = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-items: center;
  align-items: center;
`;

export const Label = styled.label`
	line-height: 25px;
`;

export const CheckboxCircle = styled.div`
	width: 23px;
	height: 23px;
	border-radius: 100%;
	background: white;
	position: absolute;
	top: 1px;
	left: 1px;
	transition: left .3s;
	pointer-events: none;

	&.active {
		left: calc(100% - 24px);
	}
	
`;