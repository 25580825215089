import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PageHeader from '../Common/PageHeader';
import { bindActionCreators } from 'redux';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import actions from '../../redux/configurations/actions';
import Graphs from './Graphs';
import MotorSpecs from './MotorSpecs';
import Sizes from './Sizes';
import ProductSheet from './ProductSheet';
import AddTo from './AddTo';
import __ from '../../helpers/language';
import SheetPageHeader from './PageHeader';

class Configuration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title : ''
        }
    }
    componentDidMount() {
    	if( this.props.match.params ) {
	    	this.props.actions.fetchConfigurationById({id : this.props.match.params.configuration_id_elastic, low_npsh: (this.props.match.params.type === 'ln' ? true : false)})
    	}
    }

    _setTitle = (title) => {
        this.setState({
            ...this.state,
            title
        })
    }

    render() {

        return (
          	<div className="single__configuration">
              	<PageHeader title={this.props.singleConfiguration.ch} alignCenter={true}/>
              	<div className="tabs">
              		<div className="container">
    		          	<ul className={"tabs__list " + ( this.props.isLoggedIn ? 'tabs__list--extend' : null )}>
                            <li className={"tabs__list__item"}>
                                <NavLink to={'/configuration/'+this.props.match.params.type+'/'+this.props.match.params.configuration_id_elastic + '/productsheet'} >
                                    {__.fs('Configuration.index.productsheet')}
                                </NavLink>
                            </li>

                            <li className={"tabs__list__item"}>
                                <NavLink to={'/configuration/'+this.props.match.params.type+'/'+this.props.match.params.configuration_id_elastic + '/graphs'} >
                                    {__.fs('Configuration.index.graph')}
                                </NavLink>
                            </li>

                            <li className={"tabs__list__item"}>
                                <NavLink to={'/configuration/'+this.props.match.params.type+'/'+this.props.match.params.configuration_id_elastic + '/motorspecs'} >
                                    {__.fs('Configuration.index.motorspecs')}
                                </NavLink>
                            </li>
                            <li className={"tabs__list__item"}>
                                <NavLink to={'/configuration/'+this.props.match.params.type+'/'+this.props.match.params.configuration_id_elastic + '/sizes'} >
                                    {__.fs('Configuration.index.sizes')}
                                </NavLink>
                            </li>
                            {this.props.singleConfiguration ? <AddTo configuration={this.props.singleConfiguration} /> : null }

    		          	</ul>
    		        </div>
    	        </div>
          		<div className="container">

                    <div className="tab tab--visible">
                        <SheetPageHeader title={this.state.title} {...this.props} />
                        <Switch>
                            <Route
                                exact
                                path="/configuration/:type/:configuration_id_elastic"
                                render={ (props) =>
                                    <Redirect  to={'/configuration/'+this.props.match.params.type+'/'+this.props.match.params.configuration_id_elastic + '/productsheet'} />
                                }
                            />
                            <Route
                                path="/configuration/:type/:configuration_id_elastic/productsheet"
                                render={ (props) =>
                                    <ProductSheet setTitle={this._setTitle} configuration={this.props.singleConfiguration} preconfig={this.props.preconfig} {...this.props} />
                                }
                            />

                            <Route
                                path="/configuration/:type/:configuration_id_elastic/graphs"
                                render={ (props) =>
                                    <Graphs setTitle={this._setTitle} configuration={this.props.singleConfiguration} preconfig={this.props.preconfig} {...this.props}/>
                                }
                            />

                            <Route
                                path="/configuration/:type/:configuration_id_elastic/motorspecs"
                                render={ (props) =>
                                    <MotorSpecs setTitle={this._setTitle} configuration={this.props.singleConfiguration} preconfig={this.props.preconfig} {...this.props} />
                                }
                            />

                            <Route
                                path="/configuration/:type/:configuration_id_elastic/sizes"
                                render={ (props) =>
                                    <Sizes setTitle={this._setTitle} configuration={this.props.singleConfiguration} preconfig={this.props.preconfig} {...this.props} />
                                }
                            />
                        </Switch>
                    </div>
    			</div>
       		</div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    	configurations : state.Configurations.configurations,
        singleConfiguration : Object.keys(state.Configurations.single_configuration).length ? state.Configurations.single_configuration : false,
        preconfig : (state.Configurations.configurations.preconfig.length ? state.Configurations.configurations.preconfig[0]  : false ),
    	user : state.User,
    	defaultSettings : state.User.default_settings,
        isLoggedIn : state.User.logged_in,
        user_profile_data : state.User.user_profile_data
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Configuration))
