const actions = {
	ADD_TO_COMPARE: 'ADD_TO_COMPARE',
	REMOVE_FROM_COMPARE: 'REMOVE_FROM_COMPARE',
	SET_COMPARE_ITEMS : 'SET_COMPARE_ITEMS',
	SET_COMPARE_ITEMS_FIREBASE : 'SET_COMPARE_ITEMS_FIREBASE',
	SET_SYNCED_COMPARE_ITEMS : 'SET_SYNCED_COMPARE_ITEMS',
	SYNC_COMPARE_ITEMS : 'SYNC_COMPARE_ITEMS',
	
	setCompareItems : ( payload ) => ({
		type : actions.SET_COMPARE_ITEMS,
		payload : payload
	}),

	setSyncedCompareItems : ( payload ) => ({
		type : actions.SET_SYNCED_COMPARE_ITEMS,
		payload : payload
	}),
	
	addToCompare: ( payload ) => ({ 
		type : actions.ADD_TO_COMPARE,
		payload : payload
	}),

	removeFromCompare:  (payload) => ({
		type: actions.REMOVE_FROM_COMPARE,
		payload: payload
	}),
};

export default actions;
