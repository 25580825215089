import React from 'react';
import BackgroundImage from '../../assets/img/background_image.png';
import { withRouter } from 'react-router-dom';

const PageHeader = (props) => {
    return (
        <div className={"sub__header " + (props.alignCenter ? 'sub__header--align-center' : null)} style={{backgroundImage: `url(${BackgroundImage})`}}>
            <h1>{props.title}</h1>
        </div>
    )
}

export default withRouter(PageHeader)
