import {
  checkIntersection
} from 'line-intersect';

import { parsePolynomialValues, getVariablePolynomialValues } from './graphCalculationHelpers';
import React from 'react';

import QHSeries from './Graphs/QHSeries';
import PowerSeries from './Graphs/PowerSeries';
import NPSHSeries from './Graphs/NPSHSeries';
import EfficiencySeries from './Graphs/EfficiencySeries';

import CustomizedRect from './Graphs/GraphComponents/CustomizedRect';
import CustomizedCircle from './Graphs/GraphComponents/CustomizedCircle';
/*

	Adjust to dutypoint
	Dutypoint is either the QH Opt, or the dutypoint the user requested
	Adjust to dutypoint calculates the frequence needed to make the configuration meet the required dutypoint
	This works best using polynomial values since they are closer together.

*/

class graphCalc {

	constructor(configuration, inputFrequence, dutyPoint = false, head = 0, unit = 'M', type = 'general') {

		this._unit = unit;
		this._curveValues = configuration[type].curve_values;
		this._preFilters = configuration.pre_filters;
		this._inputFrequence = inputFrequence;
		this._inputDutyPoint = dutyPoint;
		this._polynomialValues = configuration[type].polynomial_values;
        this._ratedPolynomialValues = parsePolynomialValues(configuration[type].polynomial_values, unit);
        this._variablePolynomialValues = getVariablePolynomialValues(this._ratedPolynomialValues, this._inputFrequence, unit);
		this._head = head;
		this._returnQOpt = false;
        this.dutyPointActual = false;
        this._dutyPoint = false;

		if ( dutyPoint === false ) {
			this._returnQOpt = true;
		}

        this._setClasses();
        this._setCalculations();
		this._setDutyPoint();
		this._setConstantPressure();
	}

    _setClasses = () => {
        this.QH = new QHSeries(this._ratedPolynomialValues, this._variablePolynomialValues, (this._preFilters ? this._preFilters.PRE_FILTER : false), this._returnQOpt);
        this.P2 = new PowerSeries(this._ratedPolynomialValues, this._variablePolynomialValues, this._returnQOpt, this.QH);
        this.NPSH = new NPSHSeries(this._ratedPolynomialValues, this._variablePolynomialValues, this._returnQOpt, this.QH);
        this.Efficiency = new EfficiencySeries(this._ratedPolynomialValues, this._variablePolynomialValues,  this._returnQOpt, this.QH);
    }

    _setCalculations = () => {
        this._highestQ      = this.QH._getHighestQ();
        this._QHSeries      = this.QH._getQHValues();
        this._qhOpt         = this.QH._getQHOptValues();
        this._P2Series      = this.P2._getPowerValues();
        this._NPSHSeries    = this.NPSH._getNPSHValues();
        this._EffSeries     = this.Efficiency._getEfficiencyValues();
    }
    // -- Start Duty Point
    _setDutyPoint = () => {
    	if( !this._curveValues ) {
    		return [];
    	}

    	if( this._inputDutyPoint ) {
    		this._dutyPoint = {
    			q: parseFloat(this._inputDutyPoint.q),
    			h: parseFloat(this._inputDutyPoint.h)
    		}

		} else {
			const H = parseFloat(this._preFilters['Nominal discharge head (fixed)']);
			const Q = parseFloat(this._preFilters['Nominal flow (fixed)']);

			if( H && Q ) {
				this._dutyPoint = {
					q: parseFloat(Q),
					h: parseFloat(H)
				}
			}
		}

    }

    _getDutyPoint = ( onlyData = false ) => {

    	if ( onlyData || !this._dutyPoint ) {
    		return this._dutyPoint;
    	}

		return [
			{
				dot : <CustomizedCircle />,
				activeDot : <CustomizedCircle />,
				stroke: '#d15337',
				name: 'Duty Point Required',
				data: [this._dutyPoint]
			}
		]

    }

    // -- End Duty Point
     _setConstantPressure = () => {

    	let constantPressureData = [];
    	const dutyPoint = this._dutyPoint;
    	for ( let i = 0; i <= this._highestQ; i += 0.1 ) {
     		constantPressureData.push({
    			q : i,
    			h : dutyPoint.h
    		})
    	}

    	this._constantPressure = constantPressureData;
    }

    _getConstantPressure = ( onlyData = false ) => {

    	if( onlyData || !this._constantPressure ) {
    		return this._constantPressure;
    	}

    	return [
			{
				name: 'Constant pressure',
				dot: false,
				activeDot: { stroke: '#d15337', strokeWidth: 2, fill: 'yellow' },
				stroke: '#a5a5a5',
				type: 'monotone',
				data: this._constantPressure,
			}
		];
    }

    /*
	*	Adjust to dutypoint calculates the frequence needed reach the dutypoint
	*
    */
    _getAdjustToDutyPointFrequency = () => {

        const dutyPoint = this._getDutyPoint(true);

		const lowestX = 0;
    	const highestX = this._highestQ;
    	const lowestY = dutyPoint.h;
    	const highestY = dutyPoint.h;

        let adjustToDutyPointFrequency = false;

    	let intersectionData = [];
    	let intersectionPoint = false;

    	// -- Loop through every frequence from 0 to 100 in steps of 0.01 to find an intersection point.
    	for ( let i = 0; i <= 100; i += 0.01 ) {

            const polynomialValues = getVariablePolynomialValues(this._ratedPolynomialValues,parseFloat(i.toFixed(2)));

    		const frequence = parseFloat(i.toFixed(2));

	    	// -- Loop through all polynomial values end check each point for an intersection with our constant pressure line.
	    	for ( let i = 0; i <= polynomialValues.length; i++ ) {
	    		if( polynomialValues[i] && polynomialValues[i+1]) {
	    			let item1 = polynomialValues[i];
	    			let item2 = polynomialValues[i+1];

	    			const intersection = checkIntersection(item1.q, item1.h, item2.q, item2.h, lowestX, lowestY, highestX, highestY);

	    			if ( intersection.type === 'intersecting' ) {
	    				// -- Intersection occured; return the frequency we used to find our intersection point.
						intersectionPoint = intersection;

						if(parseFloat(intersectionPoint.point.x.toFixed(2)) === parseFloat(dutyPoint.q)) {

							adjustToDutyPointFrequency = frequence;

							intersectionData.push({
								q : intersectionPoint.point.x,
								h: intersectionPoint.point.y,
								frequence : frequence
							})
						}
					}
	    		}
	    	}
    	}
    	// -- We got multiple intersection points; return the closest one.
        let index = 0;
    	if ( intersectionData.length > 1 ) {

            // -- If the length is uneven, we can't get the middle one so we need to loop 'm all
            if ( (intersectionData.length % 2) === 0 ) {

                let difference = false;

    			for ( let i = 0; i <= intersectionData.length; i++ ) {
    				if ( intersectionData[i] ) {
    					if ( difference === false ) {
    						difference = Math.abs(dutyPoint.q - intersectionData[i]['q']);
    						index = i;
    					}

    					if ( Math.abs(dutyPoint.q - intersectionData[i]['q']) < difference) {
    						difference = Math.abs(highestY - intersectionData[i]['h']);
    						index = i;
    					}
    				}
    			}
            } else {
                // The length is uneven, so there's a middle item. Testing shows we can safely assume this is the closest result.
                index = Math.floor(intersectionData.length / 2);

            }

			adjustToDutyPointFrequency = intersectionData[index]['frequence'];
		}
    	return adjustToDutyPointFrequency;
    }

    _getVariableQHConstantPressureIntersection = ( onlyData = false ) => {


        const dutyPoint = this._dutyPoint;

    	// -- Get polynomial values
    	const polynomialValues = this._variablePolynomialValues;

    	// -- Set X and Y values for constant pressure.
    	const lowestX = 0;
    	const highestX = this._highestQ;
    	const lowestY = dutyPoint.h;
    	const highestY = dutyPoint.h;

    	let intersectionData = [];
    	let intersectionPoint = false;


    	// -- Loop through all polynomial values end check each point for an intersection with our constant pressure line.
    	for ( let i = 0; i <= polynomialValues.length; i++ ) {
    		if( polynomialValues[i] && polynomialValues[i+1]) {
    			let item1 = polynomialValues[i];
    			let item2 = polynomialValues[i+1];

    			const intersection = checkIntersection(item1.q, item1.h, item2.q, item2.h, lowestX, lowestY, highestX, highestY);

    			if ( intersection.type === 'intersecting' ) {
    				// -- Intersection occured; push our intersection point to the array contain all intersectionpoints.
					intersectionPoint = intersection;
					intersectionData.push({
						q : parseFloat(intersectionPoint.point.x),
						h: parseFloat(intersectionPoint.point.y)
					})
				}
    		}
    	}

    	if ( intersectionPoint ) {

    		if ( intersectionData.length > 1 ) {
    			let index = 0;
    			let difference = false;
    			for ( let i = 0; i <= intersectionData.length; i++ ) {
    				if ( intersectionData[i] ) {
    					if ( difference === false ) {
    						difference = Math.abs(highestY - intersectionData[i]['h']);
    						index = i;
    					}

    					if ( Math.abs(highestY - intersectionData[i]['h']) < difference) {
    						difference = Math.abs(highestY - intersectionData[i]['h']);
    						index = i;
    					}
    				}
    			}

    			intersectionData = intersectionData[index];
    		}
    	}

    	if ( onlyData ) {
    		return intersectionData;
    	}

        this.dutyPointActual = intersectionData;

		return [
			{
				name: 'Duty Point Actual',
				dot : <CustomizedRect />,
				activeDot : <CustomizedRect />,
				stroke: '#d15337',
				type: 'monotone',
				data: intersectionData
			}
		];

	}

	_getVariableQHSystemCurveIntersection = ( onlyData = false ) => {

        const dutyPoint = this._dutyPoint;

   		const systemCurveValues = this._getSystemCurve( true );

   		// -- Set X and Y values for constant pressure.

    	const highestY = dutyPoint.h;

    	let intersectionData = [];
    	let intersectionPoint = false;


    	let qhPoints = this.QH._getQHSeries();
    	if ( qhPoints[1] ) {

    		qhPoints = qhPoints[1].data;
    	} else {
    		return;
    	}


    	// -- Loop through all polynomial values end check each point for an intersection with our constant pressure line.
    	for ( let i = 0; i <= systemCurveValues.length; i++ ) {
    		if( systemCurveValues[i] && systemCurveValues[i+1]) {
    			let item1 = systemCurveValues[i];
    			let item2 = systemCurveValues[i+1];

    			for ( let j = 0; j <= qhPoints.length; j++ ) {

    				if( qhPoints[j] && qhPoints[j+1]) {

    					let qhItem1 = qhPoints[j];
    					let qhItem2 = qhPoints[j+1];

	    				const intersection = checkIntersection(item1.q, item1.h, item2.q, item2.h, qhItem1.q, qhItem1.h, qhItem2.q, qhItem2.h);

		    			if ( intersection.type === 'intersecting' ) {
		    				// -- Intersection occured; push our intersection point to the array contain all intersectionpoints.
							intersectionPoint = intersection;
						
							intersectionData.push({
								q : parseFloat(intersectionPoint.point.x),
								h : parseFloat(intersectionPoint.point.y)
							})
						}
					}
    			}
    		}
    	}

    	if ( intersectionPoint ) {
    		if ( intersectionData.length > 1 ) {
    			let index = 0;
    			let difference = false;
    			for ( let i = 0; i <= intersectionData.length; i++ ) {
    				if ( intersectionData[i] ) {
    					if ( difference === false ) {
    						difference = Math.abs(highestY - intersectionData[i]['h']);
    						index = i;
    					}

    					if ( Math.abs(highestY - intersectionData[i]['h']) < difference) {
    						difference = Math.abs(highestY - intersectionData[i]['h']);
    						index = i;
    					}
    				}
    			}
    			intersectionData = intersectionData[index];
    		}
    	}

    	if ( onlyData  ) {
    		return intersectionData;
    	}

        this.dutyPointActual = intersectionData;

		return [
			{
				name: 'Duty Point Actual',
				dot : <CustomizedRect />,
				activeDot : <CustomizedRect />,
				stroke: '#d15337',
				type: 'monotone',
				data: intersectionData
			}
		];
	}
	/**
	 * Calculate systemcurve.
	 * Y = Constante * a.x2 + voordruk
	 * Then we use Y to calculate points on graph by multiplying a.Q2
	 * Edit 24-2 ook een constante moeten we uitrekenen.
	 * Constante = (dutypoint.h - opvoerhoogte)/dutypoint.q^2
	 */
	_getSystemCurve = ( onlyData = false ) => {

		const dutyPoint = this._getDutyPoint(true);

		const a = (dutyPoint.h - this._head) / (dutyPoint.q * dutyPoint.q);

		//const a = dutyPoint.h / (dutyPoint.q * dutyPoint.q);

		const polynomialValues = this._ratedPolynomialValues;
        let systemCurve = [];
        if(polynomialValues.length) {
            for(let i = 0; i <= polynomialValues.length; i++)
            {
                if(polynomialValues[i] !== undefined) {
                    let h = (a * (polynomialValues[i].q * polynomialValues[i].q) + this._head);
                   // if ( h <= highestH + 10) {
                         systemCurve.push({q: polynomialValues[i].q, h: h})
                    //}

                }
            }
        }
		if ( onlyData ) {
			return systemCurve;
		}
		return [
			{
				name: 'Systemcurve',
				dot : false,
				activeDot : { stroke: 'green', strokeWidth: 2, fill: 'green' },
				stroke: '#a5a5a5',
				type: 'monotone',
				data: systemCurve
			}
		];
	}
}

export default graphCalc;
