import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../Common/Popup';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import priceRequestActions from '../../redux/priceRequests/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';


class PriceRequestPopup extends Component {

	state = {
		activeOption: null,
		address: '',
		comments: '',
		name: '',
		email: '',
		phone: '',
		company: '',
	}

	options = [
		{
			name: __.fs('Configuration.PriceRequestPopup.price'),
			value: "price"
		},
		{
			name:  __.fs('Configuration.PriceRequestPopup.delivery-time'),
			value: "delivery_time"
		},
		{
			name: __.fs('Configuration.PriceRequestPopup.price-and-delivery-time'),
			value: "price_and_delivery_time"
		}
	]

	selectOption = (e, option) => {
		e.preventDefault();
		this.setState({
			activeOption: option
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	submit = () => {

		let error = false;

		const addressIsValid = !validator.isEmpty(this.state.address);
		const showAddress = this.state.activeOption === "delivery_time" || this.state.activeOption === "price_and_delivery_time";

		if(showAddress){
			if(!addressIsValid) {
				error = true;
				this.props.actions.setMessage({
					message: __.fs('components.Configuration.PriceRequestPopup.add-address'),
					type: 'error'
				})
			}
		}


		if ( !this.state.name ) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('components.Configuration.PriceRequestPopup.add-name'),
				type: 'error'
			})
		}

		if ( !this.state.phone ) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('components.Configuration.PriceRequestPopup.add-phone-number'),
				type: 'error'
			})
		}

		if ( !this.state.email ) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('components.Configuration.PriceRequestPopup.add-email'),
				type: 'error'
			})
		}

		if ( !this.state.company ) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('components.Configuration.PriceRequestPopup.add-company-name'),
				type: 'error'
			})
		}

		if(!this.state.activeOption){
			error = true;
			this.props.actions.setMessage({
				message: __.fs('components.Configuration.PriceRequestPopup.select-option'),
				type: 'error'
			})
		}



		if(!error){
			this.props.actions.addPriceRequestSingleProduct({
				configuration: this.props.data.configuration,
				address: showAddress ? this.state.address : "",
				name : this.state.name,
				phone: this.state.phone,
				email: this.state.email,
				company: this.state.company,
				comments: this.state.comments,
				type: this.state.activeOption
			});

			this.props.closePopup();
		}

	}

	render() {
		const showAddress = this.state.activeOption === 'delivery_time' || this.state.activeOption === 'price_and_delivery_time';
		return (
			<Popup
				visible={this.props.data.visible}
				popup_title={(this.props.data ? this.props.data.title : null)}
				fullscreen={true}
				size={'large'}
				backdrop={true}
				togglePopup={this.props.closePopup}>
				<div className="price-request-form">
					<span>Type verzoek:</span>
					{
						this.options.map(item => {
							const activeClass = this.state.activeOption === item.value ? "price-request-form__type__checkbox--active" : " ";
							return(
								<div key={item.value} className="price-request-form__type">
									<span  onClick={e => this.selectOption(e, item.value)} className={"price-request-form__type__checkbox " + activeClass}></span>
									<span>{item.name}</span>
								</div>

							)
						})
					}


					<div className="form-group">
						<label>Naam</label>
						<input type="text" value={this.state.name} onChange={this.handleInputChange} id="name" name="name" placeholder="Naam" />
					</div>
					<div className="form-group">
						<label>E-mailadres</label>
						<input type="text" value={this.state.email} onChange={this.handleInputChange} id="email" name="email" placeholder="E-mailadres"/>
					</div>
					<div className="form-group">
						<label>Telefoonnummer</label>
						<input type="text" value={this.state.phone} onChange={this.handleInputChange} id="phone" name="phone" placeholder="Telefoonnummer"/>
					</div>

					<div className="form-group">
						<label>Bedrijf</label>
						<input type="text" value={this.state.company} onChange={this.handleInputChange} id="company" name="company" placeholder="Bedrijf" />
					</div>


					{showAddress &&
		      		<div className="form-group">
		      			<label>Adres</label>
						<input type="text" value={this.state.address} onChange={this.handleInputChange} id="address" name="address" placeholder="Adres"/>
					</div>
					}
		      		<div className="form-group">
		      			<label>Opmerkingen</label>
						<textarea onChange={this.handleInputChange} value={this.state.comments} placeholder="Opmerkingen" id="comments" name="comments" />
					</div>

					<button className="button" onClick={this.submit} type="submit">Verzoek indienen</button>
				</div>
			</Popup>
		)

	}
}

const mapStateToProps = (state, ownProps) => {
    return {
         isLoggedIn: state.Auth.user_token !== null ? true : false,
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...priceRequestActions, ...messageActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PriceRequestPopup))
