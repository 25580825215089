import { round, findClosestQ } from '../graphCalculationHelpers';
import React from 'react';
import CustomizedRect from './GraphComponents/CustomizedRect';

class NPSHSeries {
    constructor(ratedPolynomialValues, variablePolynomialValues, returnQOpt, QH) {
        this._returnQOpt = returnQOpt;
        this.QH = QH;
        this.ratedPolynomialValues = ratedPolynomialValues;
        this.variablePolynomialValues = variablePolynomialValues;

    }

    _getNPSHValues = () => {

        return {
            rated : this.ratedPolynomialValues.map(this._mapValues),
            variable : this.variablePolynomialValues.map(this._mapValues)
        }
    }

    _mapValues = (item, key) => {

        return {
            npsh_q : parseFloat(item.q),
            npsh_h: parseFloat(item.npsh_h),
        }
    }


   _getNPSHSeries = ( actualDutyPoint ) => {
        let returnSeries = [
            {
                name: 'Rated NPSH',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 1,
                data: this._getNPSHValues().rated
            },
            {
                name: 'Variable NPSH',
                dot: false,
                activeDot: { stroke: '#d15337' },
                stroke: '#32a9e7',
                type: 'monotone',
                strokeWidth: 2,
                data: this._getNPSHValues().variable
            }

        ];

        if ( this._returnQOpt ) {
            const optSeries = this._getOptValues();
            if ( optSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        npsh_q : parseFloat(optSeries.q),
                        npsh_h : parseFloat(optSeries.npsh_h)
                    }]
                })
            }
        }

        if ( actualDutyPoint ) {
            const actualSeries = this._getActualValues(actualDutyPoint);
            if ( actualSeries ) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    data: [{
                        npsh_q : parseFloat(actualSeries.q),
                        npsh_h : parseFloat(actualSeries.npsh_h)
                    }]
                })
            }
        }

        return returnSeries;
   }

   _getNPSHOpt = (actualDutyPoint) => {
       if ( actualDutyPoint ) {
           const actualSeries = this._getActualValues(actualDutyPoint);
           if ( actualSeries ) {
               return actualSeries.npsh_h;
           }

           return false;
       }

       return false;
   }

   _getActualValues = (actualDutyPoint) => {
       if( actualDutyPoint.q ) {
           const values =  this._getNPSHValues().variable;
           const index = findClosestQ(actualDutyPoint, 'npsh_q', values);
           return {
               q : values[index].npsh_q,
               npsh_h : values[index].npsh_h
           }
       }
   }

    _getOptValues = ( isQH = false) => {
        const QHOpt = this.QH._getQHOpt ( true );
        if ( !QHOpt ) {
            return false;
        }

        if( QHOpt.q ) {

            for ( let i = 0; i <= this._getNPSHValues().rated.length; i++ ){
                if ( this._getNPSHValues().rated[i] ) {
                    if ( round(parseFloat(this._getNPSHValues().rated[i].npsh_q), 2) === round(QHOpt.q, 1) ) {

                        return {
                            q : this._getNPSHValues().rated[i].npsh_q,
                            npsh_h : this._getNPSHValues().rated[i].npsh_h
                        }
                    }
                }
            }
        }
    }
}

export default NPSHSeries;
