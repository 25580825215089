import actions from './actions';

const initState = { listCategories: null };



export default function listCategoriesReducer(state = initState, action) {
	
	switch (action.type) {  
		case actions.SET_LIST_CATEGORIES:
			return {...state, listCategories: action.payload}
		default:
			return state;
	}
}
