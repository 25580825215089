import React from 'react';
import __ from '../../../helpers/language';
import { round } from '../../../helpers/graphCalculationHelpers';
const AdjustCurves = props => {
    return (

        <React.Fragment>
            <div className={"pre__filters__select-graph-type "}>
                <button
                    onClick={ () => props.setGraphType('systemcurve') }
                    className={"type-curve pre__filters__select-type__button pre__filters__select-type__button--QH button button--inverted " + (props.graphType === 'systemcurve' ? 'button--inverted--active' : null)}
                >
                    {__.fs('Configuration.Graphs.Systemcurve')}
                </button>

                {props.graphType === 'systemcurve' ?
                    <div className="input input--head">
                        <label className="input__label">{__.fs('Configuration.Graphs.Head')}</label>
                        <input className="input__field"  onChange={ (e) => props.changeHead(e) } type="number" placeholder={__.fs('Configuration.Graphs.Head')} value={props.head ? props.head : '0'}/>
                    </div> :
                null}
                <button
                    onClick={ () => props.setGraphType('constantpressure') }
                    className={"type-pressure pre__filters__select-type__button pre__filters__select-type__button--filters button button--inverted " + (props.graphType === 'constantpressure' ? 'button--inverted--active' : null)}
                >
                    {__.fs('Configuration.Graphs.ConstantPressure')}
                </button>


                { (props.dutyPoint !== false && props.adjustToDutyPointFrequency) ?
                    <button
                        onClick={ () => props.adjustToDutypoint(props.adjustToDutyPointFrequency) }
                        className={"adjust-to-dutypoint pre__filters__select-type__button pre__filters__select-type__button--filters button button--inverted "}
                    >
                    {__.fs('Configuration.GraphComponents.AdjustCurves.AdjustToDutyPoint')} ({round(props.adjustToDutyPointFrequency, 1)})
                    </button>
                : null }

            </div>
        </React.Fragment>
    )
}

export default AdjustCurves;
