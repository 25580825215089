import React from 'react';

const TC = (props) => {
    return (
    	<div className="display__table">
          	<div className="display__table__cell">
    			{props.children}
            </div>
        </div>
    );
}

export default TC;
