import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../Common/Popup';
import CreatableSelect from 'react-select/creatable';
import productListActions from '../../redux/productList/actions';
import compareActions from '../../redux/compare/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';

class AddToListPopup extends Component {

	state = {
		listName: "",
		selectedList: null
	}

	mapProductLists = () => {
		const { productLists } = this.props;

		let options = [];
		if(productLists){
			options = productLists.map(item => {
				item.value = item.id;
				item.label = item.name;
				return item;
			});
		}

		return options;
	}

	getSelectedValue = () => {
		let selectedValue = [];
		const productLists = this.mapProductLists();
		const defaultList = productLists.find(item => item.default);


		// if user already changed select value
		if(this.state.selectedList){
			selectedValue = this.state.selectedList;
		}
		else{
			// if default list exists
			if(defaultList){
				this.setState({
					selectedList: defaultList
				});
			}
		}

		return selectedValue;
	}

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handleSelectChange = (selectedOption) => {
		this.setState({
			selectedList: selectedOption
		});
	}

	handleCreate = (listName) => {
		this.setState({
			selectedList: {value: null, label: listName, newList: true}
		});
	}

	addToList = () => {
		let error = false;
		const configuration = {
			id: this.props.data.productData.id,
			products: this.props.data.productData.products,
			ch: this.props.data.productData.ch,
			price: this.props.data.productData.total_price,
			is_low_npsh : this.props.data.productData.is_low_npsh,
			pre_filters : this.props.data.productData.pre_filters
		}

		if(!this.state.selectedList){
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.AddToListPopup.select-list-first'),
				type: 'error'
			})
		}


		if(!error){
			if(this.state.selectedList.newList){
				this.props.actions.addListFirebase({
					name: this.state.selectedList.label,
					configuration
				});
			}
			else{
				this.props.actions.addProductToListFirebase({
					listId: this.state.selectedList.value,
					configuration
				});
			}
			this.props.closePopup();
		}

	}

	_addToCompare = ( e ) => {
		e.preventDefault();
		this.props.actions.addToCompare( {...this.props.data.productData} );
		this.props.closePopup();
	}

	render() {
		console.log(this.props);
		return (
			<Popup visible={this.props.data.visible} popup_title={(this.props.data ? this.props.data.title : null)} fullscreen={true} size={'large'} backdrop={true}  togglePopup={this.props.closePopup}>
				<small className="description">{__.fs('Lists.AddToListPopup.search-or-create')}</small>
				<CreatableSelect
					options={this.mapProductLists()}
					value={this.getSelectedValue()}
					onChange={this.handleSelectChange}
					placeholder="Selecteer lijst"
					onCreateOption={this.handleCreate}
					formatCreateLabel={string => __.fs('Lists.AddToListPopup.add-to-new-list') + string}
					/>
				<button className="button" style={{marginTop: '25px'}} onClick={this.addToList} type="submit">{__.fs('Lists.AddToListPopup.add-to-list')}</button>

			</Popup>
		)

	}
}


const mapStateToProps = (state, ownProps) => {
    return {
    	defaultSettings : state.DefaultSettings,
    	user : state.User,
    	productLists: state.ProductLists.productLists
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...productListActions,...compareActions,...messageActions}, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(AddToListPopup)
