import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderWithCards from '../Common/HeaderWithCards';
import productActions from '../../redux/products/actions';
import messageActions from '../../redux/message/actions';
import { bindActionCreators } from 'redux';
import { getUniqueArray, categorizeFilters, findFilterExists, rebuildFilterArray } from '../../helpers/utility';
import Autosuggest from 'react-autosuggest';
import __ from '../../helpers/language';

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.label}
  </div>
);

const getSuggestionValue = suggestion => suggestion.label;

class SpareParts extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	products : [],
        	autoCompleteSuggestions: [],
        	inputValue : false,
        	suggestions : [],
        	value : '',
        	activeFilters: []
        }
    }

    componentWillMount() {

    	if(!this.props.products.length) {
    		this.props.actions.fetchProducts();
    	}else{
    		this._formatAndGroupProducts(this.props.products);
    	}
    }

    componentDidUpdate(prevProps) {
    	if(prevProps.products.length !== this.props.products.length) {
    		this._formatAndGroupProducts(this.props.products);
    	}
    }

    onChange = (event, { newValue }) => {
	    this.setState({
	      value: newValue
	    });
	};

    _formatAndGroupProducts = (products) => {

    	const autoCompleteProducts = this._getAutoCompleteProducts(products);
    	this.setState({
    		products : products,
    		autoCompleteSuggestions : autoCompleteProducts
    	})
    }

	getSuggestions = value => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0 ? [] : this.state.autoCompleteSuggestions.filter(item =>
			item.label.toLowerCase().slice(0, inputLength) === inputValue
		);
	}

    _getAutoCompleteProducts = (products) => {
    	let suggestions = [];
    	for( let i = 0; i <= products.length; i++ ) {
    		if( products[i] ) {
    			suggestions.push({label : products[i]['code']});
    		}
    	}

    	return suggestions;
    }

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value).slice(0, 5)
		});
	}


	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	}


	_shouldRenderSuggestions = value => {
		return value.trim().length > 2;
	}

	    _setActiveFilters = (activeFilters) => {

    	activeFilters = getUniqueArray(activeFilters);
    	this.setState({
    		activeFilters : activeFilters
    	})

    	this.filterProducts(activeFilters);
    }

    _findItemContainsSearchString(configuration, filters) {
    	let stringifiedConfiguration = JSON.stringify(configuration).toLowerCase();

    	let numberOfSearchStrings = 0;
    	let numberOfFinds = 0;
    	for(let category in filters) {
    		if(category === 'text') {
	    		if(filters.hasOwnProperty(category)) {

	    			let filter = filters[category];

	    			for(let searchQuery in filter) {
	    				numberOfSearchStrings++;

	    				if( stringifiedConfiguration.includes(searchQuery.toLowerCase()) ) {
	    					numberOfFinds++;
	    				}
	    			}
	    		}
	    	}
    	}

    	return numberOfSearchStrings === numberOfFinds;
    }


    filterProducts = (activeFilters) => {
    	const products = [...this.props.products];
    	let resultProducts = [];
    	let categorizedFilters = categorizeFilters(activeFilters);


    	for( let i = 0; i <= products.length; i++ ) {
    		if( products[i] ) {
    			const product = products[i];

    			const textSearchResult = this._findItemContainsSearchString(product, categorizedFilters);

    			if(textSearchResult) {
    				resultProducts.push(product);
    			}
    		}
    	}

    	this._formatAndGroupProducts(resultProducts);
    }

	_submitSearchField = (e) => {
    	e.preventDefault();

    	if(this.state.value) {
    		if(this.state.value.length >= 3) {
    			let activeFilters = [...this.state.activeFilters];
    			if(activeFilters) {
		        	activeFilters.push({
		    			filterCategory : 'text',
		    			filterProperty : this.state.value
		    		})
				}

				this.setState({
					...this.state,
					value : '',
					suggestions : []
				})

				this._setActiveFilters(activeFilters);
    		}else{
                this.props.actions.setMessage({
                	message: __.fs('alert.filters.minimum-characters'),
                	type: 'error'
                })
    		}

    	}else{
            this.props.actions.setMessage({
            	message: __.fs('alert.filters.term-required'),
            	type: 'error'
            })
    	}

    }

    _renderFilterBreadcrumbs(item, key) {
    		return (<li key={key}> {item.filterProperty}  <i className="fas fa-times" onClick={(event) => this._filterClicked(event, item.filterCategory, item.filterProperty)}></i></li>)

    }

        _filterClicked = (event, filterCategory, filterProperty) => {
    	event.preventDefault();
    	let activeFilters = [...this.state.activeFilters];
		if(activeFilters) {

			const filterIndex = findFilterExists(filterCategory, filterProperty, [...this.state.activeFilters]);

    		// -- Filter does not exists in current active filter list, add it.
	        if(filterIndex === -1) {
	        	activeFilters.push({
	    			filterCategory : filterCategory,
	    			filterProperty : filterProperty
	    		})
	        }else{
	        	// -- Does exist, remove it.
	        	activeFilters = rebuildFilterArray(activeFilters, filterIndex);
	        }
		}

    	this._setActiveFilters(activeFilters);
    }


    render() {
    	const { value, suggestions } = this.state;
        const inputProps = {
		    placeholder: 'Zoek een product',
		    value ,
		    onChange: this.onChange
	    };
        return (

          	<div>

          		<HeaderWithCards />
          		<div className={"selected__filters collapse collapse--open"}>
           			<div className="collapse__inner">

	 					<div className="container">
		 					<h3>{__.fs('main.words.selection')}</h3>
		 					<h2>{this.props.activeFilterCategory}</h2>

		 				</div>
		 				<div className="container container--small">
		 					<ul className="filters__breadcrumbs"><li>{this.state.autoCompleteSuggestions.length} resultaten</li>{this.state.activeFilters ? this.state.activeFilters.map((filter) => this._renderFilterBreadcrumbs(filter)) : null}</ul>
		 				</div>
		 			</div>
		 		</div>
           		<div className={"available__filters " }>
	          		<div className="full__width filters">
	          			<ul className="filters__input filters__input--grid filters__input--grid--5">
	          				<li className="filters__input__Q">
	          					<label>Q</label>
	          					<input type="text"/>
	          				</li>
	          				<li className="filters__input__Q">
	          					<label>H</label>
	          					<input type="text"/>
	          				</li>
	          				<li className="filters__input__or">
	          					{__.fs('main.words.or')}
	          				</li>
	          				<li className="filters__input__search">
	          				<label><i className="fas fa-search"></i></label>
	          					{this.state.autoCompleteSuggestions ?
				          		  <Autosuggest
							        suggestions={suggestions}
							        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
							        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
							        getSuggestionValue={getSuggestionValue}
							        renderSuggestion={renderSuggestion}
							        inputProps={inputProps}
							        shouldRenderSuggestions={this._shouldRenderSuggestions}
							      />
							      : null }
	          				</li>
	          				<li className="filters__input__submit">

	          					<input type="submit" value={__.fs('main.words.search')} className="button" onClick={this._submitSearchField}/>
	          				</li>
	          			</ul>
	               	</div>
               	</div>

       		</div>


        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
    	products : state.Products.products
    }
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...productActions, ...messageActions}, dispatch) })

export default connect(mapStateToProps,mapDispatchToProps)(SpareParts)
