import React from 'react';
import TC from '../Common/TC';
import { NavLink } from 'react-router-dom';
import __ from '../../helpers/language';
import { round } from '../../helpers/graphCalculationHelpers';
import ReactTooltip from 'react-tooltip';
const graphTooltip =
	<ReactTooltip id="tip_graph" className='tooltip'>
		<span>Bekijk grafiek</span>
	</ReactTooltip>
;
export const getColumns = (functions) => {
	const Columns = [
    	{
			Header: '',
			expander: true,
			width: 0,
			sortable: false,
			show: false,
			resizable : false
		},
		{
			Header: '',
			resizable : false,
			sortable: false,
			id: 'ch',
			width: 250,
      		accessor: 'ch',
			className: "text__color--black border--right--none",
			Cell: props => <TC><span className='number'><span><strong>{props.original.artnr}: </strong></span><NavLink to={functions._buildCHURL(props.value, props.original.is_low_npsh)}>{functions._buildDescription(props.original.pre_filters.description, 'short_description')}</NavLink>{functions.renderExpander(props)}</span><NavLink className="configure-button" to={functions._buildCHURL(props.value, props.original.is_low_npsh)}>{__.fs('Category.Columns.configure-this-basic-configuration')}</NavLink></TC>,
		},
		{
			Header: '',
			resizable : false,
			id: '',
			sortable: false,
			width: 75,
			Cell: props => <React.Fragment>{graphTooltip}<TC><span data-tip data-for='tip_graph' className="icon__chart" onClick={(e) => functions.toggleChartPopup(e, props.original)}> <i className="fas fa-chart-line"></i></span></TC></React.Fragment>
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.grossPrice') + ' (€)',
			resizable : false,
			id: 'grossPrice',
			accessor: 'total_price',
			width: 100,
			className: "text__color--black text__style--bold",
			Cell: props => <TC>€ {functions.getPrice(props.value, 'bruto')}</TC>,
			sortMethod: (a, b) => {
				a = parseFloat(functions.getPrice(a, 'bruto'));
				b = parseFloat(functions.getPrice(b, 'bruto'));
				if (a === b) {
					return 0;
				}
				return a > b ? 1 : -1;
			}
		},
		/*
		{
			Header: __.fs('ConfiguredProducts.Columns.netPrice'),
			resizable : false,
			id: 'netPrice',
			show: functions.shouldDisplayNetPrice(),
			width: 100,
			accessor: 'total_price',
			Cell: props => <TC>€ {functions.getPrice(props.value, 'netto')}</TC>
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.discount'),
			resizable : false,
			show: functions.shouldDisplayNetPrice(),
			id: 'discount',
			width: 100,
			accessor: 'total_price',
			Cell: props => <TC>{functions.getDiscount(functions.getPrice(props.value, 'bruto'), functions.getPrice(props.value, 'netto'))}</TC>,
			sortMethod: (a, b) => {
				a = parseFloat(functions.getDiscount(functions.getPrice(a, 'bruto'), functions.getPrice(a, 'netto')));
				b = parseFloat(functions.getDiscount(functions.getPrice(b, 'bruto'), functions.getPrice(b, 'netto')));
				if (a === b) {
                    return 0;
                }
                return a > b ? 1 : -1;
	         }
		},*/
		{
			Header: __.fs('ConfiguredProducts.Columns.efficiency'),
			resizable : false,
			id: 'efficiency',
			width: 150,
			defaultSorted: true,
			accessor: 'general',
			Cell: props => <TC>{round(functions.getOpt(props.original, 'eff', 'eff'), 2)}</TC>,
            sortMethod: (a, b) => {
                let objectA = {
                    general : a
                }
                let objectB = {
                    general: b
                }
				a = parseFloat(functions.getOpt(objectA, 'eff', 'eff'));
				b = parseFloat(functions.getOpt(objectB, 'eff', 'eff'));
				if (a === b) {
                    return 0;
                }
                return a > b ? 1 : -1;
	         }
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.pRequired'),
			resizable : false,
			id: 'prequired',
			width: 150,
			accessor: 'general',
			Cell: props => <TC>{round(functions.getOpt(props.original, 'power', 'p'), 2)}</TC>,
			sortMethod: (a, b) => {
				let objectA = {
					general : a
				}
				let objectB = {
					general: b
				}
				a = parseFloat(functions.getOpt(objectA, 'power', 'p'));
				b = parseFloat(functions.getOpt(objectB, 'power', 'p'));
				if (a === b) {
					return 0;
				}
				return a > b ? 1 : -1;
			}
		},
        {
			Header: __.fs('ConfiguredProducts.Columns.NPSH'),
			resizable : false,
			id: 'npshreq',
			width: 150,
            accessor: 'general',
			Cell: props => <TC>{round(functions.getOpt(props.original, 'npsh', 'n'), 2)}</TC>,
            sortMethod: (a, b) => {
                let objectA = {
                    general : a
                }
                let objectB = {
                    general: b
                }
				a = parseFloat(functions.getOpt(objectA, 'npsh', 'n'));
				b = parseFloat(functions.getOpt(objectB, 'npsh', 'n'));
				if (a === b) {
                    return 0;
                }
                return a > b ? 1 : -1;
			}
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.productDescription'),
			resizable : false,
			sortable: false,
			id: 'products',
			Cell: props => <TC><span className='number'>{ functions._buildDescription(props.original.pre_filters.description, 'long_description')} <br />{functions.buildProductString(props.original.products, ',')}</span></TC>
		}
	]
	return Columns;
}
