import React from 'react';
import { round } from '../graphCalculationHelpers';
import CustomizedRect from './GraphComponents/CustomizedRect';
import CustomizedCircle from './GraphComponents/CustomizedCircle';

class QHSeries {
    constructor(ratedPolynomialValues, variablePolynomialValues, preFilters, returnQOpt) {

        this._preFilters = preFilters;
        this._returnQOpt = returnQOpt;


        this.ratedPolynomialValues = ratedPolynomialValues;
        this.variablePolynomialValues = variablePolynomialValues;

    }

    _getQHOptValues = () => {
       const H = parseFloat(this._preFilters['Nominal discharge head (fixed)']);
       const Q = parseFloat(this._preFilters['Nominal flow (fixed)']);

       if( H && Q ) {
           return {
               q: parseFloat(Q),
               h: parseFloat(H)
           }
       }
   }

    _getQHValues = () => {
        return {
            rated : this.ratedPolynomialValues.map(this._mapValues),
            variable : this.variablePolynomialValues.map(this._mapValues)
        }
    }

    _mapValues = (item, key) => {
        return {
            q : parseFloat(item.q),
            h: parseFloat(item.h),
        }
    }

    _getHighestQ = () => {
        let highestQ = 0;
        for ( let i = 0; i <= this.variablePolynomialValues.length; i++ ) {
            if ( this.variablePolynomialValues[i] ) {
                if ( this.variablePolynomialValues[i].q > highestQ ) {
                    highestQ = this.variablePolynomialValues[i].q;
                }
            }
        }
        return highestQ + 0.5;
    }

    _getHighestH = () => {
       let highestH = 0;
        for ( let i = 0; i <= this.variablePolynomialValues.length; i++ ) {
            if ( this.variablePolynomialValues[i] ) {
                if ( this.variablePolynomialValues[i].h > highestH ) {
                    highestH = this.variablePolynomialValues[i].h;
                }
            }
        }
        return highestH + 10;
    }

    _getQHSeries = ( ) => {

    	let returnSeries = [
    		{
				name: 'Rated QH',
				dot: false,
				activeDot: { stroke: '#d15337' },
				stroke: '#32a9e7',
				type: 'monotone',
				strokeWidth: 1,
				data: this._getQHValues().rated,
			},
			{
				name: 'Variable QH',
				dot: false,
				activeDot: { stroke: '#d15337'},
				stroke : '#32a9e7',
				type: 'monotone',
				strokeWidth: 2,
				data: this._getQHValues().variable,
			},

    	];

        if ( this._returnQOpt ) {
    		const optSeries = this._getOptValues(true);

            if ( optSeries) {
                returnSeries.push ({
                    dot : <CustomizedRect />,
                    activeDot : <CustomizedRect />,
                    stroke: 'red',
                    id : 'OPT',
                    name: 'Opt',
                    legend: false,
                    data: [{
                        q : parseFloat(optSeries.q),
                        h : parseFloat(optSeries.h)
                    }]
                })
            }
    	}

        return returnSeries;

    }

    _getQHOpt = ( onlyData = false ) => {
       if ( onlyData || !this._qhOpt ) {
           return this._getQHOptValues();
       }

       return [
           {
               dot : <CustomizedCircle />,
               activeDot : <CustomizedCircle />,
               stroke: 'red',
               id : 'QHOPT',
               name: 'QH Opt',
               data: [this._getQHOptValues()]
           }
       ]
   }

   _getOptValues = ( ) => {
       const QHOpt = this._getQHOpt ( true );
       if ( !QHOpt ) {
           return false;
       }

       if( QHOpt.q ) {
           const qToUse = round(parseFloat(QHOpt.q), 1)
          

           for ( let i = 0; i <= this._getQHValues().rated.length; i++ ) {
               if ( this._getQHValues().rated[i] ) {
                   if ( this._getQHValues().rated[i].q === qToUse ) {
                       return this._getQHValues().rated[i];
                   }
               }
           }
       }
   }
}

export default QHSeries;
