import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FirebaseHelper from '../../helpers/firebase';
import actions from './actions';

const { rsfFirestore } = FirebaseHelper;
export function* fetchProducts() {

	yield takeEvery(actions.FETCH_PRODUCTS, function*(action) {
				
		const snapshot = yield call(rsfFirestore.getCollection, 'products');

		const products = FirebaseHelper.processFireStoreCollection(snapshot);
		let productArray = [];
		for(let key in products) {
			if(products.hasOwnProperty(key)) {
				productArray.push(products[key]);
			}
		}

		yield put({
			type: actions.SET_PRODUCTS,
			payload: productArray
		})
	})
}


export default function* rootSaga() {
	yield all([
		fork(fetchProducts),
		//fork(syncConfiguredProducts)
	]);
}
 