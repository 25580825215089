import nl_oracle from '../lang/nl_NL_Oracle.json';
import en_oracle from '../lang/en_EN_Oracle.json';

import nl_application from '../lang/nl_NL_Application.json';
import en_application from '../lang/en_EN_Application.json';

import store from '../redux/store';

class Language  {

	constructor() {

		this._findOracleString = this._findOracleString.bind(this);
		this.fs = this.fs.bind(this);
	}

	_findOracleString(identifier){
		let translations = false;

		if ( store.store.getState().User.default_settings.language === 'NL' ) {
			translations = nl_oracle;
		}
		if ( store.store.getState().User.default_settings.language === 'EN' ) {
			translations = en_oracle;
		}
		for ( let i = 0; i <= translations.length; i++ ) {
			if( translations[i] ) {
				if ( translations[i]['ORACLE_NAME'] === identifier ) {
					return translations[i]['TRANSLATED_NAME'];
				}
			}
		}




		console.warn("\n%c Missing Oracle string for " +  store.store.getState().User.default_settings.language + ': \n\n%c"' + identifier + '" : ""\n', 'background: #000000; color: #ffffff; font-size:15px', 'background: white');
		
		return "<||- " + identifier + " -||>";
	}
	// -- Find String
	fs(identifier) {


		let translations = false;		

		if ( store.store.getState().User.default_settings.language === 'NL' ) {
			translations = nl_application;
		}
		if ( store.store.getState().User.default_settings.language === 'EN' ) {
			translations = en_application;
		}

		if ( translations[identifier] ) {
			return translations[identifier];
		}

		console.warn("\n%c Missing Application string for " +  store.store.getState().User.default_settings.language + ': \n\n%c"' + identifier + '" : ""\n', 'background: #000000; color: #ffffff; font-size:15px', 'background: white');


		return "<||- " + identifier + " -||>";
		 

		
	}


}

export default new Language();