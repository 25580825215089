const actions = {
	FETCH_FILTERS: 'FETCH_FILTERS',
	SET_FILTERS: 'SET_FILTERS',
	
	fetchFilters : () => ({
		type: actions.FETCH_FILTERS
	}),
	
	setFilters : (payload) => ({
		type: actions.SET_FILTERS,
		payload:payload
	})

};

export default actions;
