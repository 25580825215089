import actions from './actions';

const initState = { configured_products: null };

export default function configuredProductsReducer(state = initState, action) {
	switch (action.type) {		
		case actions.SET_CONFIGURED_PRODUCTS:
		return { 
				...state, 
				configured_products: action.payload
			};	
		case actions.SET_RANDOM_PRODUCTS:		
		return { 
				...state, 
				random_products: action.payload,
				reset_products: [...action.payload]
			};	
		default:
			return state;
	}
}
 