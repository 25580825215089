import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../Common/Popup';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import priceRequestActions from '../../redux/priceRequests/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';

class PriceRequestPopup extends Component {

	state = {
		activeOption: null,
		address: '',
		comments: ''
	}

	options = [
		{
			name: __.fs('Lists.PriceRequestPopup.price'),
			value: "price"
		},
		{
			name: __.fs('Lists.PriceRequestPopup.delivery-time'),
			value: "delivery_time"
		},
		{
			name: __.fs('Lists.PriceRequestPopup.price-and-delivery-time'),
			value: "price_and_delivery_time"
		}
	]

	selectOption = (e, option) => {
		e.preventDefault();
		this.setState({
			activeOption: option
		})
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	submit = () => {

		let error = false;

		const addressIsValid = !validator.isEmpty(this.state.address);
		const showAddress = this.state.activeOption === "delivery_time" || this.state.activeOption === "price_and_delivery_time";

		if(showAddress){
			if(!addressIsValid) {
				error = true;
				this.props.actions.setMessage({
					message: __.fs('Lists.PriceRequestPopup.add-address'),
					type: 'error'
				})
			}
		}

		if(!this.state.activeOption){
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Lists.PriceRequestPopup.select-option'),
				type: 'error'
			})
		}

		if(!error){
			this.props.actions.addPriceRequest({
				listId: this.props.data.listId,
				address: showAddress ? this.state.address : "",
				comments: this.state.comments,
				type: this.state.activeOption
			});

			this.props.closePopup();
		}

	}

	render() {
		const showAddress = this.state.activeOption === 'delivery_time' || this.state.activeOption === 'price_and_delivery_time';

		return (
			<Popup
				visible={this.props.data.visible}
				popup_title={(this.props.data ? this.props.data.title : null)}
				fullscreen={true}
				size={'large'}
				backdrop={true}
				togglePopup={this.props.closePopup}>
				<div className="price-request-form">
				<span>{__.fs('Lists.PriceRequestPopup.request-type')}:</span>
				{
					this.options.map(item => {
						const activeClass = this.state.activeOption === item.value ? "price-request-form__type__checkbox--active" : " ";
						return(
							<div key={item.value} className="price-request-form__type">
				    			<span  onClick={e => this.selectOption(e, item.value)} className={"price-request-form__type__checkbox " + activeClass}></span>
								<span>{item.name}</span>
							</div>
						)
					})
				}
				{showAddress &&
	      		<div className="form-group">
	      			<label>{__.fs('Lists.PriceRequestPopup.address')}</label>
					<input type="text" onChange={this.handleInputChange} id="address" name="address" placeholder={__.fs('Lists.PriceRequestPopup.address')} style={{border: '1px solid #bdbdbd', padding: '10px 14px'}}/>
				</div>
				}
	      		<div className="form-group">
	      			<label>{__.fs('Lists.PriceRequestPopup.notes')}</label>
					<textarea onChange={this.handleInputChange} placeholder={__.fs('Lists.PriceRequestPopup.notes')} id="comments" name="comments" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}}>

					</textarea>
				</div>

				<button className="button" onClick={this.submit} type="submit">{__.fs('Lists.PriceRequestPopup.submit-request')}</button>
				</div>
			</Popup>
		)

	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token ? true : false
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...priceRequestActions, ...messageActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PriceRequestPopup))
