import React from 'react';

export const Footer = () => {
	return (
		<footer className="footer footer--main">

		</footer>
	)
}

export default Footer;
