import React, { Component } from 'react';
import { generateProductSheetsWithKey } from '../../helpers/productSheetHelpers';

class ProductSheet extends Component {
	componentDidMount () {
		this.props.setTitle("Technisch specificatieblad")
	}

	render() {
		return (
	      	<div className="specs-sheet">
				<div className="specs-sheet__content">
	          		<div className="specs-sheet__columns">
	          			<div className="specs-sheet__columns__column">
	          				{this.props.singleConfiguration ? generateProductSheetsWithKey(this.props.singleConfiguration.pre_filters.product_sheet, 'Connection base') : null}
							{this.props.singleConfiguration ? generateProductSheetsWithKey(this.props.singleConfiguration.pre_filters.product_sheet, 'Basic hydraulic data') : null}
							{this.props.singleConfiguration ? generateProductSheetsWithKey(this.props.singleConfiguration.pre_filters.product_sheet, 'Hydraulic efficiency') : null}
						</div>

						<div className="specs-sheet__columns__column">
	          				{this.props.singleConfiguration ? generateProductSheetsWithKey(this.props.singleConfiguration.pre_filters.product_sheet, 'Seal data') : null}
	          				{this.props.singleConfiguration ? generateProductSheetsWithKey(this.props.singleConfiguration.pre_filters.product_sheet, 'Plug') : null}
						</div>
	        		</div>
	        	</div>
	      	</div>
	    );
	}

}



export default ProductSheet
