import React, { Component } from 'react';
import { buildDescription} from '../../helpers/productSheetHelpers';
import Graphs from './Graphs';
import html2canvas from 'html2canvas';
import PriceRequestPopup from './PriceRequestPopup';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import __ from '../../helpers/language';
class PageHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphImage : false,
			priceRequestPopup: {
	    		visible: false
			},

		}
	}

	_closePriceRequestPopup = (e) => {
		this.setState({
			priceRequestPopup : {
				visible: false
			}
		})
	}

	_renderToImage = () => {
		let hz = '50Hz';

		if ( this.props.user ) {
			if ( this.props.user.default_settings ) {
				if ( this.props.user.default_settings.frequency ) {
					hz = this.props.user.default_settings.frequency.value;
				}
			}
		}
		setTimeout(function() {
			const elem = document.getElementById('Graphs');
			if ( elem ) {
				html2canvas(elem, {
					onclone: function (clonedDoc) {
						clonedDoc.getElementById('Graphs').style.display = 'block';
					}
				}).then((canvas)=>{
					var data = canvas.toDataURL('image/png');
					// -- Post image data to server
					let formData = new FormData();
					formData.append('image', data);
					formData.append('configuration_id', this.props.match.params.configuration_id_elastic);
					formData.append('hz', hz);
					fetch(global.serverURL + "productsheet/postImage/", {
			            method: 'POST',
			            body: formData,

			        })
			        .then((response) => {
			            if (response.ok) {
			                return response.text();
			            }
			        })
			        .then((response) => {
						let serverResponse = JSON.parse(response);

						if ( serverResponse.imageExists ) {
							this.setState({
								graphImage: true
							})
						}

			        })
			        .catch(() => {

			        });
				})
			}
		}.bind(this), 3000);

	}

	_generateProductSheets = (object) => {
		let rows = [];
		for(const key in object) {
				if ( object.hasOwnProperty(key) ) {
					rows.push(this._displayRowHeader(key));
					rows.push(object[key].map((value,key) => this._mapProductsheetSpecs(value, key)));
				}
			}
		return <tbody>{rows}</tbody>
	}

	_togglePriceRequestPopup = (e) => {

		e.preventDefault();

		this.setState({
			priceRequestPopup : {
				visible : true,
				title : __.fs('Configuration.PageHeader.price-request'),
				configuration: this.props.singleConfiguration
			}
		})
	}


	_fetchUserProfileFields = () => {

		if ( this.props.user_profile_data.company && this.props.user_profile_data.street_number && this.props.user_profile_data.zipcode_city && this.props.user_profile_data.country  ) {
			return (
				<React.Fragment>
					<input type="hidden" name="client_data_company" defaultValue={this.props.user_profile_data.company}/>
					<input type="hidden" name="client_data_street_number" defaultValue={this.props.user_profile_data.street_number}/>
					<input type="hidden" name="client_data_zipcode_city" defaultValue={this.props.user_profile_data.zipcode_city}/>
					<input type="hidden" name="client_data_country" defaultValue={this.props.user_profile_data.country}/>
					<input type="hidden" name="client_data_uid" defaultValue={this.props.user.user_profile.uid}/>
				</React.Fragment>
			)
		} else {
			return null;
		}
	}

	_clearSearchHistory = () => {
		sessionStorage.removeItem('filterType');
		sessionStorage.removeItem('activeFilters');
		localStorage.removeItem('searchPath');
		this.props.history.push('/');
	}

	render() {
		let previousURL = false;

		if ( localStorage.getItem('searchPath')) {
			previousURL = localStorage.getItem('searchPath');
		}
        return (
          	<React.Fragment>
				<div className="configuration__top-buttons">

					{ previousURL ? <NavLink className="configuration__top-buttons__button" to={previousURL}>{__.fs('Configuration.PageHeader.back-to-search-results')}</NavLink> : null }
					<span className="configuration__top-buttons__button" onClick={this._clearSearchHistory}>{__.fs('Configuration.PageHeader.new-search-query')}</span>
				</div>
				{!this.state.graphImage ? <React.Fragment><div id="Graphs" >
    					<Graphs {...this.props} renderToImage={this._renderToImage} hideAdjust={true}/>
    				</div>
				<div id="GraphsImage" ></div></React.Fragment>
                : null}
				<div className="specs-sheet__top">
					<div className="specs-sheet__top__content">
		          		<h2>{this.props.title}</h2>
		          		{ this.props.singleConfiguration ? <p>{__.fs('Configuration.PageHeader.product')}: {buildDescription(this.props.singleConfiguration.pre_filters.description, 'long_description')}</p> : null}
		          	</div>
		          	<div className="specs-sheet__top__button">

						<form target="_blank"  method="POST" action={global.serverURL + "productsheet/download"}>
							<input type="hidden" name="product_string" defaultValue={ this.props.match.params.configuration_id_elastic}/>
							<input type="hidden" name="configuration_id_elastic" defaultValue={this.props.singleConfiguration.id}/>
							{ this._fetchUserProfileFields() }
							{(this.state.graphImage ? <button type="submit"  className="specs-sheet__download-button" >{__.fs('Configuration.PageHeader.download')}</button> : <div className="loader"></div>)}
						</form>
						{!this.props.isLoggedIn ? <button onClick={this._togglePriceRequestPopup} className="specs-sheet__request-button">{__.fs('Configuration.PageHeader.price-delivery-time')}</button> : null}
					</div>
				</div>

				  {this.state.priceRequestPopup.visible ?
                	<PriceRequestPopup closePopup={this._closePriceRequestPopup} data={this.state.priceRequestPopup} />
                : null }

          	</React.Fragment>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
         isLoggedIn: state.Auth.user_token !== null ? true : false,
    }
}

export default connect(mapStateToProps)(PageHeader)
