import { Map } from 'immutable';

export function clearToken() {
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_profile');
}

export function getToken() {
  try {
    const userId = localStorage.getItem('user_id');
    const userProfile = JSON.parse(localStorage.getItem('user_profile'));
    return new Map({ userId, userProfile });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getClientPath() {
  try {
    return localStorage.getItem('clientPath');
  } catch (err) {
    
    return false;
  }
}

export function getApiPath() {
	try {
	  return localStorage.getItem('apiPath');
	} catch (err) {
	  
	  return false;
	}
  }

export function formatMoney(value) {
	const formatter = new Intl.NumberFormat('nl-NL', {
	  style: 'currency',
	  currency: 'EUR',
	  minimumFractionDigits: 2
	})

	return formatter.format(value);
}

export function getUniqueArray(activeFilters){
	const uniqueArray = activeFilters.filter((thing,index) => {
	  return index === activeFilters.findIndex(obj => {
	    return JSON.stringify(obj) === JSON.stringify(thing);
	  });
	});

	return uniqueArray;
}

export function rebuildFilterArray(filters, indexToRemove) {
	let filtersToReturn = [];
	for(let i = 0; i<= filters.length; i++) {

		if(filters[i]) {
			if(i !== indexToRemove) {
				filtersToReturn.push(filters[i]);
			}
		}
	}

	return filtersToReturn;
}

export function rebuildFilterArrayMultipleIndexes(filters, indexes) {
	let filtersToReturn = [];
	for(let i = 0; i<= filters.length; i++) {

		if(filters[i]) {
			if(!indexes.includes(i)) {
				filtersToReturn.push(filters[i]);
			}
		}
	}

	return filtersToReturn;
}

export function orderKeys(obj, expected){
	var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
		if (k1 < k2) return -1;
		else if (k1 > k2) return +1;
		else return 0;
	});

	var i, after = {};
	for (i = 0; i < keys.length; i++) {
		after[keys[i]] = obj[keys[i]];
		delete obj[keys[i]];
	}

	for (i = 0; i < keys.length; i++) {
		obj[keys[i]] = after[keys[i]];
	}
	return obj;
}


export function categorizeFilters(activeFilters){
	let categorizedFilters = {};

	activeFilters.forEach(function(filter) {
		if ( filter.pathToFilter ) {
			categorizedFilters = {
				...categorizedFilters,
				[filter.filterCategory] : {
					...categorizedFilters[filter.filterCategory],
					[filter.filterProperty] : true,
					key : filter.pathToFilter,
					property : filter.filterProperty
				}
			}
		} else {
			categorizedFilters = {
				...categorizedFilters,
				[filter.filterCategory] : {
					...categorizedFilters[filter.filterCategory],
					[filter.filterProperty] : true,
					key : filter.filterCategory,
					property : filter.filterProperty
				}
			}
		}
		
	})

	return categorizedFilters;
}

export function keyValueFilters( activeFilters ) {
	let keyValueFilters = [];

	activeFilters.forEach(function(filter) {
		if ( filter.pathToFilter ) {
			keyValueFilters.push({
				key : filter.filterCategory + '.' + filter.pathToFilter,
				property : filter.filterProperty
			})
		
		} else {
			keyValueFilters.push({
				key : filter.filterCategory,
				property : filter.filterProperty
			})
		}
		
	})

	return keyValueFilters;
}

export function countKeys(obj) {
	let count = 0;
	for( let key in obj ) {
		if( obj.hasOwnProperty(key) ) {
			count++;
		}
	}

	return count;
}


export function findFilterExists(filterCategory, filterProperty, searchable){
	let activeFilters = searchable;

	const index = activeFilters.findIndex(function(filter) {       
   		if(filter.filterCategory === filterCategory && filter.filterProperty === filterProperty) {
           	return true;
        }else{
           	return false;
        }
    });

    return index;
}

export function findExistingFilterIndex( usedFilter, searchable ) {
	
	let activeFilters = searchable;
	const index = activeFilters.findIndex(function(filter) {       
   		if(filter.category === usedFilter.category && filter.property === usedFilter.property && filter.path === usedFilter.path) {
           	return true;
        }else{
           	return false;
        }
    });

    return index;

}

export function findNestedFilterExists(filterCategory, filterProperty, searchable, path) {
		let activeFilters = searchable;

	const index = activeFilters.findIndex(function(filter) {       

   		if(filter.filterCategory === filterCategory && filter.filterProperty === filterProperty && filter.path === path) {
           	return true;
        }else{
           	return false;
        }
    });

    return index;
}

export function findCategoryExists (filterCategory, searchable) {
	let activeFilters = searchable;

	const index = activeFilters.findIndex(function(filter) {       
   		if(filter.filterCategory === filterCategory) {
           	return true;
        }else{
           	return false;
        }
    });

    return index;
}

export function findParentExistsButIsNotProperty(filterCategory, filterProperty, activeFilters) {

	for( let i = 0; i <= activeFilters.length; i++ ){
		if( activeFilters[i] ) {
			const filter = activeFilters[i];

			if( filter.filterCategory === filterCategory && filter.filterProperty !== filterProperty ) {
				return true;
			}
		}
	}

	return false;
}

export function getPageSize(length) {
	if(length <= 10) {
		return length;
	}
	return 10;
}


export function groupConfigurations(configurations){
	let groupedConfigurations = {};
	let returnConfigurations = [];
	for( let i = 0; i <= configurations.length; i++ ) {
		if( configurations[i] ) {
			const configuration = configurations[i];


			if( !groupedConfigurations[configuration.ch] ) {
				groupedConfigurations[configuration.ch] = {
					ch: configuration.ch, 
					configurations: [],
					...configuration
				}


			}else{
				let configurationToPush = configuration;

				if(parseFloat(configuration.highest_efficiency) > parseFloat(groupedConfigurations[configuration.ch]['highest_efficiency'])) {
					configurationToPush = {
						ch : groupedConfigurations[configuration.ch]['ch'],
						curve_values : groupedConfigurations[configuration.ch]['curve_values'],
						filters: groupedConfigurations[configuration.ch]['filters'],
						highest_efficiency : groupedConfigurations[configuration.ch]['highest_efficiency'],
						is_preconfig: groupedConfigurations[configuration.ch]['is_preconfig'],
						main_category_id : groupedConfigurations[configuration.ch]['main_category_id'],
						pre_filters : groupedConfigurations[configuration.ch]['pre_filters'],
						products: groupedConfigurations[configuration.ch]['products'],
					}

					groupedConfigurations[configuration.ch] = {
						ch: configuration.ch, 
						...configuration,
						configurations: [...groupedConfigurations[configuration.ch]['configurations']]
					}

				}

				groupedConfigurations[configuration.ch]['configurations'].push(configurationToPush);
			}
		}
	}



	for( let ch in groupedConfigurations ) {
		if(groupedConfigurations.hasOwnProperty(ch)) {



			if(groupedConfigurations[ch]['configurations'].length > 1) {
				returnConfigurations.push(groupedConfigurations[ch]);
			}else{
				if ( groupedConfigurations[ch]['configurations'][0] ) {
					returnConfigurations.push(groupedConfigurations[ch]['configurations'][0]);
				} else {
					groupedConfigurations[ch]['configurations'] = false;
					returnConfigurations.push(groupedConfigurations[ch]);
				}
				
			}
			
		}
	}

	// -- Clean all non existent items
	const cleanedArray = returnConfigurations.filter(function (el) {
	  return el != null;
	});

	return cleanedArray;
}

export function lowerAndCleanString(str) {
	return str.replace('/', '').toLowerCase();	
}

export function getReference(referenceObject) {
	return new Promise((resolve, reject) => {
		referenceObject.get()
		.then(function(response) {
			return response.data();
		}).then(function(response) {
			resolve(response);
		});	
	}) 
}

export function feetToMeters(feet) {
	return (parseFloat(feet) * 0.3048);
}

export function metersToFeet(meters) {
	return (parseFloat(meters) / 0.3048);
}

export function maybeConvertToFeet(meters, unit) {
	if ( unit === 'feet' ) {
		return metersToFeet(meters);
	} else {
		return meters;
	}
}