import React, { Component } from 'react';
import { generateProductSheetsWithKey } from '../../helpers/productSheetHelpers';

class MotorSpecs extends Component {
    componentDidMount () {
        this.props.setTitle("Motor specificaties")
    }

    render() {
        return (
            <div className="specs-sheet">
                <div className="specs-sheet__content">
                    <div className="specs-sheet__columns">
                        <div className="specs-sheet__columns__column">
                            {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Electric data') : null}
                            {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, '50Hz') : null}
                            {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, '60Hz') : null}
                            {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Motor protection') : null}
                       </div>
                       <div className="specs-sheet__columns__column">
                          {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Mechanical data') : null}
                          {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Dimensions') : null}
                          {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Bearings/lubrication') : null}
                          {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Details') : null}
                       </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MotorSpecs
