import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FirebaseHelper from '../../helpers/firebase';
import actions from './actions';

const { rsfFirestore } = FirebaseHelper;
export function* fetchFilters() {

	yield takeEvery(actions.FETCH_FILTERS, function*(action) {
		
		const snapshot = yield call(rsfFirestore.getCollection, 'filters');

		 yield put({
		 	type: actions.SET_FILTERS,
		 	payload: FirebaseHelper.processFireStoreCollection(snapshot)
		 })

	})
}


// export function* syncConfiguredProducts() {
	
// 	yield fork(
// 	  	rsfFirestore.syncCollection,
// 	  	firebase.firestore().collection('configured_products'),
// 		{ successActionCreator: (snap) => actions.setConfiguredProducts(FirebaseHelper.processFireStoreCollection(snap))}
// 	  )	
// }

export default function* rootSaga() {
	yield all([
		fork(fetchFilters),
		//fork(syncConfiguredProducts)
	]);
}
 