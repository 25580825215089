import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label } from 'recharts';
import PropTypes from 'prop-types';
import { round } from '../../../helpers/graphCalculationHelpers';
import { convert } from '../../../helpers/conversion';
const Curve = (props) => {

    return(

        <React.Fragment>
    		<h2 className="graph-title">{props.title}</h2>
  			{ (props.series.length) ?
                <div className={(props.compare ? 'compare-graph' : null)}>
    				<ResponsiveContainer height={(props.compare || props.popup ? 300 : 500)} width={(props.compare ? '75%' : '100%')}>
    					<LineChart  >
    						<CartesianGrid strokeDasharray="1 1"/>
    						<XAxis tickFormatter={(props.convertX ? (value) => round(convert(value, props.convertX.type, props.convertX.unit), 2) : false)} dataKey={props.xDataKey} type="number" allowDuplicatedCategory={false} domain={[0, props.highestQ]}>
    							<Label value={props.xLabel} position="bottom" offset={10} />

                                {props.actualDutyPoint && <Label value={(props.convertX ? round(convert(props.actualDutyPoint.q, props.convertX.type, props.convertX.unit), 2) : round(parseFloat(props.actualDutyPoint.q), 2))} position="insideBottom" offset={80} /> }

                            </XAxis>

    						<YAxis label={{ value: props.yLabel, angle: -90, position: 'insideLeft', offset: (props.popup ? 0 : -25)}} tickFormatter={(props.convertY ? (value) => round(convert(value, props.convertY.type, props.convertY.unit), 2) : false)} yAxisId="left" dataKey={props.yDataKey} orientation='left' type="number" allowDataOverflow={(props.highestY ? true : false)} domain={(props.highestY ? [0, props.highestY] : [0, 'auto'])}>

                                 {props.actualDutyPoint &&  <Label value={(props.convertY ? round(convert(props.actualDutyPoint.h, props.convertY.type, props.convertY.unit), 2) : round(parseFloat(props.actualDutyPoint.h), 2))} position="insideLeft" offset={80} /> }

                            </YAxis>

    						{props.series.map(s => (
    							<Line  yAxisId="left" type={s.type} isAnimationActive={true} dataKey={props.yDataKey} stroke={s.stroke} strokeWidth={s.strokeWidth} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name +'left'} />
    						))}
                            {props.renderSearchedByQHCurves ? props.renderSearchedByQHCurves() : null }
    					</LineChart>
    				</ResponsiveContainer>

                </div>



		    : null }
        </React.Fragment>
    )
}

export default Curve

Curve.propTypes = {
    title : PropTypes.string.isRequired,
    xDataKey : PropTypes.string.isRequired,
    yDataKey : PropTypes.string.isRequired,
    xLabel : PropTypes.string.isRequired,
    yLabel : PropTypes.string.isRequired,
    series : PropTypes.array.isRequired,
    renderSearchedByQHCurves : PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]).isRequired,
}
