export function calculateVariableValues(item, inputFrequence) {
	const hzCalculated = (inputFrequence / 50);
	// -- Rated Q * (User input frequency / Configuration frequency)
	const q = parseFloat(item.q) * hzCalculated;

	// -- Rated H * ((User input frequency / Configuration frequency) ^ 2)
	const h = parseFloat(item.h) * (Math.pow(hzCalculated, 2));

	// -- Rated P * ((User input frequency / Configuration frequency) ^ 3)
	const p = parseFloat(item.p) * (Math.pow(hzCalculated, 3));

	const efficiency = ( ( ( h ) * ( 1000 * 9.81 ) ) * ( ( q / 3600 ) ) / ( p * 1000) ) * 100;

	// -- Rated NPSH Q * (User input frequency / Configuration frequency)
	const npsh_q = parseFloat(item.npsh_q) * (hzCalculated);

	// -- Rated NPSH * ((User input frequency / Configuration frequency) ^ 2)
	const npsh_val = parseFloat(item.npsh_h) * Math.pow(hzCalculated, 2);


	let returnItem = {
		q : parseFloat(q),
		h : parseFloat(h),
		p : parseFloat(p),
		eff : parseFloat(efficiency)
	}

	if( item.npsh_q ) {
		returnItem.npsh_q = npsh_q;
	}

	if( item.npsh_h ) {
		returnItem.npsh_h = npsh_val
	}

	return returnItem;
}

function compare( a, b ) {
  if ( a.q < b.q ){
    return -1;
  }
  if ( a.q > b.q ){
    return 1;
  }

  if ( a.npsh_q < b.npsh_q ){
	return -1;
  }
  if ( a.npsh_q > b.npsh_q ){
	return 1;
  }
  return 0;
}



export function getPolynomialValuesToArrayMappable(collection, key, xName, yName) {
	let polynomialValues = [];

	for ( const Q in collection ) {
		if( collection.hasOwnProperty( Q ) ) {
			const value = collection[Q];
			polynomialValues.push({
				[xName] : (parseInt(Q.replace(key, '')) / 10),
				[yName] : value
			})
		}
	}

	return polynomialValues.sort(compare);
}

export function round (value, precision){
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

export function getVariablePolynomialValues(polynomialValues, inputFrequence) {

	let variablePolynomialValues = [];

	for ( let i = 0; i <= polynomialValues.length; i++ ) {
		if ( polynomialValues[i] ) {
			variablePolynomialValues.push(calculateVariableValues(polynomialValues[i], inputFrequence));
		}
	}

	return variablePolynomialValues;
}

export function parsePolynomialValues(polynomialValues) {

	let qhSeries = {};
	let effSeries = {};
	let powerSeries = {};
	let npshSeries = {};

	let oProperties = [];
	for ( const property in polynomialValues ) {
		if ( polynomialValues.hasOwnProperty(property) ) {
			switch ( property ) {
				case 'eff' :
					effSeries = getPolynomialValuesToArrayMappable(polynomialValues[property], 'eff', 'q', 'eff');
				break;
				case 'head' :
					qhSeries = getPolynomialValuesToArrayMappable(polynomialValues[property], 'h', 'q', 'h');
				break;
				case 'power' :
					powerSeries = getPolynomialValuesToArrayMappable(polynomialValues[property], 'p', 'q', 'p');
				break;
				case 'npsh-min' :
					npshSeries = getPolynomialValuesToArrayMappable(polynomialValues[property], 'n-min', 'npsh_q', 'npsh_h');
				break;
				default:
				break;
			}
		}
	}

	for ( const index in qhSeries ) {
		if ( qhSeries.hasOwnProperty(index) ) {

			let item = {
				q : qhSeries[index].q,
				h : qhSeries[index].h
			}

			// -- Find item with same Q
			const effResult = effSeries.find(item => item.q === qhSeries[index].q);
			if ( effResult ) {
				item = {
					...item,
					eff: effResult.eff
				}
			}

			const powerResult = powerSeries.find(item => item.q === qhSeries[index].q);
			if ( powerResult ) {
				item = {
					...item,
					p: powerResult.p
				}
			}

			if ( npshSeries.length ) {
				const npshResult = npshSeries.find(item => item.npsh_q === qhSeries[index].q);
				if ( npshResult ) {
					item = {
						...item,
						npsh_h: npshResult.npsh_h
					}
				}
			}


			oProperties.push(item);
		}
	}

	return oProperties;

}

export function findClosestQ(actualDutyPoint, xIdentifier, values) {
	if( actualDutyPoint.q ) {

		let smallestDifference = false;
		let currentIndex = false;
		for ( let i = 0; i <= values.length; i++ ){
			if ( values[i] ) {
				let currentQ = round(parseFloat(values[i][xIdentifier]), 2);

				let actualQ = round(actualDutyPoint.q, 1 );

				let difference = false;

				if ( currentQ > actualQ ) {
					difference = currentQ - actualQ;
				} else {
					difference = actualQ - currentQ;
				}

				if ( smallestDifference !== 0 ) {
					if ( !smallestDifference ) {
						smallestDifference = difference;
						currentIndex = i;
					}

					if ( difference < smallestDifference ) {
						smallestDifference = difference;
						currentIndex = i;
					}
				}


				if ( difference === 0 && smallestDifference !== 0) {
					smallestDifference = 0;
					currentIndex = i;
				}

			}
		}

		return currentIndex;
	}

	return false;
}
