import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { getToken } from '../../helpers/utility';

const { rsfFirestore } = FirebaseHelper;

export function* setUserProfile() {
	yield takeEvery(actions.SET_USER_PROFILE, function*(action) {
		yield localStorage.setItem('user_profile', JSON.stringify(action.payload));
	})
}

export function* setUserProperties() {
	yield takeEvery(actions.SET_USER_PROPERTIES, function*(action) {
		yield put({
		 	type: actions.SET_SETTINGS,
		 	payload: action.payload.default_settings
		})

		if ( action.payload.clientID) {
			yield put({
			 	type: actions.SET_CLIENT_ID,
			 	payload: action.payload.clientID
			})
		}

		if ( action.payload.user_profile_data ) {
			yield put({
				type: actions.SET_USER_PROFILE_DATA,
				payload: action.payload.user_profile_data
			})
		}
	})
}

export function* fetchUserProperties() {
	yield takeEvery(actions.FETCH_USER_PROPERTIES, function*(action) {
		const snapshot = yield call(rsfFirestore.getDocument, 'users/'+action.payload);
		const userData = snapshot.data();

		yield put ({
			type : actions.SET_USER_PROFILE_DATA,
			payload : {
				userData
			}
		})

	})
}

export function* updateUserSettingsFirebase() {
	yield takeEvery(actions.UPDATE_USER_SETTINGS_FIREBASE, function*(action) {

		let userData = {};

		if ( action.payload.default_settings ) {
			userData = {
				...userData,
				default_settings : action.payload.default_settings
			}
		}

		if ( action.payload.filter_groups ) {
			userData = {
				...userData,
				filter_groups : action.payload.filter_groups
			}
		}

		yield call(rsfFirestore.updateDocument, 'users/'+action.payload.user_id , userData);
	})
}

export function* updateUserProfileData() {
	yield takeEvery(actions.UPDATE_USER_PROFILE_DATA, function*(action) {
		const localUserData = getToken();
		let user_profile_data = {...action.payload};
		const user_id = localUserData.get('userId');
		yield call(rsfFirestore.updateDocument, 'users/' + user_id, 'user_profile_data', user_profile_data);

	})
}


export function* setFilterGroups() {
	yield takeEvery(actions.SET_FILTER_GROUPS, function*(action) {
		const state = yield select();
		const filterGroups = state.User.filter_groups;
		const localUserData = getToken();

		const user_id = localUserData.get('userId');
		yield put ({
			type : actions.UPDATE_USER_SETTINGS_FIREBASE,
			payload : {
				filter_groups : filterGroups,
				user_id : user_id
			}
		})
	})
}

export function* addFilterGroup() {
	yield takeEvery(actions.ADD_FILTER_GROUP, function*(action) {
		const state = yield select();
		const filterGroups = state.User.filter_groups;
		const localUserData = getToken();

		const user_id = localUserData.get('userId');
		yield put ({
			type : actions.UPDATE_USER_SETTINGS_FIREBASE,
			payload : {
				filter_groups : filterGroups,
				user_id : user_id
			}
		})
	})
}

export function* createUserFirebase() {
	yield takeEvery(actions.CREATE_USER_FIREBASE, function*(action) {

		const userData = {
			default_settings : action.payload.default_settings,
			filter_groups : action.payload.filter_groups

		}

		yield call(rsfFirestore.setDocument, 'users/'+action.payload.user_id, userData)
	})
}

export function* updateSetting() {
	yield takeEvery(actions.UPDATE_SETTINGS, function*(action) {

		let data = {};

		if(action.payload.frequency) {
			data = {
				...data,
				frequency : {
					label : action.payload.frequency,
					value : action.payload.frequency
				}
			}
		}

		if ( action.payload.motor_poles ) {
			data = {
				...data,
				motor_poles : {
					label : action.payload.motor_poles.replace('P', ' Polen'),
					value : action.payload.motor_poles,
				}
			}
		}

		if ( action.payload.field ) {
			data = {
				...data,
				[action.payload.field] : action.payload.value
			}
		}

		if ( action.payload.language ) {
			data = {
				...data,
				language : action.payload.language
			}
		}

		data = {
			...data,
			units : action.payload.units
		}

		yield put({
		 	type: actions.SET_SETTINGS,
		 	payload: data
		})

		yield put({
			type: actions.UPDATE_USER_SETTINGS_FIREBASE_IF_EXISTS
		})
	})
}

export function* updateSettingsFirebaseIfExists() {
	yield takeEvery(actions.UPDATE_USER_SETTINGS_FIREBASE_IF_EXISTS, function*(action) {

		const state = yield select();
		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		if ( user_id ) {
			yield put({
				type: actions.UPDATE_USER_SETTINGS_FIREBASE,
				payload : {
					user_id : user_id,
					default_settings : state.User.default_settings
				}
			})
		}

	})
}

export function* syncUserCollection() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');

	if ( user_id ) {
		// -- Sync user document and set user properties with snapped data on change.
		yield fork(
			rsfFirestore.syncDocument,
			'users/'+user_id,
			{ successActionCreator: (snap) => actions.setUserProperties(snap.data())}
		);
	}
}



export default function* rootSaga() {
	yield all([
		fork(setUserProfile),
		fork(fetchUserProperties),
		fork(setUserProperties),
		fork(syncUserCollection),
		fork(updateSetting),
		fork(updateUserSettingsFirebase),
		fork(createUserFirebase),
		fork(updateSettingsFirebaseIfExists),
		fork(addFilterGroup),
		fork(setFilterGroups),
		fork(updateUserProfileData)
	]);
}
