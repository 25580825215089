const actions = {
	ADD_LIST_FIREBASE : 'ADD_LIST_FIREBASE',
	ADD_PRODUCT_TO_LIST_FIREBASE: 'ADD_PRODUCT_TO_LIST_FIREBASE',
	GET_PRODUCT_LISTS: 'GET_PRODUCT_LISTS',
	SET_PRODUCT_LISTS: 'SET_PRODUCT_LISTS',
	CHANGE_LIST_ORDER_FIREBASE: 'CHANGE_LIST_ORDER_FIREBASE',
	CHANGE_PRODUCT_ORDER_FIREBASE: 'CHANGE_PRODUCT_ORDER_FIREBASE',
	DELETE_LIST_FIREBASE: 'DELETE_LIST_FIREBASE',
	SYNC_PRODUCT_LISTS : 'SYNC_PRODUCT_LISTS',
	ADD_CATEGORY_TO_LIST: 'ADD_CATEGORY_TO_LIST',
	UPDATE_PRODUCT_CATEGORIES: 'UPDATE_PRODUCT_CATEGORIES',
	UPDATE_CONFIGURATIONS_IN_LIST : 'UPDATE_CONFIGURATIONS_IN_LIST',

	addListFirebase : (payload) => ({
		type : actions.ADD_LIST_FIREBASE,
		payload : payload
	}),
	addProductToListFirebase : (payload) => ({
		type : actions.ADD_PRODUCT_TO_LIST_FIREBASE,
		payload : payload
	}),
	addCategoryToList : (payload) => ({
		type : actions.ADD_CATEGORY_TO_LIST,
		payload : payload
	}),
	updateProductCategories : (payload) => ({
		type : actions.UPDATE_PRODUCT_CATEGORIES,
		payload : payload
	}),
	getProductLists: (payload) => ({
		type: actions.GET_PRODUCT_LISTS,
		payload : payload
	}),
	changeListOrderFirebase: (payload) => ({
		type: actions.CHANGE_LIST_ORDER_FIREBASE,
		payload : payload
	}),
	changeProductOrderFirebase: (payload) => ({
		type: actions.CHANGE_PRODUCT_ORDER_FIREBASE,
		payload : payload
	}),
	deleteList: (payload) => ({
		type: actions.DELETE_LIST_FIREBASE,
		payload : payload
	}),

	updateConfigurationsInList : (payload) => ({
		type: actions.UPDATE_CONFIGURATIONS_IN_LIST,
		payload: payload
	}),

};

export default actions;
