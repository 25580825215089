import React, { Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import RouteWrap from './components/Common/RouteWrap';
import Category from './components/Category';
import Configuration from './components/Configuration';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import 'react-toastify/dist/ReactToastify.css';
import SpareParts from './components/SpareParts';
import Authenticate from './components/Authenticate';
import ForgotPassword from './components/ForgotPassword';
import Notifier from './components/Notifier';
import Profile from './components/Profile';
import Compare from './components/Compare';
import ConfiguredProducts from './components/ConfiguredProducts';
import MakeFilterTree from './components/MakeFilterTree';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './redux/configurations/actions';

class Routes extends Component {
	render() {


		const location = this.props.location;
        return (

        	<div className={"application__wrapper " + (this.props.working_overlay ? 'application__wrapper--working' : '')}>
        		<Header />
	        		<div className="application__body">
	        			<Notifier />
			            <Switch location={location}>
							<Route
								exact
								path="/"
								render={ (props) =>

									<Redirect to="/category/1" />
				                }
							/>

							<Route
								exact
								path="/make-filter-tree"
								render={ (props) =>
				                    <RouteWrap component={MakeFilterTree}/>
				                }
							/>


							<Route
								exact
								path="/category/:category_id"
								render={ (props) =>
				                    <RouteWrap component={Category}/>
				                }
							/>

							<Route
								exact
								path="/CH/:CH/:category/:filterType/:size/:numberOfStages/:isLowNpsh"
								render={ (props) =>
				                    <RouteWrap component={ConfiguredProducts}/>
				                }
							/>

							<Route
								exact
								path="/CH/:CH/:category/:filterType/:Q/:H/:plus/:minus/:isLowNpsh"
								render={ (props) =>
				                    <RouteWrap component={ConfiguredProducts}/>
				                }
							/>

		                    <Route
								path="/producten"
								render={ (props) =>
				                    <RouteWrap component={SpareParts}/>
				                }
							/>

							<Route

								path="/configuration/:type/:configuration_id_elastic"
								render={ (props) =>
				                    <RouteWrap component={Configuration}/>
				                }
							/>

							<Route
								path="/profile"
								render={ (props) =>
				                    <RouteWrap restricted component={Profile}/>
				                }
							/>

							<Route
								exact
								path="/login"
								render={ (props) =>
				                    <RouteWrap component={Authenticate}/>
				                }
							/>

							<Route
								exact
								path="/forgot-password"
								render={ (props) =>
				                    <RouteWrap component={ForgotPassword}/>
				                }
							/>

							<Route
								exact
								path="/logout"
								render={ (props) =>
				                    <RouteWrap component={Authenticate}/>
				                }
							/>

							<Route
								exact
								path="/compare"
								render={ (props) =>
				                    <RouteWrap restricted component={Compare}/>
				                }
							/>



			            </Switch>

			        </div>
	            <Footer />
            </div>
        )

	}
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
const mapStateToProps = (state, ownProps) => {
    return {
    	working_overlay : state.Layout.working_overlay,
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
