import actions from './actions';

const initState = { productLists: [] };



export default function productListsReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_PRODUCT_LISTS:
			return {...state, productLists: action.payload}
		default:
			return state;
	}
}
