import actions from './actions';

const initState = { filters: null };

export default function filtersReducer(state = initState, action) {
	switch (action.type) {		
		case actions.SET_FILTERS:
		return { 
				...state, 
				filters: action.payload
			};	
		default:
			return state;
	}
}
 