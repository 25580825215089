const actions = {
	ADD_PRICE_REQUEST: 'ADD_PRICE_REQUEST',
	SET_PRICE_REQUESTS: 'SET_PRICE_REQUESTS',
	ADD_PRICE_REQUEST_SINGLE : 'ADD_PRICE_REQUEST_SINGLE',
	
	addPriceRequest: (payload) => ({ 
		type: actions.ADD_PRICE_REQUEST,
		payload : payload
	}),
	setPriceRequests: (payload) => ({ 
		type: actions.SET_PRICE_REQUESTS,
		payload : payload
	}),

	addPriceRequestSingleProduct : (payload) => ({
		type: actions.ADD_PRICE_REQUEST_SINGLE,
		payload: payload
	})
};

export default actions;
