import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import __ from '../../helpers/language';
import PageHeader from '../Common/PageHeader';
import Profile from './profile';
import Lists from '../Lists';
import Settings from '../Settings';
import EditProfile from './profile';

const ProfileRouter = props => (
    <div className="profile">
    	<PageHeader title={"Mijn account"} alignCenter={true}/>

    	<div className="tabs">
      		<div className="container">
	          	<ul className="tabs__list">
	          		{/*<NavLink exact to="/profile" className="tabs__list__item" activeClassName="tabs__list__item--active">
	          			<li>{__.fs('Profile.index.my-profile')}</li>
	          		</NavLink>*/}

	          		<NavLink to="/profile/lists" className="tabs__list__item" activeClassName="tabs__list__item--active">
	          			<li>{__.fs('Profile.index.lists')}</li>
	          		</NavLink>

	          		<NavLink to="/profile/settings" className="tabs__list__item" activeClassName="tabs__list__item--active">
	          			<li>{__.fs('Profile.index.settings')}</li>
	          		</NavLink>

                    <NavLink to="/profile/edit" className="tabs__list__item" activeClassName="tabs__list__item--active">
	          			<li>{__.fs('Profile.index.my-profile')}</li>
	          		</NavLink>

	          	</ul>
	        </div>
        </div>

        <div className="tab tab--visible">
            <Switch>
				<Route
					exact
					path="/profile"
					render={ (props) =>
	                    <Profile />
	                }
				/>
				<Route
					path="/profile/lists"
					render={ (props) =>
	                    <Lists />
	                }
				/>
				<Route
					path="/profile/settings"
					render={ (props) =>
	                    <Settings />
	                }
				/>

                <Route
					path="/profile/edit"
					render={ (props) =>
	                    <EditProfile />
	                }
				/>

			</Switch>
		</div>
	</div>
);


export default ProfileRouter;
