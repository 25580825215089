import React from 'react';

class CustomizedCircle extends React.Component {
 render() {
     const { cx, cy } = this.props;

     return (
         <circle cx={cx} cy={cy} r={6} stroke="red" strokeWidth={2} fill="yellow" />
     );
 }
};

export default CustomizedCircle;
