const actions = {
	SET_USER_PROFILE : 'SET_USER_PROFILE',
	FETCH_USER_PROPERTIES : 'FETCH_USER_PROPERTIES',
	SET_USER_PROPERTIES: 'SET_USER_PROPERTIES',
	SET_DUTY_POINT : 'SET_DUTY_POINT',
	ADD_FILTER_GROUP : 'ADD_FILTER_GROUP',
	SET_FILTER_GROUPS : 'SET_FILTER_GROUPS',
	SET_TCO_VALUE : 'SET_TCO_VALUE',
	SET_USER_LOGGED_IN : 'SET_USER_LOGGED_IN',
	UPDATE_SETTINGS: 'UPDATE_SETTINGS',
	SET_SETTINGS : 'SET_SETTINGS',
	SET_LOW_NPSH : 'SET_LOW_NPSH',
	UPDATE_USER_SETTINGS_FIREBASE : 'UPDATE_USER_SETTINGS_FIREBASE',
	CREATE_USER_FIREBASE : 'CREATE_USER_FIREBASE',
	UPDATE_USER_SETTINGS_FIREBASE_IF_EXISTS : 'UPDATE_USER_SETTINGS_FIREBASE_IF_EXISTS',
	SET_CLIENT_ID : 'SET_CLIENT_ID',
	UPDATE_USER_PROFILE_DATA : 'UPDATE_USER_PROFILE_DATA',
	SET_USER_PROFILE_DATA : 'SET_USER_PROFILE_DATA',

	updateUserProfileData : ( payload ) => ({
		type: actions.UPDATE_USER_PROFILE_DATA,
		payload : payload
	}),

	updateSettings : (payload) => ({
		type: actions.UPDATE_SETTINGS,
		payload: payload
	}),

	setLowNpsh : (payload) => ({
		type: actions.SET_LOW_NPSH,
		payload: payload
	}),


	setTcoValue : (payload) => ({
		type: actions.SET_TCO_VALUE,
		payload: payload
	}),

	setFilterGroups : (payload) => ({
		type : actions.SET_FILTER_GROUPS,
		payload: payload
	}),

	addFilterGroup : (payload) => ({
		type : actions.ADD_FILTER_GROUP,
		payload : payload,
	}),

	setUserProperties: (payload) => ({
		type: actions.SET_USER_PROPERTIES,
		payload: payload
	}),

	setUserProfile: (payload) => ({
		type: actions.SET_USER_PROFILE,
		payload: payload,
	}),
};

export default actions;
