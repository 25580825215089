const actions = {
	REQUEST_ORDER: 'REQUEST_ORDER',	

	requestOrder: (payload) => ({ 
		type: actions.REQUEST_ORDER,
		payload : payload
	}),
	
};

export default actions;
