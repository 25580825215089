import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Filters from '../Filters';
import ResultTable from './ResultTable';

import PageHeader from '../Common/PageHeader';

import { lowerAndCleanString } from '../../helpers/utility';

import actions from '../../redux/configurations/actions';
import {bindActionCreators} from 'redux';
import __ from '../../helpers/language';
class Category extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	data: false,
        	filterCollapse : '',
        	filterCollapseButton : '',
        	activeFilterCategory : false,
        	configurations : false,
        	unfilteredConfigurations : []
        }
    }

    _groupAndSetConfigurations = () => {
    	const initialConfigurations = [...this.props.configurations];


		if ( initialConfigurations.length === 0 ) {
			this.setState({
				configurations : false,
			})
		} else {
			this.setState({
				configurations : initialConfigurations,
				unfilteredConfigurations : initialConfigurations,
			})
		}
    }

    componentDidUpdate(prevProps) {
		if(prevProps.configurations.length !== this.props.configurations.length) {
			this._groupAndSetConfigurations();
		}
    }

    componentDidMount() {

        localStorage.setItem('searchPath', this.props.location.pathname);
        const low_npsh = this.props.match.params.isLowNpsh === '0' ? false : true;
        if ( this.props.match.params.filterType === 'filter' ) {
            const numberOfStages = this.props.match.params.numberOfStages;
            const size = this.props.match.params.size;
            const CH = this.props.match.params.CH;
            const category = this.props.match.params.category;
            const postData = this._buildFilterQuery(numberOfStages, size, CH, category);
            this.props.actions.fetchConfigurationsWithQuery({
                query: postData,
                low_npsh : low_npsh
            });
        }

        if ( this.props.match.params.filterType === 'qh' ) {

            const qhQueryData = {
                categoryId: this.props.match.params.category,
                frequency: lowerAndCleanString(this.props.defaultSettings.frequency.value),
                motorPoles: lowerAndCleanString(this.props.defaultSettings.motor_poles.value),
                qhFilterValues: {
                    Q: this.props.match.params.Q,
                    H: this.props.match.params.H,
                    plus: this.props.match.params.plus,
                    minus: this.props.match.params.minus,
                    CH : lowerAndCleanString(this.props.match.params.CH)
                }
            }
            this.props.actions.fetchConfigurationsWithQH({
                qhQueryData : qhQueryData,
                low_npsh : low_npsh
            });
        }
    }

    _buildFilterQuery = (numberOfStages, size, CH, category) => {

		let formData = new FormData();
		formData.append("Number of stages", numberOfStages);
		formData.append("size", size);
		formData.append("ch", lowerAndCleanString(CH));
		formData.append("category_id", category);
		formData.append("frequency", lowerAndCleanString(this.props.defaultSettings.frequency.value));
		formData.append("poles", lowerAndCleanString(this.props.defaultSettings.motor_poles.value));


        return formData;
    }

    _setActiveFilterCategory = (filterCategory) => {
    	this.setState({
    		activeFilterCategory : filterCategory,
    		filterCollapse : 'collapse--open',
    		filterCollapseButton : 'collapse__button--visible'
    	})
    }

    _collapseIn = () => {
    	this.setState({
    		...this.state,
    		filterCollapse : '',
    		filterCollapseButton : ''
    	})
    }



    _findConfigurationHasFilter = (properties, filters) => {
    	let numberOfTruths = 0;
    	let numberOfCategories = 0;
		
    	for(let category in filters) {
    		if(category !== 'text') {
	    		if(filters.hasOwnProperty(category)) {
	    			numberOfCategories++;
	    			let filter = filters[category];
	    			let trueForCategory = false;

	    			for(let property in filter) {
						let filterProperty = category + '.' + property;
						
						// -- fix for WRAS/ACS
						if(filterProperty == "Drinking water approval.WRAS/ACS")
						{
						
							filterProperty = 'Drinking water approval.WRAS\\/ACS';
						}

	    				if ( properties.includes(filterProperty) ) {
	    					trueForCategory = true;
	    				}
	    			}

	    			if(trueForCategory) {
	    				numberOfTruths++;
	    			}
	    		}
	    	}
    	}

    	return numberOfCategories === numberOfTruths;
    }

    _categorizeKeyValueFilters = (activeFilters) => {
		let categorizedFilters = {};

		[...activeFilters].forEach(function(filter) {
			categorizedFilters = {
				...categorizedFilters,
				[filter.path] : {
					...categorizedFilters[filter.path],
					[filter.property] : true
				}
			}
		})
		
		return categorizedFilters;
	}

    _filterConfigurations = (activeFilters, pushToState = true) => {

    	let resultConfigurations = [];
    	const allConfigurations = [...this.state.unfilteredConfigurations];


    	let categorizedFilters = this._categorizeKeyValueFilters(activeFilters);
		
		for ( let i = 0; i <= allConfigurations.length; i++ ) {
			if ( allConfigurations[i] ) {
				const configuration = allConfigurations[i];

				const result = this._findConfigurationHasFilter(configuration[global.filterOptionItem], categorizedFilters);

				if ( result ) {
					resultConfigurations.push(configuration);
				}
			}
		}

    	if ( pushToState ) {
    		this.setState({
	    		configurations : resultConfigurations,
	    	})
    	} else {
    		return resultConfigurations;
    	}
    }

    render() {

        return (
          	<div>
                <PageHeader title={this.props.match.params.CH} alignCenter={true}/>
          		{this.state.configurations !== false ?
	          		<div>
			            <Filters
			            	filterCollapseButton={this.state.filterCollapseButton}
			            	resultCount={this.state.configurations ? this.state.configurations.length : 0}
			            	activeFilterCategory={this.state.activeFilterCategory}
			            	filterCollapse={this.state.filterCollapse}
			            	collapseIn={this._collapseIn}
			            	configurations={this.state.configurations}

			            	filterConfigurations={this._filterConfigurations}
			            	allConfigurations={this.state.unfilteredConfigurations}
			            />
                        <div className="whitespace"></div>
                        <div className="container container--description">
                            <h3>{__.fs('ConfiguredProducts.index.step-2-description')}</h3>
                        </div>
                        <div className="contained-table">
			                  <ResultTable groupedConfigurations={this.state.configurations} preconfig={this.props.preconfig}/>
                        </div>
			        </div>
	            : null }
       		</div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
    	configurations : state.Configurations.configurations.configurations,
        preconfig : (state.Configurations.configurations.preconfig.length ? state.Configurations.configurations.preconfig[0]  : false ),
        defaultSettings: state.User.default_settings,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Category))
