const actions = {
	ADD_LISTCATEGORY_FIREBASE: 'ADD_LISTCATEGORY_FIREBASE',
	SET_LIST_CATEGORIES: 'SET_LIST_CATEGORIES',

	addListCategoryFirebase: (payload) => ({ 
		type: actions.ADD_LISTCATEGORY_FIREBASE,
		payload : payload
	}),
	setListCategories: (payload) => ({ 
		type: actions.SET_LIST_CATEGORIES,
		payload : payload
	})
};

export default actions;
