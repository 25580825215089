import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import userActions from '../../redux/user/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';
class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			company : '',
			street_number : '',
			zipcode_city : '',
			country : ''
		}
	}
	handleInputChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	componentDidMount () {
		this.setState({
			...this.props.user_profile_data
		})
	}

	componentDidUpdate(prevProps) {
		if ( this.props.user_profile_data !== prevProps.user_profile_data) {
			this.setState({
				...this.props.user_profile_data
			})
		}
	}

	_saveUserProfileData = (e) => {
		e.preventDefault();
		let error = false;
		for ( const property in this.state ) {
			if ( this.state.hasOwnProperty(property) ) {
				if ( !this.state[property] ) {
					error = true;
				}
			}
		}

		if ( error ) {
			this.props.actions.setMessage({
				message: __.fs('User.Profile.profile.all-fields-required'),
				type: 'error'
			})
		} else {
			this.props.actions.updateUserProfileData(this.state);
		}
	}

	render() {
      	return(
      		<div className="profile">
                <div className="container">
                    <div className="input" style={{marginBottom: '30px'}}>
                        <label className="input__label">{__.fs('Lists.profile.company-name')}</label>
                        <br />
                        <input type="text" onChange={this.handleInputChange} id="company" name="company" defaultValue={this.state.company} className="input__field input__field--nopad input__field--wider" placeholder={__.fs('Lists.profile.company-name')} />

                    </div>

                    <div className="input" style={{marginBottom: '30px'}}>
                        <label className="input__label">{__.fs('Lists.profile.company-street-number')}</label>
                        <br />
                        <input type="text" onChange={this.handleInputChange} id="street_number" name="street_number" defaultValue={this.state.street_number} className="input__field input__field--nopad input__field--wider" placeholder={__.fs('Lists.profile.company-street-number')} />
                    </div>

                    <div className="input" style={{marginBottom: '30px'}}>
                        <label className="input__label">{__.fs('Lists.profile.company-zipcode-city')}</label>
                        <br />
                        <input type="text" onChange={this.handleInputChange} id="zipcode_city" name="zipcode_city" defaultValue={this.state.zipcode_city} className="input__field input__field--nopad input__field--wider" placeholder={__.fs('Lists.profile.company-zipcode-city')} />
                    </div>

                    <div className="input" style={{marginBottom: '30px'}}>
                        <label className="input__label">{__.fs('Lists.profile.company-country')}</label>
                        <br />
                        <input type="text" onChange={this.handleInputChange} id="country" name="country" defaultValue={this.state.country} className="input__field input__field--nopad input__field--wider" placeholder={__.fs('Lists.profile.company-country')} />
                    </div>



                    <button style={{marginLeft: '20px'}} className="button" onClick={this._saveUserProfileData} type="submit">{__.fs('Lists.edit.save')}</button>
                </div>
       		</div>
      	);
	}
}


const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token ? true : false,
        user_profile_data : state.User.user_profile_data
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...userActions, ...messageActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
