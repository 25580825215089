const actions = {
	SET_WORKING_OVERLAY : 'SET_WORKING_OVERLAY',

	setWorkingOverlay : (payload) => ({
		type: actions.SET_WORKING_OVERLAY,
		payload: payload
	}),
};

export default actions;
