import __ from './language';

/**
 * @var conversion the variable that converts 1 Head to the other unit
 */

export const convertHeadToOtherUnit = {
	// -- default value
	M : {
		conversion : 1,
		label : __.fs('helpers.conversion.m')
	},

	FT : {
		conversion : 3.280839895,
		label : __.fs('helpers.conversion.ft')
	},

	Bar : {
		conversion : 0.09804,
		label : __.fs('helpers.conversion.bar')
	},

	PSI : {
		conversion : 1.42197,
		label : __.fs('helpers.conversion.psi')
	},

	mwc : {
		conversion : 99.97439933107079,
		label : __.fs('helpers.conversion.mwc')
	},

	kpa : {
		conversion : 9.804139432000051,
		label : __.fs('helpers.conversion.kpa')
	}
}

/**
 * @var conversion the variable that converts 1 M3/H to the other unit
 */
export const convertQToOtherUnit = {
	// -- default value
	M : {
		conversion : 1,
		label : __.fs('helpers.conversion.m3u')
	},

	Lsec : {
		conversion : 0.277778,
		label : __.fs('helpers.conversion.lsec')
	},

	Lmin : {
		conversion : 16.666667,
		label : __.fs('helpers.conversion.lmin')
	},

	USGPM : {
		conversion : 4.402881245487,
		label : __.fs('helpers.conversion.usgpm')
	},

	ImpGPM : {
		conversion : 3.67,
		label : __.fs('helpers.conversion.impgpm')
	}
}

// -- Stompdown killaz!
/**
*	@param 	value	interval 		Value to convert
*	@param currentUnit string		The unit in which value is formatted
*	@param QorH string				Defines the type of conversion to use
*	@param backToMetric boolean		Defines wether we should divide multiply value
*
*	Description: this function converts values to another unit.
*/
export const convert = function(value, currentUnit, QorH, backToMetric = false){

	if ( QorH == 'q' ) {
		// - backToMetric defines whether we should convert a metric value to anything else, or the other way around
		if ( backToMetric ) {
			// -- Visual to DB compatible t (i.e. BAR to M3)
			return value / (convertQToOtherUnit[currentUnit]['conversion']);
		} else {
			// -- DB compatible to visual (i.e. M3 to BAR)
			return value * (convertQToOtherUnit[currentUnit]['conversion']);
		}
	} else {
		if ( backToMetric ) {
			// -- Visual to DB compatible t (i.e. BAR to M3)
			return value / (convertHeadToOtherUnit[currentUnit]['conversion']);
		} else {
			// -- DB compatible to visual (i.e. M3 to BAR)
			return value * (convertHeadToOtherUnit[currentUnit]['conversion']);
		}
	}
}
