import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import Graphs from '../Configuration/Graphs';
import {  buildDescription } from '../../helpers/productSheetHelpers';
import PageHeader from '../Common/PageHeader';
import __ from '../../helpers/language';
// -- App functions
import actions from '../../redux/compare/actions';

class Compare extends Component {
	constructor (props) {
		super(props);
		this.state = {
			tableElements : false
		}
	}

	_checkScroll = (e) => {
		if ( document.querySelector('.sub__header.sub__header--align-center') ) {
			let bottomFromTop = document.querySelector('.sub__header.sub__header--align-center').getBoundingClientRect().top + 170;
			if ( bottomFromTop <= 90 ) {
				bottomFromTop = 90;
			}
			if ( document.querySelector('.compare table thead')) {
				document.querySelector('.compare table thead').style.top = bottomFromTop + 'px';
			}

		}
	}
	componentDidMount () {
		this._parseCompareConfigurations();
		if ( document.querySelector('.sub__header.sub__header--align-center') ) {
			document.addEventListener('scroll', this._checkScroll);

			this._checkScroll();
		}
	}

	componentDidUpdate (prevProps) {
		if ( this.props.configurations !== prevProps.configurations ) {
			this._parseCompareConfigurations();

			let bottomFromTop = document.querySelector('.sub__header.sub__header--align-center').getBoundingClientRect().top + 170;
			if ( bottomFromTop <= 90 ) {
				bottomFromTop = 90;
			}
			if ( document.querySelector('.compare table thead') ) {
				document.querySelector('.compare table thead').style.top = bottomFromTop + 'px';
			}

		}
	}

	_getAllSameValues = tableElements => {

		for ( let property in tableElements ) {
			if ( tableElements.hasOwnProperty( property ) ) {

				let currentProperty = tableElements[property];
				let numItems = [];
				for ( let product in currentProperty ) {

					if ( currentProperty.hasOwnProperty( product ) ) {
						numItems.push( currentProperty[product] );
					}
				}

				tableElements = {
					...tableElements,
					[property] : {
						...tableElements[property],
						allTheSame : numItems.every( (val, i, arr) => val === arr[0] )
					}
				}
			}
		}
		return tableElements;
	}

	_parseCompareConfigurations = () => {
		const configurations = {...this.props.configurations};
		let tableElements = {};
		for ( let key in configurations ) {
			if ( configurations.hasOwnProperty(key) ) {
				const configuration = configurations[key];

				const properties = configuration.pre_filters.product_sheet;

				for ( let propertyKey in properties ) {
					if ( properties.hasOwnProperty(propertyKey) ) {

						const propertyArray = properties[propertyKey];
						for ( let i = 0; i <= propertyArray.length; i++ ) {
							if ( propertyArray[i] ) {
								tableElements = {
									...tableElements,
									[propertyArray[i]['element_name']] : {
										...tableElements[propertyArray[i]['element_name']],
										[key] : propertyArray[i]['value']
									}
								}
							}
						}
					}
				}
			}
		}

		tableElements = this._getAllSameValues(tableElements);
		this.setState({
			tableElements
		})
	}

	_renderTableHeaders = () => {
		let headers = [];
		for ( let key in this.props.configurations ) {
			if ( this.props.configurations.hasOwnProperty(key) ) {
				headers.push(<th key={key}><NavLink to={'/configuration/'+key}>{buildDescription(this.props.configurations[key].pre_filters.description, 'short_description')}</NavLink><button onClick={() => this.props.actions.removeFromCompare(key)}>X</button></th>);
			}
		}
		return headers;
	}

	_renderTableElements = () => {
		let tdKey = 0;
		const tableElements = this.state.tableElements;
		let returnElements = [];

		for ( let property in tableElements ) {

			if ( tableElements.hasOwnProperty(property) ) {
				let row = tableElements[property];
				let values = [];
				let allTheSame = false;
				for ( let value in row ) {
					if ( value === 'allTheSame' ) {
						continue;
					}

					allTheSame = tableElements[property].allTheSame;
					if ( row.hasOwnProperty(value) ) {
						values.push(<td  key={'td' + tdKey}>{row[value]}</td>);
						tdKey++;
					}
				}

				if ( Object.keys(row).length === 2 ) {
					allTheSame = false;
				}
				returnElements.push(<tr className={(allTheSame ? 'all-the-same' : '')} key={'tr' + tdKey}><td>{property}</td>{values}</tr>)
			}
		}

		return returnElements;
	}

	_renderGraphs = (configurationID, key) => {

		let configuration = this.props.configurations[configurationID];

		let lowNPSH = false;
		if ( configuration.is_low_npsh ) {
			lowNPSH = true;
		}

		return <td key={key} style={{paddingBottom: '30px'}}><Graphs low_npsh={lowNPSH} compare={true} user={this.props.user} hideAdjust={true} preconfig={configuration}/></td>
	}

    render() {
		if ( (Object.keys(this.props.configurations).length) ) {
			return (
				<React.Fragment>
					<PageHeader title={__.fs('Compare.index.configurations-to-compare')} alignCenter={true}/>
		          	<div className={"compare compare-items-"+(Object.keys(this.props.configurations).length)}>
	          			<table>
	          				<thead><tr><th>Eigenschap</th>{this._renderTableHeaders()}</tr></thead>
	          				<tbody>
	 							<tr><td></td>{this.props.configurations ? Object.keys(this.props.configurations).map(this._renderGraphs) : null}</tr>
	          					{this.state.tableElements ? this._renderTableElements() : null }
	          				</tbody>
	          			</table>
		       		</div>
				</React.Fragment>
	        );
		} else {
			return (
				<PageHeader title={__.fs('Compare.index.no-configurations-to-compare')} alignCenter={true}/>

			)
		}
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    	configurations : state.Compare.configurations,
    	user : state.User,
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Compare))
