import React from 'react';
import __ from './language';
export function mapProductsheetSpecs(value, key){
	if ( value.value === '~') {
		return null;
	}
	return (
		<tr key={key} className="techspecs__table__row">
			<td>{__._findOracleString(value.element_name)}</td>
			<td>{value.value}</td>
		</tr>
	)
}

export function displayRowHeader(displayName) {
	return(
		<tr key={displayName}>
			<td className="techspecs__table__title" colSpan="2">{displayName}</td>
		</tr>
	)
}

export function generateProductSheetsWithKey(object, useKey){
	let rows = [];
	for(const key in object) {
		if (key === useKey) {
			if ( object.hasOwnProperty(key) ) {
				rows.push(displayRowHeader(key));
				rows.push(object[key].map((value,key) => mapProductsheetSpecs(value, key)));
			}
		}

		}
	return <table><tbody>{rows}</tbody></table>
}

export function mapSizeSpecs(name, value){

	if ( value === 0) {
		return null;
	}

	if ( name === 'Total net weight' ) {
		value = parseFloat(value / 1000).toFixed(2) + 'kg';
	}
	return (
		<tr key={name} className="techspecs__table__row">
			<td>{__._findOracleString(name)}</td>
			<td>{value}</td>
		</tr>
	)
}

export function generateSizesSheetWithKey(object, useKey){
	let rows = [];

	for(const key in object) {
		if (key === useKey) {
			if ( object.hasOwnProperty(key) ) {

				rows.push(Object.keys(object[key]).map((value,obkey) => mapSizeSpecs(value, object[key][value])));
			}
		}

		}
	return <table><tbody>{rows}</tbody></table>
}

export function generateImagesWithKey(object, useKey){
	let rows = [];
	object = {drawing1: '20091216.jpg', drawing2: '20100156.jpg' }

	return <table><tbody><tr><td><img src={global.serverURL + "/images/20091216.jpg"} /></td></tr><tr><td><img src={global.serverURL + "/images/20100156.jpg"} /></td></tr></tbody></table>
}



export function findWhereKeyStartsWith(properties, id, type){

	for (let item in properties) {
		if ( properties.hasOwnProperty(item) ) {
			if ( item.startsWith(id) ) {
				return properties[item][type];
			}
		}
	}
}

export function buildDescription(properties, type){
	let description = [];

	let CH = findWhereKeyStartsWith(properties, 'CH', type);
	if ( CH ) {
		description.push(CH);
	}
	let MT = findWhereKeyStartsWith(properties, 'MT', type);
	if ( MT   ) {
		description.push(MT);
	}
	let CB = findWhereKeyStartsWith(properties, 'CB', type);
	if ( CB  ) {
		description.push(CB);
	}
	let SE = findWhereKeyStartsWith(properties, 'SE', type);
	if ( SE ) {
		description.push(SE);
	}

	let LN = findWhereKeyStartsWith(properties, 'LN', type);
	if ( LN ) {
		description.push(LN);
	}

	return description.join(' ');
}
