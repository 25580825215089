import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import React from 'react';
import { getToken } from '../../helpers/utility';
import actions from './actions';
import messageActions from '../message/actions';
import FirebaseHelper from '../../helpers/firebase';
import __ from '../../helpers/language';
import { NavLink } from 'react-router-dom';
const { rsfFirestore, firebase } = FirebaseHelper;

function userLoggedIn() {
	const localUserData = getToken();
	const user_id = localUserData.get('userId');
	return user_id;
}

export function* addListFirebase() {
	yield takeEvery(actions.ADD_LIST_FIREBASE, function*(action) {

		const { name, configuration } = action.payload;

		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		const productListData = {
			name,
			user_id,
			locked: false,
			can_be_ordered: false
		}

		if(configuration){
			const configurationObject = {
				id: configuration.id,
				products: configuration.products,
				ch: configuration.ch,
				quantity: 1,
				price: configuration.price
			}
			productListData.configurations = [
				configurationObject
			];
			productListData.categories = {
				uncategorized: {
					configurations: [
						configurationObject
					]
				}
			}
		}

		// check if user logged in
		if(user_id) {

			const newProductList = yield call(rsfFirestore.addDocument, 'product_lists', productListData);

			// if product list is succesfully added
			if(newProductList.id){
				const userSnapshot = yield call(rsfFirestore.getDocument, 'users/' + user_id);
				const userData = userSnapshot.data();

				let newProductListsOrder = userData.product_lists_order && userData.product_lists_order.length > 0 ? userData.product_lists_order : [];

				newProductListsOrder.unshift(newProductList.id);

				// update order of productlist in user collection
				yield call(rsfFirestore.updateDocument, 'users/' + user_id, 'product_lists_order', newProductListsOrder);

				// if new list has configuration make list default
				if(configuration){
					yield call(rsfFirestore.updateDocument, 'users/' + user_id, 'product_lists_default', newProductList.id);

				    yield put({
						type: messageActions.MESSAGE,
						payload: {
							message: <NavLink to={'/profile/lists/'+newProductList.id}>{__.fs('redux.productList.saga.added-to-list')}</NavLink>,
							type: 'success'
						}
				    });
				}

			}

		}
		else{

		}

	})
}

export function* addCategoryToList() {
	yield takeEvery(actions.ADD_CATEGORY_TO_LIST, function*(action) {
		const {category, listId} = action.payload;
		const user_id = userLoggedIn();

		if(user_id){

			// find product list
			const productListSnapshot = yield call(rsfFirestore.getDocument, 'product_lists/' + listId);
			const productListData = productListSnapshot.data();

			if(productListData.locked === false){
				// check if product list exists in firestore
				if(productListData && productListData.user_id === user_id){
					const newCategories = productListData.categories || {};

					// update product list with new category
					if(!newCategories[category.value]){
						newCategories[category.value] = {
							name: category.label,
							configurations: []
						};

						yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'categories', newCategories);

						yield put({
							type: messageActions.MESSAGE,
							payload: {
								message:  __.fs('Redux.productList.saga.list-updated'),
								type: 'success'
							}
					    });
					}

				}
				else{

				}
			}
			else{

			}
		}
	});
}

export function* updateProductCategories() {
	yield takeEvery(actions.UPDATE_PRODUCT_CATEGORIES, function*(action) {
		const {categories, listId} = action.payload;
		if(categories){
			yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'categories', categories);

			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message:  __.fs('Redux.productList.saga.list-updated'),
					type: 'success'
				}
		    });
		}

	});
}

export function* updateConfigurationsInLIst() {
	yield takeEvery(actions.UPDATE_CONFIGURATIONS_IN_LIST, function* (action) {
		const { configurations, listId, categories } = action.payload;
		console.log(configurations);
		console.log(listId);
		console.log(categories);

		const user_id = userLoggedIn();

		if(user_id){
			const productListSnapshot = yield call(rsfFirestore.getDocument, 'product_lists/' + listId);
			const productListData = productListSnapshot.data();

			if(productListData.locked === false){

				// check if product list exists in firestore
				if(productListData && productListData.user_id === user_id){

					//const categories = productListData.categories || {};
					const newProductListData = {
						...productListData,
						configurations,
						categories
					}

					yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, newProductListData);
				}
			}
		}
	})
}

export function* addProductToList() {

	yield takeEvery(actions.ADD_PRODUCT_TO_LIST_FIREBASE, function*(action) {
		const {configuration, listId} = action.payload;
		const user_id = userLoggedIn();

		if(user_id){

			// find product list
			const productListSnapshot = yield call(rsfFirestore.getDocument, 'product_lists/' + listId);
			const productListData = productListSnapshot.data();

			if(productListData.locked === false){

				// check if product list exists in firestore
				if(productListData && productListData.user_id === user_id){

					const configurations = productListData.configurations || [];
					const configurationIndex = configurations.findIndex((element) => {
						return (element.id === configuration.id && element.is_low_npsh === configuration.is_low_npsh);
					});

					const pre_filters = {
						description : {
							...configuration.pre_filters.description
						}
					}
					const newConfiguration = {
						id: configuration.id,
						products: configuration.products,
						ch: configuration.ch,
						quantity: 1,
						price: (configuration.price ? configuration.price : ''),
						is_low_npsh : configuration.is_low_npsh,
						pre_filters : pre_filters
					};

					const categories = productListData.categories || {};
					const uncategorizedConfigurations = (categories.uncategorized && categories.uncategorized.configurations) || [];

					if(configurationIndex === -1){
						configurations.push(newConfiguration);
						uncategorizedConfigurations.push(newConfiguration);

						categories.uncategorized = {
							configurations: uncategorizedConfigurations
						}

					}
					else{
						configurations[configurationIndex].quantity ++;
					}

					const newProductListData = {
						...productListData,
						configurations,
						categories
					}

					// update product list with new configuration
					yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, newProductListData);

					// set product list as new default
					yield call(rsfFirestore.updateDocument, 'users/' + user_id, 'product_lists_default', listId);

				    yield put({
						type: messageActions.MESSAGE,
						payload: {
							message: <NavLink to={'/profile/lists/'+listId}> { __.fs('redux.productList.saga.added-to-list')}</NavLink>,
							type: 'success'
						}
				    });

				}
				else{

				}
			}

			else{

			}

		}
	})
}

export function* changeListOrderFirebase() {

	yield takeEvery(actions.CHANGE_LIST_ORDER_FIREBASE, function*(action) {
		const listOrder = action.payload;

		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		let newProductListsOrder = listOrder.map(x => x.id);

		if(user_id){
			yield call(rsfFirestore.updateDocument, 'users/' + user_id, 'product_lists_order', newProductListsOrder);

			yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('Redux.productList.saga.list-updated'),
					type: 'success'
				}
		    });
		}

	})
}

export function* deleteListFirebase() {
	yield takeEvery(actions.DELETE_LIST_FIREBASE, function*(action) {
		const {listId} = action.payload;

		const user_id = userLoggedIn();

		const productListSnapshot = yield call(rsfFirestore.getDocument, 'product_lists/' + listId);
		const productListData = productListSnapshot.data();

		if(productListData.locked === false){

			// check if product list exists in firestore
			if(productListData && productListData.user_id === user_id){
				yield call(rsfFirestore.deleteDocument, 'product_lists/' + listId);

				// remove product list order

				// remove from default

				yield put({
					type: messageActions.MESSAGE,
					payload: {
						message: __.fs('redux.productList.saga.list-deleted'),
						type: 'success'
					}
			    });
			}
		}
		else{

		}

	})
}


export function* syncProductListCollection() {
	yield takeEvery(actions.SYNC_PRODUCT_LISTS, function*(action) {
	 	const localUserData = getToken();
	 	const user_id = localUserData.get('userId');

		if(user_id){
			yield fork(
			  	rsfFirestore.syncCollection,
			  	firebase.firestore().collection('product_lists').where('user_id', '==', user_id),
				{ successActionCreator: (snap) => actions.getProductLists(FirebaseHelper.processFireStoreCollection(snap))}
			)
		}
	})

}

export function* setProductLists(){
	yield takeEvery(actions.GET_PRODUCT_LISTS, function*(action) {
		let lists = {...action.payload};

	 	const localUserData = getToken();
	 	const user_id = localUserData.get('userId');

	 	if(user_id){
			const userSnapshot = yield call(rsfFirestore.getDocument, 'users/' + user_id);
			const userData = userSnapshot.data();
			const orderKeys =  userData.product_lists_order || [];
			const defaultList = userData.product_lists_default;

			const orderedLists = Object.keys(lists).map(key => {
				return {
					id: key,
					default: defaultList === key,
					order: orderKeys.indexOf(key),
					...lists[key]
				};
			}).sort((a,b) => {return a.order - b.order});

			yield put({
		        type: actions.SET_PRODUCT_LISTS,
		        payload: orderedLists
	      	});

	 	}

	})
}


export default function* rootSaga() {
	yield all([
		fork(addListFirebase),
		fork(changeListOrderFirebase),
		fork(deleteListFirebase),
		fork(setProductLists),
		fork(addProductToList),
		fork(updateProductCategories),
		fork(addCategoryToList),
		fork(syncProductListCollection),
		fork(updateConfigurationsInLIst)
	]);
}
