import actions from './actions';
const initState = { 
	working_overlay : false
};

export default function layoutReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_WORKING_OVERLAY:
			return {
				...state,
				working_overlay : action.payload
			}
		default:
			return state;
	}
}
