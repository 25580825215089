import React, { Component } from 'react';
import Popup from '../Common/Popup';
import { rebuildFilterArrayMultipleIndexes } from '../../helpers/utility';

class FilterPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	activeFilters : [],
        	openItems : [],
            level : 0
        }
        this._deepestLevel = 0 ;
        this._deepestPathLevel = 0;
    }

    _saveFilterPopupProperties = (event) => {
    	event.preventDefault();

    	const filterPopup = this.props.popupData;
    	const activePopupFilters = [...this.state.activeFilters];
    	let activeFilters = [...this.props.activeFilters];

    	let indexes = [];
    	// -- Remove all properties with this parent from the filter array
    	for ( let i = 0; i <= activeFilters.length; i++ ) {
    		if ( activeFilters[i] ) {
    			if ( activeFilters[i]['category'] === filterPopup.title ) {
    				indexes.push(i);
    			}
    		}
    	}

    	activeFilters = rebuildFilterArrayMultipleIndexes(activeFilters, indexes);
    	// -- Add all active filters
    	activePopupFilters.forEach(function(filter) {
        	activeFilters.push(filter)
    	}, this);


    	// -- Close popup, set active filters and apply filters to our configurations.
    	this.props.closeFilterPopup();
    	this.props.setActiveFilters(activeFilters);

    	if( this.props.filterConfigurations ) {
    		this.props.filterConfigurations(activeFilters);
    	}
    }

    _makeFilterObject = (category, item, path) => {
    	let filterPath = [...path];

    	filterPath.push(item);
    	return {
    		category : category,
    		property : item,
    		path : path.join('.'),
    		arrPath : path,
    		nestedPath: filterPath.join('.'),
    		nestedArrPath : filterPath
    	}
    }

    _popupFilterClicked = (event, item, filterObject) => {
        if ( event ) {
            event.preventDefault();
        }
    	let activeFilters = [...this.state.activeFilters];
    	const filterPath = [...filterObject.path];
    	const filter = this._makeFilterObject(this.props.popupData.title, item, filterPath)
    	let hidePreviousButton = true;
    	if(activeFilters) {
			const filterIndex = this.props.findFilterExists(filter, activeFilters);
    		// -- Filter does not exists in current active filter list, add it.
	        if(filterIndex === -1) {
	        	activeFilters.push(filter)
	        }else{
	        	// -- Does exist, remove it.
	        	activeFilters = this.props.rebuildFilterArray(activeFilters, filterIndex);
	        	hidePreviousButton = false;

	        	for ( let i = 0; i <= activeFilters.length; i++ ) {
		    		if ( activeFilters[i] ) {
		    			if ( activeFilters[i]['category'] === this.props.popupData.title ) {
		    				hidePreviousButton = true;
		    			}
		    		}
    			}
	        }
		}

    	this.setState({
    		activeFilters : activeFilters,
    		hidePreviousButton
    	})
    }

    _setPath = (e, path, level) => {
    	this.setState({
    		openItems : path,
    		activeFilters: [],
            level : level,
    	})
    }

    _checkIfLevelExceeded = (level) => {

        if ( level === this._deepestLevel ) {
            if ( this.state.openItems.length === level ) {
                return true;
            }
        }

        if ( level+1 === this.state.level || level+1 <= this.state.level) {
            return true;
        }

        return false;
    }

    _mapPopupFilterProperties = (item, values = false, level = 0, path = [], clickItem = false, parentIsOpen = false, pathLevel = 1) => {
    	path = [...path];
    	level += 1;

        if ( level > this._deepestLevel ) {
            this._deepestLevel = level;
        }

    	const unique = item;
    	let openClass = '';
    	let liOpen = '';

        path.push(unique);

    	if ( this.state.openItems ) {
            let statePathReversed = [];
            let currentPathReversed = [];

            // -- Make sure the EXACT path is found within the current active filters.
            // -- This prevents duplicate filters with largely the same name being active at te same time.
            for ( let i = 0; i <= this.state.openItems.length; i++ ) {
                if ( this.state.openItems[i] ) {
                    let value = this.state.openItems[i];
                    const joined =  value.split("").reverse().join("")
                    statePathReversed.push(joined);
                }
            }

            for ( let i = 0; i <= path.length; i++ ) {
                if ( path[i] ) {
                    let value = path[i];

                    const joined = value.split("").reverse().join("")
                    currentPathReversed.push(joined);
                }
            }

            statePathReversed = statePathReversed.join('--');
            currentPathReversed = currentPathReversed.join('--');

            if ( statePathReversed.startsWith(currentPathReversed)) {
                openClass = 'filter__popup__list--sub--opened';
                liOpen = 'opened';
            }
    	}

    	let activeClass = '';
    	let greyedOutClass = '';
      
    	if ( values[item].filterable === true || values[item].filterable === false ) {

    		const filterObject = this._makeFilterObject(this.props.popupData.title, item, values[item]['path'])
    		const filterIndex = this.props.findFilterExists(filterObject, [...this.state.activeFilters]);
    		let num = this.props.findNumFilterAvailable(filterObject.path + '.' + filterObject.property, this.props.allConfigurations);
 
            if(filterIndex !== -1) {
    			activeClass = 'filter__popup__list__item--active';
    			greyedOutClass = '';
    		}

    		if ( num === 0 ) {
    			greyedOutClass = 'filter__popup__list__item--greyed';
    		}

    		return (
    			<li key={item} className={"filter__popup__list__item filter__popup__list__item--checkbox " + activeClass  + ' ' + greyedOutClass }>
	    			<a data-parent={this.props.popupData.title} data-child={item} onClick={(event) => this._popupFilterClicked(event, item, values[item])} href="/#">{item} ({num})</a>
	    		</li>
	    	)
    	}

        let itemGreyOut = '';
        let numFilterProperties = '';
        if ( global.calcAvailableFilterProps ) {
            const numAvailableFilters = this._getAvailableSubFilters(values[item]);
            if ( numAvailableFilters === 0 ) {
                itemGreyOut = ' filter__popup__list__item--greyed'
            }

            if ( level === 1 ) {
                numFilterProperties = ' (' + numAvailableFilters + ')';
            }
        }

        // -- If item contains only one option, click it
        let shouldBeClicked = false;
        let parentOpen = false;
        let itemWidthClass = '';

        if ( Object.keys(values[item]).length === 1) {
            if(!values[item][Object.keys(values[item])[0]].hasOwnProperty('filterable')) {
                shouldBeClicked = true;
                if ( liOpen) {
                    parentOpen = true;
                    itemWidthClass = 'display-none';

                }
            }

        } else {
            pathLevel++;
        }

        if (pathLevel > this._deepestPathLevel ) {
            this._deepestPathLevel = pathLevel;
        }

        if ( clickItem ) {
            if ( !liOpen) {
                if ( parentIsOpen ) {

                    this._setPath(false, path, level);
                }
            }
        }
        if ( item !== 'groupName' ) {
             return (
                <li className={liOpen + itemGreyOut} key={item}>
                    <span className={' filter__popup__list__collapsible ' +path.join('--') } onClick={(e) => this._setPath(e, path, level)}>{item}{numFilterProperties}</span>
                    <ul className={"filter__popup__list filter__popup__list--sub  "  + itemWidthClass + ' '  + openClass +  ' ' + (this._checkIfLevelExceeded(level) ? 'item-in-array' : '')}  >
                        <li className="filter__popup__list__item filter__popup__list__item--title">{item}</li>
                        {Object.keys(values[item]).map((filterProp) => this._mapPopupFilterProperties(filterProp, values[item], level, path, shouldBeClicked, parentOpen, pathLevel))}
                    </ul>
                </li>
            )
        }

    }


    _getAvailableSubFilters(values) {
        return this._findDeepestAndMapPropertiesToNumber( values);
    }

     _findNumFilterAvailable ( fullFilterPath, configurations = false ) {
        let configurationsToUse = configurations;
        if ( !configurations ) {
            configurationsToUse = this.props.allConfigurations;
        }

        let results = 0;
        for ( let i = 0; i <= configurationsToUse.length; i++ ) {
            if ( configurationsToUse[i] ) {
                if ( configurationsToUse[i][global.filterOptionItem].includes(fullFilterPath) ) {
                    results ++;
                }
            }
        }

        return results;
    }


     _findDeepestAndMapPropertiesToNumber = (filter ) => {
        return parseInt(Object.keys(filter).map((filterProp) => this._loopNestedFilters(filterProp, filter)));
    }

    _loopNestedFilters = (filterProp, filter, numResults = 0) => {

        for ( let property in filter ) {
            if ( filter.hasOwnProperty( property ) ) {
                if ( filter[property].filterable ) {
                    if ( this._findNumFilterAvailable(filter[property].path.join('.') + '.' + property) > 0 ) {
                        numResults ++;
                    }
                } else {
                    numResults += parseInt(Object.keys(filter[property]).map((filterProp) => this._loopNestedFilters(filterProp, filter[property])));

                }
            }
        }

        return numResults;
    }


    _getActivePopupFilters = () => {
    	let popupFilters = [];
    	this.props.activeFilters.forEach(function(filter) {
    		if(filter.category === this.props.popupData.title) {
    			popupFilters.push(filter)
    		}

    	}, this);

    	return popupFilters;
    }

    componentDidMount() {
    	const activeFilters = this._getActivePopupFilters();

    	// -- Only filters in the same category may be opened, so if active filters exists, we can just use the first one in the array to set the active path.
    	let pathToOpen = false;

    	if ( activeFilters.length ) {
    		pathToOpen = activeFilters[0]['arrPath']

            let newPath = [];
            for ( let i = 0; i <= pathToOpen.length; i++ ) {
                if ( pathToOpen[i] ) {
                    if ( i > 0 ) {
                        newPath.push(pathToOpen[i]);
                    }
                }

            }

            pathToOpen = newPath;
    	}


    	this.setState({
    		activeFilters : this._getActivePopupFilters(),
    		openItems : pathToOpen,
    		hidePreviousButton : pathToOpen ? pathToOpen.length ? true : false : false
    	})
    }

    _goToPrevious = () => {
    	// -- Get the current open item path and remove the last item.
    	const openItems = [...this.state.openItems];
    	openItems.pop();
    	this.setState({
    		openItems
    	})
    }

    render() {

        return (
        	<Popup overflows={true} visible={this.props.popupOpen} popup_title={this.props.popupData.headerTitle} fullscreen={true} size={'wide'} backdrop={true} submitButton={this._saveFilterPopupProperties} togglePopup={this.props.closeFilterPopup}>
                <div className="popup__title__bar"></div>
                {/*this.state.openItems ? this.state.openItems.length && !this.state.hidePreviousButton ? <span onClick={this._goToPrevious} className="button button--active button--previous">Vorige</span> : null : null */}
   				<ul className={"filter__popup__list filter__popup__list--nested deepest-level--"+this._deepestPathLevel + (this._checkIfLevelExceeded(0) ? ' item-in-array' : '')}>
                    <li className="filter__popup__list__item filter__popup__list__item--title">{this.props.popupData.title}</li>
   					{this.props.popupData.values ? Object.keys(this.props.popupData.values).map((filterProp) => this._mapPopupFilterProperties(filterProp, this.props.popupData.values, false, [])) : null}
   				</ul>

	      	</Popup>
        );
    }

}

export default FilterPopup;
