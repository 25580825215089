const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	LOGIN_MESSAGE: 'LOGIN_MESSAGE',
	FETCH_ROUTES: 'FETCH_ROUTES',
	SET_ROUTES: 'SET_ROUTES',
	SET_MENU: 'SET_MENU',
	CHECK_URL: 'CHECK_URL',
	FETCH_ALL_DATA : 'FETCH_ALL_DATA',
	RESET_PERMISSIONS : 'RESET_PERMISSIONS',
	FETCH_USER_PROPERTIES : 'FETCH_USER_PROPERTIES',
	SET_USER_PROFILE_DATA : 'SET_USER_PROFILE_DATA',
	RESET_PASSWORD : 'RESET_PASSWORD',

	checkAuthorization: () => ({ 
		type: actions.CHECK_AUTHORIZATION 
	}),

	updateUserPermissions:  (payload) => ({
		type: actions.RESET_PERMISSIONS,
		payload: payload
	}),

	setLoginMessage:  (payload) => ({
		type: actions.LOGIN_MESSAGE,
		payload: payload
	}),	
	
	checkUrl: (payload) => ({ 
		type: actions.CHECK_URL, 
		payload:payload 
	}),

	login: (payload) => ({
	    type: actions.LOGIN_REQUEST,
	    payload: payload
	}),

	logout: () => ({
		type: actions.LOGOUT
	}),

	fetchUserProperties: (payload) => ({
		type: actions.FETCH_USER_PROPERTIES, 
		payload: payload
	}),

	resetPassword : (payload) => ({
		type : actions.RESET_PASSWORD,
		payload: payload
	})
};

export default actions;
