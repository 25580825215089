import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PreFilters from '../Filters/PreFilters/index';
import ResultTable from './ResultTable';
import HeaderWithCards from '../Common/HeaderWithCards';
import { groupConfigurations } from '../../helpers/utility';
import __ from '../../helpers/language';
class Category extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	data: false,
        	filterCollapse : '',
        	filterCollapseButton : '',
        	activeFilterCategory : false,
        	configurations : false,
        	groupedConfigurations : [],
        	unfilteredConfigurations : []
        }
    }

    _groupAndSetConfigurations = () => {
    	const initialConfigurations = [...this.props.configurations];
		const groupedConfigurations = initialConfigurations;

		if ( initialConfigurations.length === 0 ) {
			this.setState({
				configurations : false,
				groupedConfigurations : []
			})
		} else {
			this.setState({
				configurations : initialConfigurations,
				unfilteredConfigurations : initialConfigurations,
				groupedConfigurations : groupedConfigurations
			})
		}
    }

    componentDidUpdate(prevProps) {
		if(prevProps.configurations !== this.props.configurations) {
			this._groupAndSetConfigurations();
		}
    }

    componentDidMount() {
    	if(this.props.configurations) {
    		this._groupAndSetConfigurations();
    	}
    }

    _setActiveFilterCategory = (filterCategory) => {
    	this.setState({
    		activeFilterCategory : filterCategory,
    		filterCollapse : 'collapse--open',
    		filterCollapseButton : 'collapse__button--visible'
    	})
    }

    _collapseIn = () => {
    	this.setState({
    		...this.state,
    		filterCollapse : '',
    		filterCollapseButton : ''
    	})
    }

    _findConfigurationHasFilters(properties, filters) {
    	let numberOfTruths = 0;
    	let numberOfCategories = 0;
    	let configurationProps = [];
    	for(let category in filters) {
    		if(category !== 'text') {
	    		if(filters.hasOwnProperty(category)) {
	    			numberOfCategories++;
	    			let filter = filters[category];
	    			let trueForCategory = false;

	    			for(let property in filter) {

	    				if(properties[category]) {
	    					configurationProps.push(properties[category]);
	    					if(properties[category] === property) {
	    						trueForCategory = true;
	    					}
	    				}
	    			}

	    			if(trueForCategory) {
	    				numberOfTruths++;
	    			}
	    		}
	    	}
    	}
    	return numberOfCategories === numberOfTruths;
    }

    _findConfigurationHasKeyValueFilter ( properties, filters ) {
    	const numberOfFilters = filters.length;
    	let numberOfTruths = 0;
    	for ( let i = 0; i <= filters.length; i++ ) {
    		if ( filters[i] ) {
    			if (properties[filters[i]['key']]) {
    				if ( properties[ filters[i]['key'] ]  === filters[i]['property'] ) {
    					numberOfTruths++;
    				}
    			}
    		}
    	}
    	return numberOfTruths === numberOfFilters;
    }

    _findConfigurationContainsSearchString(configuration, filters) {
    	let stringifiedConfiguration = JSON.stringify(configuration).toLowerCase();

    	let numberOfSearchStrings = 0;
    	let numberOfFinds = 0;
    	for(let category in filters) {
    		if(category === 'text') {
	    		if(filters.hasOwnProperty(category)) {

	    			let filter = filters[category];

	    			for(let searchQuery in filter) {
	    				numberOfSearchStrings++;

	    				if( stringifiedConfiguration.includes(searchQuery.toLowerCase()) ) {
	    					numberOfFinds++;
	    				}
	    			}
	    		}
	    	}
    	}
    	return numberOfSearchStrings === numberOfFinds;
    }

    _findConfigurationHasFilter = (properties, filters) => {
    	let numberOfTruths = 0;
    	let numberOfCategories = 0;

    	for(let category in filters) {
    		if(category !== 'text') {
	    		if(filters.hasOwnProperty(category)) {
	    			numberOfCategories++;
	    			let filter = filters[category];
	    			let trueForCategory = false;

	    			for(let property in filter) {
	    				const filterProperty = category + '.' + property;

	    				if ( properties.includes(filterProperty) ) {
	    					trueForCategory = true;
	    				}
	    			}

	    			if(trueForCategory) {
	    				numberOfTruths++;
	    			}
	    		}
	    	}
    	}

    	return numberOfCategories === numberOfTruths;
    }

    _categorizeKeyValueFilters = (activeFilters) => {
		let categorizedFilters = {};

		activeFilters.forEach(function(filter) {
			categorizedFilters = {
				...categorizedFilters,
				[filter.path] : {
					...categorizedFilters[filter.path],
					[filter.property] : true
				}
			}
		})

		return categorizedFilters;
	}

    _filterConfigurations = (activeFilters, pushToState = true) => {
    	let resultConfigurations = [];
    	const allConfigurations = [...this.state.unfilteredConfigurations];
    	let categorizedFilters = this._categorizeKeyValueFilters(activeFilters);

		for ( let i = 0; i <= allConfigurations.length; i++ ) {
			if ( allConfigurations[i] ) {
				const configuration = allConfigurations[i];

				const result = this._findConfigurationHasFilter(configuration[global.filterOptionItem], categorizedFilters);

				if ( result ) {
					resultConfigurations.push(configuration);
				}
			}
		}

    	if ( pushToState ) {
    		let groupedConfigurations = groupConfigurations(resultConfigurations);

    		if ( !groupedConfigurations.length ) {
    			groupedConfigurations = resultConfigurations;
    		}

    		this.setState({
	    		configurations : resultConfigurations,
	    		groupedConfigurations : groupedConfigurations
	    	})
    	} else {
    		return resultConfigurations;
    	}
    }

    render() {
        return (
          	<div>
          		<HeaderWithCards />
          		<PreFilters />
          		{this.state.configurations !== false ?
	          		<div>
                    <div className="container container--description">
                        <h3>{__.fs('ConfiguredProducts.index.step-1-description')}</h3>
                    </div>
			            <ResultTable groupedConfigurations={this.state.groupedConfigurations}/>
			        </div>
	            : null }
       		</div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    	configurations : state.Configurations.preconfig_configurations
    }
}


export default withRouter(connect(mapStateToProps)(Category))
