import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter, NavLink } from 'react-router-dom';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageHeaderWithContent from '../Common/PageHeaderWithContent';
import authActions from '../../redux/auth/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';



class Authenticate extends Component {

	state = {
		email: "",
		password: ""
	}

	componentDidMount() {

		const parsed = queryString.parse(this.props.location.search);

		if(parsed.custom_token){
			this.props.actions.login({custom_token: parsed.custom_token});
		}

		if ( this.props.location.pathname === '/logout' ) {
			this.props.actions.logout();
		}
	}

	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	submitForm = (e) => {
		if( e ) {
			e.preventDefault();
		}
		let error = false;
		const emailIsValid = validator.isEmail(this.state.email);
		const passwordIsValid = !validator.isEmpty(this.state.password);

		if(!emailIsValid) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Authenticate.index.e-mail-invalid'),
				type: 'error'
			})
		}

		if(!passwordIsValid) {
			error = true;
			this.props.actions.setMessage({
				message: __.fs('Authenticate.index.password-invalid'),
				type: 'error'
			})
		}

		if(!error){
			this.props.actions.login(this.state);
		}
	}

	render() {
      	return(
      		<div className="loginpage">
      			<PageHeaderWithContent title={__.fs('Authenticate.index.title')} alignCenter={true}>
      				<form className="loginform" onSubmit={this.submitForm}>
			      		<div className="container">
			      			<div className="loginform__group">
				      			<label>{__.fs('Authenticate.index.emailaddress')}</label>
								<input type="text" onChange={this.handleInputChange} id="email" name="email" placeholder={__.fs('Authenticate.index.emailaddress')} />
							</div>
							<div className="loginform__group">
								<label>{__.fs('Authenticate.index.password')}</label>
								<input type="password" onChange={this.handleInputChange} id="password" name="password" placeholder={__.fs('Authenticate.index.password')} />
							</div>
							<NavLink style={{textAlign: 'left', color: 'white', width: '100%', float: 'left'}} to="/forgot-password">Wachtwoord vergeten</NavLink>
							<button className="button" onClick={this.submitForm} type="submit">{__.fs('Authenticate.index.submit')}</button>
			      		</div>
			      	</form>
      			</PageHeaderWithContent>

      		</div>
      	);
	}
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...authActions, ...messageActions}, dispatch) })

export default withRouter(connect(false, mapDispatchToProps)(Authenticate))
