const actions = {
	FETCH_CONFIGURATIONS: 'FETCH_CONFIGURATIONS',
	SET_CONFIGURATIONS: 'SET_CONFIGURATIONS',
	SET_SINGLE_CONFIGURATION : 'SET_SINGLE_CONFIGURATION',
	REMOVE_SINGLE_CONFIGURATION : 'REMOVE_SINGLE_CONFIGURATION',
	ADD_CONFIGURATIONS : 'ADD_CONFIGURATIONS',
	PROCESS_CONFIGURATIONS : 'PROCESS_CONFIGURATIONS',
	PROCESS_CONFIGURATIONS_ELASTIC : 'PROCESS_CONFIGURATIONS_ELASTIC',
	PROCESS_CONFIGURATIONS_ELASTIC_CHUNKED : 'PROCESS_CONFIGURATIONS_ELASTIC_CHUNKED',
	PROCESS_CONFIGURATIONS_ELASTIC_SINGLE : 'PROCESS_CONFIGURATIONS_ELASTIC_SINGLE',
	FETCH_CONFIGURATIONS_WITH_QUERY : 'FETCH_CONFIGURATIONS_WITH_QUERY',
	RESET_CONFIGURATIONS : 'RESET_CONFIGURATIONS',
	FETCH_CONFIGURATIONS_WITH_QH: 'FETCH_CONFIGURATIONS_WITH_QH',
	FETCH_CONFIGURATION_BY_ID : 'FETCH_CONFIGURATION_BY_ID',
	SET_FULL_FILTER_TREE : 'SET_FULL_FILTER_TREE',
	DOWNLOAD_PRODUCT_SHEET : 'DOWNLOAD_PRODUCT_SHEET',
	FETCH_PRECONFIG_CONFIGURATIONS_WITH_QH : 'FETCH_PRECONFIG_CONFIGURATIONS_WITH_QH',
	FETCH_PRECONFIG_CONFIGURATIONS_WITH_QUERY : 'FETCH_PRECONFIG_CONFIGURATIONS_WITH_QUERY',
	CREATE_FILTER_TREE : 'CREATE_FILTER_TREE',
	BUILD_FULL_FILTER_TREE : 'BUILD_FULL_FILTER_TREE',
	SET_PRECONFIG_CONFIGURATIONS : 'SET_PRECONFIG_CONFIGURATIONS',

	buildFullFilterTree : (payload) => ({
		type: actions.BUILD_FULL_FILTER_TREE,
	}),

	fetchConfigurationsWithQH : (payload) => ({
		type: actions.FETCH_CONFIGURATIONS_WITH_QH,
		payload: payload
	}),

	fetchPreconfigConfigurationsWithQH : (payload) => ({
		type: actions.FETCH_PRECONFIG_CONFIGURATIONS_WITH_QH,
		payload: payload
	}),

	fetchConfigurationById : (payload) => ({
		type: actions.FETCH_CONFIGURATION_BY_ID,
		payload: payload
	}),

	fetchConfigurationsWithQuery : (payload) => ({
		type : actions.FETCH_CONFIGURATIONS_WITH_QUERY,
		payload : payload
	}),

	fetchPreconfigConfigurationsWithQuery : (payload) => ({
		type : actions.FETCH_PRECONFIG_CONFIGURATIONS_WITH_QUERY,
		payload : payload
	}),

	createFilterTree : (payload) => ({
		type: actions.CREATE_FILTER_TREE,
		payload: payload
	}),

	resetConfigurations : () => ({
		type: actions.RESET_CONFIGURATIONS
	}),

	fetchConfigurations : () => ({
		type : actions.FETCH_CONFIGURATIONS
	}),

	setConfigurations : (payload) => ({
		type: actions.SET_CONFIGURATIONS,
		payload : payload
	}),

	downloadProductSheet : (payload) => ({
		type: actions.DOWNLOAD_PRODUCT_SHEET,
		payload: payload
	}),

	setFullFilterTree : (payload) => ({
		type: actions.SET_FULL_FILTER_TREE,
		payload: payload
	})

};

export default actions;
