const actions = {
	MESSAGE: 'MESSAGE',

	setMessage : (payload) => ({
		type: actions.MESSAGE,
		payload : payload
	}),
};

export default actions;
