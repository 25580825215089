import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getToken } from '../../helpers/utility';
import actions from './actions';
import messageActions from '../message/actions';
import __ from '../../helpers/language';
import FirebaseHelper from '../../helpers/firebase';

const { rsfFirestore, firebase } = FirebaseHelper;

function userLoggedIn() {
	const localUserData = getToken();
	const user_id = localUserData.get('userId');
	return user_id;
}

export function* addListCategoryFirebase() {
	yield takeEvery(actions.ADD_LISTCATEGORY_FIREBASE, function*(action) {
		const { name } = action.payload;
		const user_id = userLoggedIn();

		if(user_id){
			const categoryData = {
				name,
				user_id
			}

			yield call(rsfFirestore.addDocument, 'categories', categoryData);

		    yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('redux.listCategories.saga.category-added-successfully'),
					type: 'success'
				}
		    });
		}

	});
}

export function* syncCategoryCollection() {
	const user_id = userLoggedIn();

	if(user_id){

		yield fork(
		  	rsfFirestore.syncCollection,
		  	firebase.firestore().collection('categories').where('user_id', '==', user_id),
			{ successActionCreator: (snap) => actions.setListCategories(FirebaseHelper.processFireStoreCollection(snap))}
		)
	}

}

export default function* rootSaga() {
	yield all([
		fork(addListCategoryFirebase),
		fork(syncCategoryCollection)
	]);
}
