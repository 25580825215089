import React from 'react';

class CustomizedRect extends React.Component {
 render() {
     const { cx, cy } = this.props;

     return (

          <rect x={cx - 3} y={cy - 3} width="6" height="6" style={{fill: 'rgb(255,255,255)', strokeWidth: 2, stroke: 'rgb(0, 0, 255)'}} />
     );
 }
};

export default CustomizedRect;
