import preconfig_dp from '../preconfig/preconfig_dp.json';

class Preconfig  {

	constructor() {

		this._preConfigArtNr = this._preConfigArtNr.bind(this);		
	}

	_preConfigArtNr(identifier){
		let listPreConfig = false;
        
        // -- only default DP at the moment
		listPreConfig = preconfig_dp;
		for ( let i = 0; i <= listPreConfig.length; i++ ) {
			if( listPreConfig[i] ) {
				if ( listPreConfig[i]['ch_name'] === identifier ) {
					return listPreConfig[i]['name'];
				}
			}
        }
        
        return '';
	}


}

export default new Preconfig();