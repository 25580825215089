import React, { Component } from 'react';
import { getColumns } from './Columns';
import { getPageSize } from '../../helpers/utility';
import Pagination from "../Common/Pagination";
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import scrollToElement from 'scroll-to-element';
import ChartPopup from './ChartPopup';
import AddToListPopup from '../Lists/AddToListPopup';
import { connect } from 'react-redux';
import actions from '../../redux/compare/actions';
import __ from '../../helpers/language';
import { bindActionCreators } from 'redux';
import graphCalculator from '../../helpers/graphCalc';
import { buildDescription } from '../../helpers/productSheetHelpers';

class ResultTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
        	chartPopup : {
        		visible: false
        	},
        	addToListPopup: {
        		visible: false
        	}
		}
	}

	_renderExpander = (props) => {
		const currentExpanded = {...this.state.expanded};
		if(props.original.configurations) {
			if( currentExpanded[props.index] === true ) {
		  		return <div className="expand__sub__rows">{__.fs('ConfiguredProducts.ResultTable.less-results')}</div>
		  	}else{
		  		return <div className="expand__sub__rows">{__.fs('ConfiguredProducts.ResultTable.more-results')} ({props.original.configurations.length})</div>
		  	}
	  	}
	}

	_toggleChartPopup = (e, item) => {
		e.preventDefault();
		this.setState({
			chartPopup : {
				visible : true,
				ch : item.ch,
				curveValues : item.curve_values,
				singleConfiguration: item
			}
		})
	}

	_closeChartPopup = (e) => {
		this.setState({
			chartPopup : {
				visible: false,
				ch: null,
				curveValues: []
			}
		})
	}

	_toggleAddToListPopup = (e, item) => {
		e.preventDefault();
		this.setState({
			addToListPopup : {
				visible : true,
				title : __.fs('ConfiguredProducts.ResultTable.add-to-list'),
				productData: item
			}
		})
	}

	_addToCompare = (e, item) => {
		e.preventDefault();
		this.props.actions.addToCompare( {...item} );
	}

	_closeAddToListPopup = (e) => {
		this.setState({
			addToListPopup : {
				visible: false
			}
		})
	}

	componentDidMount() {
		scrollToElement('.full__width.filters');
	}

	_getPrice = (priceObject, type) => {
		if ( priceObject ) {
			if ( this.props.isLoggedIn ) {
				if ( this.props.user.clientID ) {
					if ( priceObject[this.props.user.clientID]) {
						return priceObject[this.props.user.clientID][type];
					}
				}
			}

			return priceObject.dp[type];
		}
	}

	_shouldDisplayNetPrice = () => {
		if ( this.props.isLoggedIn ) {
			if ( this.props.user.clientID ) {
				return true;
			}
		}
		return false
	}

	_getDiscount(oldPrice, newPrice) {
		const difference = oldPrice - newPrice;
		const part = oldPrice / difference;
		return Number(100/part).toFixed(0) +'%';
	}

	_getOpt = (configuration, field, key) => {

		let dutyPoint = false;
		const getValuesFrom = (this.props.match.params.isLowNpsh === '1' ? 'low_npsh' : 'general');

		// -- If user has reached this configuration by searching on QH, set the values so we can use them for the graphs.
		if ( sessionStorage.getItem('qhFilterValues') ) {
          	dutyPoint = JSON.parse(sessionStorage.getItem('qhFilterValues'));
          	dutyPoint = {
          		q : dutyPoint.Q,
          		h : dutyPoint.H
          	}
        }

		if ( dutyPoint === false ) {
			const opt = configuration[getValuesFrom].polynomial_values.qopt;

			const configKey = key + (opt * 10);

			return configuration[getValuesFrom].polynomial_values[field][configKey];
		} else {

			// -- User searched by QH, we'll need to return the calculated opt value.
			const calculator = new graphCalculator(configuration, 50, dutyPoint, 0, 0, getValuesFrom);

			let actualDutyPoint = calculator._getVariableQHSystemCurveIntersection(true);
			if ( !actualDutyPoint.length ) {
				actualDutyPoint = false;
			} else {
				actualDutyPoint = actualDutyPoint[0];
			}
			switch(field) {
				case 'eff':
					return calculator.Efficiency._getEffOpt(actualDutyPoint);
				case 'power':
					return calculator.P2._getPowerOpt(actualDutyPoint);
				case 'npsh':
					return calculator.NPSH._getNPSHOpt(actualDutyPoint);
				default:
					return false;
			}
		}
	}

	getAccessor = (row) => {
		return 'general.polynomial_values.eff.eff19';
	}

	buildProductString = (products, splitter, useForURL = false) => {

		if ( this.props.match.params.isLowNpsh === '1' ) {

		}

		if ( products.length ) {
			let productArray = [...products];
			if ( this.props.match.params.isLowNpsh === '1' && useForURL === true) {
				productArray.pop();

			}
			return productArray.join(splitter);
		} else {
			let productArray = [];

			for ( const property in products ) {
				if ( products.hasOwnProperty(property) ) {
					productArray.push(products[property]);
				}
			}

			if ( this.props.match.params.isLowNpsh === '1' && useForURL === true) {
				productArray.pop();
			}

			return productArray.join(splitter);
		}
	}

	render() {
		  const columFunctions = {
        	renderExpander : this._renderExpander,
        	toggleChartPopup : this._toggleChartPopup,
        	toggleAddToListPopup: this._toggleAddToListPopup,
        	isLoggedIn : this.props.isLoggedIn,
        	addToCompare : this._addToCompare,
        	getPrice : this._getPrice,
			shouldDisplayNetPrice : this._shouldDisplayNetPrice,
			getDiscount : this._getDiscount,
			_buildDescription : buildDescription,
			getOpt : this._getOpt,
			getAccessor : this.getAccessor,
			buildProductString : this.buildProductString,
			powerOpt : ( this.props.preconfig  ? parseFloat(this._getOpt(this.props.preconfig, 'power', 'p')) : 0 ),
			effOpt :  ( this.props.preconfig  ? parseFloat(this._getOpt(this.props.preconfig, 'eff', 'eff')) : 0 ),
			npshOpt :  ( this.props.preconfig  ? parseFloat(this._getOpt(this.props.preconfig, 'npsh', 'n')) : 0 ),
			lowNPSHURL : (this.props.match.params.isLowNpsh === '1' ? 'ln' : 'gn')
        }

		return(
			  <div className="results__wrapper results__wrapper--nopad" id="resultswrapper">

		            <ReactTable
		           		PaginationComponent={Pagination}
	                    data={this.props.groupedConfigurations ? this.props.groupedConfigurations : false}
	               		columns={getColumns(columFunctions)}
	               		rowsText={__.fs('ConfiguredProducts.ResultTable.results')}
	               		showPagination={this.props.groupedConfigurations.length > 10 ? true : false}
	               		defaultPageSize={ 10 }
	               		multiSort={ true }
						sorted={[{id : 'efficiency', desc: true}]}
	                />

	                {this.state.chartPopup.visible ?
	                	<ChartPopup singleConfiguration={this.state.chartPopup.singleConfiguration} closePopup={this._closeChartPopup} data={this.state.chartPopup} />
	                : null }

	                {this.state.addToListPopup.visible ?
	                	<AddToListPopup closePopup={this._closeAddToListPopup} data={this.state.addToListPopup} />
	                : null }
            </div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
    	kwh_electricity: state.User.default_settings.kwh_electricity,
        water_usage : state.User.default_settings.water_usage,
        duty_point : state.User.duty_point,
        isLoggedIn : state.User.logged_in,
        user : state.User
    }
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultTable))
