import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';

export function* setWorkingOverlay() {
	yield takeEvery(actions.SET_WORKING_OVERLAY, function*(action) {
		
	})
}



export default function* rootSaga() {
	yield all([
		fork(setWorkingOverlay),
	]);
}
