import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { routerMiddleware } from 'react-router-redux';

const createHistory = require("history").createBrowserHistory;
const history = createHistory();
const routersMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware();
const middlewares = [ sagaMiddleware, routersMiddleware ];


function configureStore (initialState) {
	const store = createStore(
		rootReducer,
		compose(applyMiddleware(...middlewares))
	);



	sagaMiddleware.run(rootSaga);

	return { store, history }
}

export default configureStore();
