import React from 'react';
import Popup from '../Common/Popup';
import Draggable from 'react-draggable';
import Graphs from '../Configuration/Graphs';
const ChartPopup = (props) => {
	return (
		<div className="draggable-wrapper">
			<Draggable>

				<div>
					<Popup draggable={true} visible={props.data.visible} popup_title={(props.data ? props.data.ch : null)} size={'large-right'} backdrop={false} togglePopup={props.closePopup}>
		                <Graphs popup={true} low_npsh={props.data.singleConfiguration.is_low_npsh} singleConfiguration={props.data.singleConfiguration} />
					</Popup>
				</div>
			</Draggable>
		</div>
	)
}


export default ChartPopup;
