import React from 'react';
import PropTypes from 'prop-types';

const Popup = (props) => {
    return (
    	<div className={"popup popup--confirm"}>
          	<div className={'popup__backdrop'}>
          		<div className={"popup__container popup__container--confirm"}>
	 				<div className="popup__header">
       					{props.title}
       				</div>
       				<div className="popup__body popup__body--confirm">
           				<button onClick={props.deny} className="popup__button popup__button--deny">
       						{props.denyButton}
       					</button>

       					<button onClick={props.confirm} className="popup__button popup__button--confirm">
       						{props.confirmButton}
       					</button>
           			</div>
           		</div>
            </div>
        </div>
    );
}

Popup.propTypes = {
  	denyButton: PropTypes.string.isRequired,
  	confirmButton: PropTypes.string.isRequired,
  	title : PropTypes.string.isRequired,
  	deny : PropTypes.func.isRequired,
  	confirm : PropTypes.func.isRequired
};

export default Popup;
