export const errorCodes = {
	'auth/user-not-found': {
		type: 'error',
		message: 'Gebruiker niet gevonden'
	},
	'auth/app-deleted': {
		type: 'error',
		message: 'De applicatie bestaat niet'
	},
	'auth/app-not-authorized': {
		type: 'error',
		message: 'U heeft te weinig rechten'
	},
	'auth/argument-error': {
		type: 'error',
		message: 'U dient alle gegevens in te vullen'
	},
	'auth/invalid-api-key': {
		type: 'error',
		message: 'De applicatie heeft geen verbinding met de database'
	},
	'auth/invalid-user-token': {
		type: 'error',
		message: 'Uw login is ongeldig'
	},
	'auth/network-request-failed': {
		type: 'error',
		message: 'U heeft geen verbinding'
	},
	'auth/operation-not-allowed': {
		type: 'error',
		message: 'Dit is niet toegestaan'
	},
	'auth/requires-recent-login': {
		type: 'error',
		message: 'U dient opnieuw in te loggen'
	},
	'auth/too-many-requests': {
		type: 'error',
		message: 'U heeft te vaak geprobeerd in te loggen'
	},
	'auth/unauthorized-domain': {
		type: 'error',
		message: 'Domein niet geautoriseerd'
	},
	'auth/user-disabled': {
		type: 'error',
		message: 'Deze gebruiker is uitgeschakeld'
	},
	'auth/user-token-expired': {
		type: 'error',
		message: 'Uw login is verlopen'
	},
	'auth/web-storage-unsupported': {
		type: 'error',
		message: 'Uw browser ondersteund geen lokale opslag'
	},
	'auth/wrong-password': {
		type: 'error',
		message: 'Dit wachtwoord is niet geldig'
	},
	'auth/weak-password': {
		type: 'error',
		message: 'Dit wachtwoord is te zwak'
	},
	'auth/invalid-email': {
		type: 'error',
		message: 'Dit is een ongeldig e-mailadres'
	}
};