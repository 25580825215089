import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from '../Common/Popup';
import { withRouter } from 'react-router-dom';
import orderListActions from '../../redux/orderList/actions';
import __ from '../../helpers/language';
import messageActions from '../../redux/message/actions';

class OrderRequestPopup extends Component {

	state = {
		activeOption: null,
		reference: null,
		delivery_date: null,
		company: null,
		street_number: null,
		zipcode_city: null,
		country: null,
		orderConfigurations : []
	}


	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	componentDidMount() {
		this.setState({
			...this.state,
			orderConfigurations : [...this.props.data.list.configurations],
			delivery_date : this.props.data.list.delivery_date ? this.props.data.list.delivery_date : '',
			reference : this.props.data.list.reference ? this.props.data.list.reference : ''
		})
	}

	submit = () => {

		let error = false;
		let orderConfigurations = [];
		for ( let i = 0; i <= this.state.orderConfigurations.length; i++ ) {
			if ( this.state.orderConfigurations[i] ) {
				if ( this.state.orderConfigurations[i].order === true ) {
					let configuration = this.state.orderConfigurations[i];
					configuration.displayedPrice = (configuration.dps_price ? configuration.dps_price : this._getPrice(configuration.price, 'netto'))
					configuration.displayedQuantity = (configuration.dps_quantity ? configuration.dps_quantity : configuration.quantity)
					orderConfigurations.push(configuration);
				}
			}
		}

		if ( !orderConfigurations.length ) {
			this.props.actions.setMessage({
				message: __.fs('Lists.OrderRequestPopup.at-least-one-configuration'),
				type: 'error'
			})
			error = true;
		}


		if(!error){
			this.props.actions.requestOrder({
				listId: this.props.data.list.id,
				listConfigurations: orderConfigurations,
				reference : this.state.reference,
				delivery_date: this.state.delivery_date,
				company: this.state.company ? this.state.company : this.props.userData.user_profile_data.company,
				street_number: this.state.street_number ? this.state.street_number : this.props.userData.user_profile_data.street_number,
				zipcode_city: this.state.zipcode_city ? this.state.zipcode_city : this.props.userData.user_profile_data.zipcode_city,
				country: this.state.country ? this.state.country : this.props.userData.user_profile_data.country,
			});

			this.props.closePopup();
		}

	}

	_mapConfigurations = ( item, key ) => {

		return (

			<div className="order-request-form__configuration" key={key}>
				<span onClick={(e) => this._setOrderConfiguration(e, key)} className={"order-request-form__configuration__checkbox " + (this.state.orderConfigurations[key].order ? 'order-request-form__configuration__checkbox--active' : '')}></span>
				<div className="order-request-form__configuration__products">

					{item.products.join('-')}
				</div>
				<div className="order-request-form__configuration__quantity">
					{item.dps_quantity ? item.dps_quantity : item.quantity }
				</div>
				<div className="order-request-form__configuration__price">
				€ {item.dps_price ? item.dps_price : this._getPrice(item.price, 'netto')} <br />
				</div>
			</div>

		)
	}

	_getPrice = (priceObject, type) => {
		if ( this.props.isLoggedIn ) {
			if ( this.props.user.clientID ) {
				if ( priceObject[this.props.user.clientID]) {
					return priceObject[this.props.user.clientID][type];
				}
			}
		}

		return priceObject.dp[type];
	}

	_setOrderConfiguration = (e, key) => {
		let configurations = [...this.state.orderConfigurations];

		for ( let i = 0; i <= configurations.length; i++ ) {
			if ( configurations[i] ) {
				if ( i === key ) {
					configurations[i].order = !configurations[i].order;
				}

			}
		}

		this.setState({
			...this.state,
			orderConfigurations : configurations
		})


	}

	render() {
		return (
			<Popup
				visible={this.props.data.visible}
				popup_title={(this.props.data ? this.props.data.title : null)}
				fullscreen={true}
				size={'extra-large'}
				backdrop={true}
				togglePopup={this.props.closePopup}>
				<div className="order-request-form">
					<div className="form-group">
						<label>{__.fs('Lists.OrderRequestPopup.Reference')}</label>
						<input type="text" value={this.state.reference ? this.state.reference : ''} onChange={this.handleInputChange} placeholder={__.fs('Lists.OrderRequestPopup.Reference')} id="reference" name="reference" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>
					<div className="form-group">
						<label>{__.fs('Lists.OrderRequestPopup.Delivery-date')}</label>
						<input type="text" value={this.state.delivery_date ? this.state.delivery_date : ''} onChange={this.handleInputChange} placeholder={__.fs('Lists.OrderRequestPopup.Delivery-date')} id="delivery_date" name="delivery_date" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>
		      		<div className="form-group">
						<label>{__.fs('Lists.OrderRequestPopup.Company')}</label>
					  <input type="text" onChange={this.handleInputChange} value={this.props.userData.user_profile_data.company ? this.props.userData.user_profile_data.company : ''} placeholder={__.fs('Lists.OrderRequestPopup.Company')} id="company" name="company" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>
					<div className="form-group">
						<label>{__.fs('Lists.OrderRequestPopup.Street-and-number')}</label>
					  <input type="text" onChange={this.handleInputChange} value={this.props.userData.user_profile_data.street_number ? this.props.userData.user_profile_data.street_number : ''} placeholder={__.fs('Lists.OrderRequestPopup.Street-and-number')} id="street" name="street" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>
					<div className="form-group">
					<label>{__.fs('Lists.OrderRequestPopup.Zipcode-and-city')}</label>
					  <input type="text" onChange={this.handleInputChange} value={this.props.userData.user_profile_data.zipcode_city ? this.props.userData.user_profile_data.zipcode_city : ''} placeholder={__.fs('Lists.OrderRequestPopup.Zipcode-and-city')} id="city" name="city" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>
					<div className="form-group">
						<label>{__.fs('Lists.OrderRequestPopup.Country')}</label>
					  <input type="text" onChange={this.handleInputChange} value={this.props.userData.user_profile_data.country ? this.props.userData.user_profile_data.country : ''} placeholder={__.fs('Lists.OrderRequestPopup.Country')} id="country" name="country" style={{border: '1px solid #bdbdbd', padding: '10px 14px'}} />
					</div>

					{this.state.orderConfigurations.map(this._mapConfigurations)}

					<button className="button" onClick={this.submit} type="submit">{__.fs('Lists.OrderRequestPopup.Order')}</button>
				</div>
			</Popup>
		)

	}
}

const mapStateToProps = (state, ownProps) => {

    return {
		isLoggedIn: state.Auth.user_token ? true : false,
		userData: state.User.user_profile_data.userData,
		user : state.User
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...orderListActions, ...messageActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderRequestPopup))
