import React, { Component } from 'react';
import { generateProductSheetsWithKey, generateSizesSheetWithKey, generateImagesWithKey } from '../../helpers/productSheetHelpers';

class Sizes extends Component {

    componentDidMount() {
        this.props.setTitle('Dimensies specificatieblad');
    }
    render() {
         return (
           	<div className="specs-sheet">
           		<div className="specs-sheet__content">
                     <div className="specs-sheet__columns">
                         <div className="specs-sheet__columns__column">
           				  {this.props.configuration ? generateProductSheetsWithKey(this.props.configuration.pre_filters.product_sheet, 'Dimensions') : null}
                           {this.props.configuration ? generateSizesSheetWithKey(this.props.configuration.pre_filters, 'weightAndHeight') : null}
     				   </div>
                        <div className="specs-sheet__columns__column">
                        {this.props.configuration ? generateImagesWithKey(this.props.configuration.pre_filters.product_sheet, 'Drawing') : null}
                        </div>
                     </div>
         		</div>
           	</div>
         );
     }
}


export default Sizes
