import React from 'react';
import * as Styled from './styled';
import PropTypes from 'prop-types';

const Checkbox = props => (
	

		<Styled.CheckboxWrapper >
			 <input
	          type="checkbox"
	          checked={props.value}
	          onChange={props.onChange}
	        />
			<Styled.CheckboxCircle className={(props.value ? 'active' : '')} />
		</Styled.CheckboxWrapper>

)

Checkbox.propTypes = {
	onClick : PropTypes.func,
	value : PropTypes.bool,
	label : PropTypes.string
}

export default Checkbox;