import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
class Card extends Component {
    render() {
        return (
        	<NavLink activeClassName="card--active" to={this.props.url} className="card" style={{backgroundImage: `url(${this.props.background})`}}>
	          	<div >
	 				<h2 className="card__title">{this.props.title}</h2>
	            </div>
	        </NavLink>
        );
    }
}

export default withRouter(Card);
