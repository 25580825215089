import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../redux/message/actions';

class Notifier extends Component {

	render() {
		if(this.props.message.message){
			toast(this.props.message.message, {type: this.props.message.type});
		}
      	return(
      		<ToastContainer />
      	);
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        message: state.Message
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(Notifier)
