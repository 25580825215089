import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../redux/user/actions';
import Popup from '../Common/Popup';
import __ from '../../helpers/language';
import { convertHeadToOtherUnit, convertQToOtherUnit } from '../../helpers/conversion';
class DefaultSettingsPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
        	defaultSettings : {
        		frequency : null,
                height: null,
        		motor_poles : null,
                units : {
                    Q : this.props.defaultSettings.units.Q,
                    H : this.props.defaultSettings.units.H
                }
        	}
        }
    }

    componentDidMount() {
    	this.setState({
    		defaultSettings : {
    			frequency : this.props.defaultSettings.frequency.value,
                height: this.props.defaultSettings.height,
    			motor_poles : this.props.defaultSettings.motor_poles.value,
                units : {
                    Q : this.props.defaultSettings.units.Q,
                    H : this.props.defaultSettings.units.H
                }
    		}
    	})
    }

    componentDidUpdate(prevProps) {
    	if(this.props.defaultSettings !== prevProps.defaultSettings) {
    		this.setState({
	    		defaultSettings : {
	    			frequency : this.props.defaultSettings.frequency.value,
                    height: this.props.defaultSettings.height,
	    			motor_poles : this.props.defaultSettings.motor_poles.value,
                    units : {
                        Q : this.props.defaultSettings.units.Q,
                        H : this.props.defaultSettings.units.H
                    }
	    		}
	    	})
    	}
    }

    _setSetting = (property, value) => {
    	this.setState({
    		defaultSettings : {
    			...this.state.defaultSettings,
    			[property] : value
    		}
    	})
    }

    _mapProperties = (item, key, unit) => {
        return (
            <option key={key} value={key}>{item.label}</option>
        )
    }

    setUnit = (e, unit) => {
        this.setState({
            ...this.state,
            defaultSettings : {
                ...this.state.defaultSettings,
                units : {
                    ...this.state.defaultSettings.units,
                    [unit] : e.target.value
                }
            }

        })
    }

    _updateSettings = () => {
    	this.props.actions.updateSettings(this.state.defaultSettings);
    	this.props.togglePopup();
    }

    render() {
        return (
           	<Popup visible={this.props.popupVisible} popup_title={__.fs('ConfiguredProducts.DefaultSettingsPopup.title')} fullscreen={true} size={'small'} backdrop={true} submitButton={this._updateSettings} togglePopup={this.props.togglePopup}>
   				<ul className="filter__popup__list">
   					{__.fs('ConfiguredProducts.DefaultSettingsPopup.frequence')} <br />
   					<li onClick={() => this._setSetting('frequency', '50Hz')} className={"filter__popup__list__item " + (this.state.defaultSettings.frequency === '50Hz' ? 'filter__popup__list__item--active' : null)}>
						<span>50Hz</span>
					</li>
					<li onClick={() => this._setSetting('frequency', '60Hz')} className={"filter__popup__list__item " + (this.state.defaultSettings.frequency === '60Hz' ? 'filter__popup__list__item--active' : null)}>
						<span>60Hz</span>
					</li>
   				</ul>
                {/*}
                <ul className="filter__popup__list">
                    {__.fs('ConfiguredProducts.DefaultSettingsPopup.height')} <br />
                    <li onClick={() => this._setSetting('height', 'feet')} className={"filter__popup__list__item " + (this.state.defaultSettings.height === 'feet' ? 'filter__popup__list__item--active' : null)}>
                        <span>Feet</span>
                    </li>
                    <li onClick={() => this._setSetting('height', 'meters')} className={"filter__popup__list__item " + (this.state.defaultSettings.height === 'meters' ? 'filter__popup__list__item--active' : null)}>
                        <span>Meters</span>
                    </li>
                </ul>*/}

   				<ul className="filter__popup__list">
   					{__.fs('ConfiguredProducts.DefaultSettingsPopup.number-of-poles')} <br />
   					<li onClick={() => this._setSetting('motor_poles', '2P')} className={"filter__popup__list__item " + (this.state.defaultSettings.motor_poles === '2P' ? 'filter__popup__list__item--active' : null)}>
						<span>2 {__.fs('ConfiguredProducts.DefaultSettingsPopup.poles')}</span>
					</li>
					<li onClick={() => this._setSetting('motor_poles', '4P')} className={"filter__popup__list__item " + (this.state.defaultSettings.motor_poles === '4P' ? 'filter__popup__list__item--active' : null)}>
						<span>4 {__.fs('ConfiguredProducts.DefaultSettingsPopup.poles')}</span>
					</li>
   				</ul>

                <ul className="filter__popup__list">
                    <li className="filter__popup__list__label">{__.fs('Settings.index.units')}</li>
                    <li className={"filter__popup__list__select "}>
                        <span>{__.fs('Settings.index.units-Q')} </span>
                        <select onChange={(e) => this.setUnit(e, 'Q')} value={this.state.defaultSettings.units.Q}>
                            { Object.keys(convertQToOtherUnit).map((key) => this._mapProperties(convertQToOtherUnit[key], key, 'Q'))}
                        </select>
                    </li>
                    <li className={"filter__popup__list__select "}>
                        <span>{__.fs('Settings.index.units-H')} </span>
                        <select onChange={(e) => this.setUnit(e, 'H')} value={this.state.defaultSettings.units.H}>
                            { Object.keys(convertHeadToOtherUnit).map((key) => this._mapProperties(convertHeadToOtherUnit[key], key, 'H'))}
                        </select>
                    </li>
                </ul>
           	</Popup>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
    	defaultSettings : state.User.default_settings
    }
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })
export default connect(mapStateToProps,mapDispatchToProps)(DefaultSettingsPopup)
