import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ListOverview from './ListOverview';
import ListDetail from './ListDetail';

const ListRouter = props => (
    <Switch>
		<Route
			exact
			path="/profile/lists"
			render={ (props) =>
                <ListOverview />
            }
		/>

		<Route
			exact
			path="/profile/lists/:id"
			render={ (props) =>
                <ListDetail />
            }
		/>
	</Switch>
);

export default ListRouter;