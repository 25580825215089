import Auth from './auth/reducer';
import User from './user/reducer';
import ConfiguredProducts from './configuredProducts/reducer';
import Filters from './filters/reducer';
import Configurations from './configurations/reducer';
import Products from './products/reducer';
import ProductLists from './productList/reducer';
import ListCategories from './listCategories/reducer';
import Message from './message/reducer';
import Layout from './layout/reducer';
import Compare from './compare/reducer';
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
	Auth,
	User,
	ConfiguredProducts,
	Filters,
	Configurations,
	Products,
	Message,
	ProductLists,
	Layout,
	ListCategories,
	Compare
});


export default rootReducer;