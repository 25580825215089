import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import FirebaseHelper from '../../helpers/firebase';
import actions from './actions';
const { rsfFirestore } = FirebaseHelper;
export function* fetchConfiguredProducts() {

	yield takeEvery(actions.FETCH_CONFIGURED_PRODUCTS, function*(action) {
		

		const snapshot = yield call(rsfFirestore.getCollection, 'configured_products');

		const configured_products = FirebaseHelper.processFireStoreCollection(snapshot);

		yield put({
		 	type: actions.SET_CONFIGURED_PRODUCTS,
		 	payload: configured_products
		})
	})
}


// export function* syncConfiguredProducts() {
	
// 	yield fork(
// 	  	rsfFirestore.syncCollection,
// 	  	firebase.firestore().collection('configured_products'),
// 		{ successActionCreator: (snap) => actions.setConfiguredProducts(FirebaseHelper.processFireStoreCollection(snap))}
// 	  )	
// }

export default function* rootSaga() {
	yield all([
		fork(fetchConfiguredProducts),
		//fork(syncConfiguredProducts)
	]);
}
 