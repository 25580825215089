import React from 'react';
import PropTypes from 'prop-types';
import __ from '../../helpers/language';
const Popup = (props) => {
    return (
    	<div className={"popup " + (props.visible ? ' popup--visible ' : '') + (props.backdrop ? ' popup--fullscreen ' : '') + (props.overflows ? ' popup--overflows' : '') + (props.draggable? ' popup--draggable ' : '')}>
          	<div className={(props.backdrop ? 'popup__backdrop' : '')}>
          		<div className={"popup__container popup__container--"+props.size}>
     				<div className="popup__header">
       					{props.popup_title}

       					<button onClick={props.togglePopup} className="popup__header__button popup__header__button--close">
       						X
       					</button>

       					{ props.submitButton ?
           					<button  className="popup__header__button popup__header__button--save" onClick={props.submitButton}>
           						{__.fs('Common.Popup.save')}
           					</button>
           				: null }
       				</div>
       				<div className="popup__body">
           				{ props.children }
           			</div>
           		</div>
            </div>
        </div>
    );
}

Popup.propTypes = {
  	popup_title: PropTypes.string.isRequired,
  	submitButton: PropTypes.func,
  	togglePopup : PropTypes.func.isRequired
};

export default Popup;
