import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {  Redirect } from 'react-router'
import  authActions  from '../../redux/auth/actions';
import messageActions from '../../redux/message/actions';
import __ from '../../helpers/language';
class RouteWrap extends Component {
	render() {
		const Component = this.props.component;

		if ( this.props.restricted ) {
			if ( !this.props.isLoggedIn ) {

				this.props.actions.setMessage({
					message: __.fs('Common.RouteWrap.no-access'),
					type: 'error'
				})
				return <Redirect to="/" />
			}
		}

		return (
			<div className="route__wrapper">
				<Component key={this.props.match.url}/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token !== null ? true : false,
        routes: state.Auth.routes
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...authActions, ...messageActions}, dispatch) })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteWrap))
