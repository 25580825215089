import React from 'react';
import Card from './Card';
import BackgroundImage from '../../assets/img/background_image.png';
import { withRouter } from 'react-router-dom';
import PageHeader from './PageHeader';
import __ from '../../helpers/language';

const HeaderWithCards = (props) => {
    return (
        <div>
            <PageHeader title={__.fs('Common.HeaderWithCards.title')} />
            <div className="container container--small">
                <div className="grid grid--xs--cols--1 grid--xs--rows--1 grid--md--cols--1 grid--md--rows--1 sub__header__overlay card__holder">
                    <Card url={'/category/1'} title={__.fs('Common.HeaderWithCards.multisteppumps')} background={BackgroundImage}/>
                    {/*<Card url={'/category/2'} title={__.fs('Common.HeaderWithCards.find-replacement')} background={BackgroundImage}/>*/}
                    {/*<Card url={'/producten'} title={__.fs('Common.HeaderWithCards.spareparts')} background={BackgroundImage}/>
                    <Card url={'/category/3'} title={__.fs('Common.HeaderWithCards.futureproducts')} background={BackgroundImage}/>*/}
                </div>
            </div>
        </div>
    );
}

export default withRouter(HeaderWithCards)
