import React from 'react';
import TC from '../Common/TC';
import { NavLink } from 'react-router-dom';

import __ from '../../helpers/language';
import ReactTooltip from 'react-tooltip';
import { round } from '../../helpers/graphCalculationHelpers';
export const getColumns = (functions) => {

    const compareTooltip =
        <ReactTooltip id="tip_add_to_compare" className='tooltip'>
            <span>Aan vergelijken toevoegen</span>
        </ReactTooltip>
    ;

	const graphTooltip =
        <ReactTooltip id="tip_graph" className='tooltip'>
            <span>Bekijk grafiek</span>
        </ReactTooltip>
    ;

	const Columns = [
    	{
			Header: '',
			expander: true,
			width: 0,
			sortable: false,
			show: false,
			resizable : false
		},
    	{
			Header: '',
			id: 'ch',
			width: 75,
			resizable : false,
			sortable: false,
			show : functions.isLoggedIn,
			className: "content-centered  border--right--none",
			Cell: props => <React.Fragment>{compareTooltip} <TC><span data-tip data-for='tip_add_to_list' className='icon__basket' onClick={(e) => functions.toggleAddToListPopup(e, props.original)}><i className="fas fa-shopping-basket"></i></span><br /><span data-tip data-for='tip_add_to_compare' className='icon__basket' onClick={(e) => functions.addToCompare(e, props.original)}><i className="fas fa-list"></i></span></TC></React.Fragment>,
		},
		{
			Header: '',
			resizable : false,
			sortable: false,
			id: 'ch',
			width: 250,
			className: "border--right--none text__color--black",
			Cell: props => <TC><span className='number'><NavLink to={'/configuration/'+functions.lowNPSHURL + '/' + (functions.buildProductString(props.original.products, '-', true))}>{functions._buildDescription(props.original.pre_filters.description, 'short_description')}</NavLink>{functions.renderExpander(props)}</span></TC>,
		},
		/*{
			Header: '',
			resizable : false,
			id: '',
			sortable: false,
			width: 75,
			Cell: props => <React.Fragment>{graphTooltip}<TC><span data-tip data-for='tip_graph' className="icon__chart" onClick={(e) => functions.toggleChartPopup(e, props.original)}> <i className="fas fa-chart-line"></i></span></TC></React.Fragment>
		},*/
		{
			Header: __.fs('ConfiguredProducts.Columns.grossPrice') + ' (€)',
			resizable : false,
			id: 'grossPrice',
			accessor: 'total_price',
			width: 100,
			className: "text__color--black text__style--bold",
			Cell: props => <TC>€ {functions.getPrice(props.value, 'bruto')}</TC>,
			sortMethod: (a, b) => {
				a = parseFloat(functions.getPrice(a, 'bruto'));
				b = parseFloat(functions.getPrice(b, 'bruto'));
				if (a === b) {
                    return 0;
                }

                return a > b ? 1 : -1;

	          }
		},
        
		{
			Header: __.fs('ConfiguredProducts.Columns.netPrice'),
			resizable : false,
			id: 'netPrice',
			show: functions.shouldDisplayNetPrice(),
			width: 100,
			accessor: 'total_price',
			Cell: props => <TC>€ {functions.getPrice(props.value, 'netto')}</TC>
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.discount'),
			resizable : false,
			show: functions.shouldDisplayNetPrice(),
			id: 'discount',
			width: 100,
			accessor: 'total_price',
			Cell: props => <TC>{functions.getDiscount(functions.getPrice(props.value, 'bruto'), functions.getPrice(props.value, 'netto'))}</TC>,
			sortMethod: (a, b) => {
				a = parseFloat(functions.getDiscount(functions.getPrice(a, 'bruto'), functions.getPrice(a, 'netto')));
				b = parseFloat(functions.getDiscount(functions.getPrice(b, 'bruto'), functions.getPrice(b, 'netto')));
				if (a === b) {
                    return 0;
                }
                return a > b ? 1 : -1;
	          }
		},
		/*{
			Header: __.fs('ConfiguredProducts.Columns.efficiency'),
			resizable : false,
			id: 'efficiency',
			width: 150,
			defaultSorted: true,
			accessor: 'general',
			Cell: props => <TC>{round(functions.effOpt, 2)}</TC>,
		},
		{
			Header: __.fs('ConfiguredProducts.Columns.pRequired'),
			resizable : false,
			id: 'prequired',
			width: 150,
			defaultSorted: true,
            accessor: 'general',
			Cell: props => <TC>{round(functions.powerOpt, 2)}</TC>,

		},
        {
			Header: __.fs('ConfiguredProducts.Columns.NPSH'),
			resizable : false,
			id: 'npshreq',
			width: 150,
			defaultSorted: true,
            accessor: 'general',
			Cell: props => <TC>{round(functions.npshOpt, 2)}</TC>,
		},*/
		{
			Header: __.fs('ConfiguredProducts.Columns.productDescription'),
			resizable : false,
			sortable: false,
			id: 'products',
			Cell: props => <TC><span className='number'>{ functions._buildDescription(props.original.pre_filters.description, 'long_description')} <br />{functions.buildProductString(props.original.products, ',')}</span></TC>
		}]
	return Columns;
}
