import './assets/css/style.css';
import React, { Component } from 'react';
import Boot from "./redux/boot";
import { Router } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from './Routes';

// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!./worker'
let instance = worker()  // `new` is optional
window.workerInstance = instance;

global.index = 'configurations_temp';
global.calcAvailableFilterProps = false;
global.appURL = 'http://dps.acceptage.nl';
global.serverURL = 'http://dps.acceptage.nl/api/public/';
global.filterOptionItem = 'applied_filters';
if ( window.location.hostname === 'localhost' ) {
	global.index = 'configurations_temp';
	global.serverURL = 'http://127.0.0.1:8000/';

	//global.filterOptionItem = 'filter_option';
}

class App extends Component {

	UNSAFE_componentWillMount() {
		Boot(store.store);
	}

	render() {
		return (
			<Provider store={store.store}>

			        <Router history={store.history}>
			            <Routes />
			        </Router>

	        </Provider>
		);
	}
}

export default App;
