import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Line } from 'recharts';
import graphCalculator from '../../helpers/graphCalc';
import Curve from './GraphComponents/Curve';
import AdjustFrequency from './GraphComponents/AdjustFrequency';
import AdjustCurves from './GraphComponents/AdjustCurves';
import { round } from '../../helpers/graphCalculationHelpers';
import { convert, convertHeadToOtherUnit, convertQToOtherUnit } from '../../helpers/conversion';
class Graphs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user_input_hz : 50,
			graphType : this.props.renderToImage ? 'systemcurve' : 'constantpressure',
			head: 0,
			headDisplayValue : 0,
			tmp_user_input_hz : 50,
            QHSeries : [],
            EffSeries : [],
            NPSHSeries : [],
            P2Series : [],
            ConstantPressure : [],
            CrossingPointConstantPressure : [],
            CrossingPointSystemCurve : [],
            DutyPoint : false,
            adjustToDutyPointFrequency : false,
            systemCurve : false,
            highestQ  : 0,
			dutyPoint : false,
			actualDutyPoint: false
		}

		this._headInterval = false;
		this._hzInterval = false;
	}

	setCalculations = () => {
        let dutyPoint = false;
        let actualCurveDutyPoint = false;
		// -- If user has reached this configuration by searching on QH, set the values so we can use them for the graphs.
		if ( sessionStorage.getItem('qhFilterValues') ) {
          	dutyPoint = JSON.parse(sessionStorage.getItem('qhFilterValues'));
          	dutyPoint = {
          		q : dutyPoint.Q,
          		h : dutyPoint.H
          	}


        }else {
			//Testing Purposes -- Set a dutypoint so that we don't have to go through filters everytime
			/*if ( this.props.singleConfiguration.pre_filters ) {
                dutyPoint = {
					h : parseFloat(this.props.singleConfiguration.pre_filters.PRE_FILTER['Nominal discharge head (fixed)']),
					q: parseFloat(this.props.singleConfiguration.pre_filters.PRE_FILTER['Nominal flow (fixed)'])
				}
			}*/

			actualCurveDutyPoint = {
				h : parseFloat(this.props.preconfig.pre_filters.PRE_FILTER['Nominal discharge head (fixed)']),
				q: parseFloat(this.props.preconfig.pre_filters.PRE_FILTER['Nominal flow (fixed)'])
			}
		}

		let graphType = (this.props.match.params.type === 'ln' ? 'low_npsh' : 'general');

		if ( this.props.low_npsh ) {
			if ( this.props.low_npsh === true ) {
				graphType = 'low_npsh';
			}
		}


		const calculator = new graphCalculator(this.props.preconfig, this.state.user_input_hz, dutyPoint, this.state.head, this.props.defaultSettings.units_distance, graphType );
		let actualDutyPoint = false;

		if ( dutyPoint ) {
			if ( this.state.graphType === 'systemcurve' ) {
				actualDutyPoint = calculator._getVariableQHSystemCurveIntersection(true)
			}else {
				actualDutyPoint = calculator._getVariableQHConstantPressureIntersection(true)
			}

			if ( !actualDutyPoint.length ) {
				actualDutyPoint = false;
			} else {
				actualDutyPoint = actualDutyPoint[0];
				actualCurveDutyPoint = actualDutyPoint
			}
		}

        this.setState({
            ...this.state,
            EffSeries : calculator.Efficiency._getEffSeries(actualDutyPoint),
            QHSeries : calculator.QH._getQHSeries(),
            NPSHSeries : calculator.NPSH._getNPSHSeries(actualDutyPoint),
            P2Series : calculator.P2._getPowerSeries(actualDutyPoint),
            ConstantPressure : calculator._getConstantPressure(),
            CrossingPointConstantPressure :  calculator._getVariableQHConstantPressureIntersection(),
            CrossingPointSystemCurve : calculator._getVariableQHSystemCurveIntersection(),
            DutyPoint : calculator._getDutyPoint(),
            adjustToDutyPointFrequency : calculator._getAdjustToDutyPointFrequency(),
            systemCurve : calculator._getSystemCurve(),
            highestQ : Math.ceil( parseFloat( calculator.QH._getHighestQ() ) ),
            highestH : Math.ceil( parseFloat( calculator.QH._getHighestH() ) / 10 ) * 10,
            dutyPoint : dutyPoint,
            actualCurveDutyPoint : actualCurveDutyPoint
        })


	}

    componentDidUpdate(prevProps, prevState) {
        if ( this.props.renderToImage ) {
            if ( this.state.QHSeries.length && this.state.P2Series.length && this.state.EffSeries.length && this.state.NPSHSeries.length) {
                this.props.renderToImage();
            }
        }

        if ( this.state.graphType !== prevState.graphType || this.props.preconfig !== prevProps.preconfig || this.state.user_input_hz !== prevState.user_input_hz || this.state.head !== prevState.head || this.props.defaultSettings.height !== prevProps.defaultSettings.height) {
            if(this.props.preconfig) {
    			this.setCalculations();
    		}
        }
    }

    _setHzState = (e) => {
    	if(e.target.value > 9) {

			const maxHz = 60;
			let minHz = 30;
			if(this.state.user_input_hz === 60) {
				minHz = 35;
			}

			let userInputHz = e.target.value;

			if(userInputHz < minHz)
				userInputHz = minHz;

			if(userInputHz > maxHz)
				userInputHz = maxHz;

			this.setState({
				user_input_hz : parseFloat(userInputHz),
				tmp_user_input_hz : parseFloat(userInputHz),
			})
		}
    }

	_changeInput = (e) => {

		clearInterval(this._hzInterval);
		e.persist();
		this._hzInterval = setInterval(() => this._setHzState(e), 750);

		this.setState({
			tmp_user_input_hz : parseFloat(e.target.value),
		})


	}

	_setHeadState = (e) => {
		let value = parseFloat(e.target.value);

		if ( value < 0 ) {
			value = 0;
		}

		let compareValue = convert(value, this.props.defaultSettings.units_distance, 'h');

		if ( compareValue > this.state.dutyPoint.h ) {
			value = round(convert(this.state.dutyPoint.h, this.props.defaultSettings.units_distance, 'h'));
		} else {
			value = convert(value, this.props.defaultSettings.units_distance, 'h');
		}

		this.setState({
			head : value
		})


	}

	_changeHead = (e) => {

		clearInterval(this._headInterval);
		e.persist();
		this._headInterval = setInterval(() => this._setHeadState(e), 750);

		let value = parseFloat(e.target.value);
		if ( value < 0 ) {
			value = 0;
		}

		let compareValue = convert(value, this.props.defaultSettings.units_distance, 'h');

		if ( compareValue > this.state.dutyPoint.h ) {
			value = round(convert(this.state.dutyPoint.h, this.props.defaultSettings.units_distance, 'h'));
		}
		this.setState({
			headDisplayValue : value
		})

	}


    _adjustToDutypoint = (frequence) => {
    	this.setState({
    		...this.state,
    		user_input_hz : frequence,
    		tmp_user_input_hz : frequence
    	})
    }

    componentDidMount() {
		console.log(this.props);
        if ( this.props.setTitle ) {
            this.props.setTitle('Grafieken');
        }

        if(this.props.preconfig) {
            this.setCalculations();
        }
    }

    _renderSearchedByQHCurves = () => {
	  	let lines = [];
	  	lines.push(this.state.DutyPoint.map(s => (
			<Line yAxisId="left" type={s.type} isAnimationActive={true} dataKey="h" stroke={s.stroke} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name + 'leftcrossing'} />
		)));

		if ( this.state.graphType === 'constantpressure') {
			lines.push(this.state.CrossingPointConstantPressure.map(s => (
				<Line yAxisId="left" type={s.type} isAnimationActive={true} dataKey="h" stroke={s.stroke} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name + 'left'} />
			)));
			lines.push(this.state.ConstantPressure.map(s => (
				<Line yAxisId="left" type={s.type} isAnimationActive={true} dataKey="h" stroke={s.stroke} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name + 'right'} />
			)));
		}

		if ( this.state.graphType === 'systemcurve') {
			lines.push(this.state.CrossingPointSystemCurve.map(s => (
				<Line yAxisId="left" type={s.type} isAnimationActive={true} dataKey="h" stroke={s.stroke} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name + 'left'} />
			)));
			lines.push(this.state.systemCurve.map(s => (
				<Line yAxisId="left" type={s.type} isAnimationActive={true} dataKey="h" stroke={s.stroke} data={s.data} name={s.name} dot={s.dot} activeDot={s.activeDot} key={s.name + 'right'} />
			)));
		}

	  	return lines;
    }

    _setGraphType = (type) => {
        this.setState({
            graphType : type
        })
    }



    render() {


        return (
          	<div className="graphs-wrapper">
                { !this.props.hideAdjust ? <AdjustFrequency changeInput={this._changeInput} defaultValue={this.state.tmp_user_input_hz} /> : null }

	  			<div className="curve__grid">
	  				<div className={"curve__grid__curve curve__grid__curve--hydraulic " + ((this.state.dutyPoint && !this.props.compare) ? 'curve__grid__curve curve__grid__curve--hydraulic--has-dutypoint' : null)}>
						{(this.state.DutyPoint !== false && !this.props.hideAdjust ) ? <AdjustCurves head={this.state.headDisplayValue} adjustToDutypoint={this._adjustToDutypoint} adjustToDutyPointFrequency={this.state.adjustToDutyPointFrequency} dutyPoint={this.state.DutyPoint} graphType={this.state.graphType} setGraphType={this._setGraphType} changeHead={this._changeHead}  /> : null }
                        <div className="curve-inner-grid">
	                        <div className={ (this.state.dutyPoint ? 'curve curve--right-box' : null)}>
	                        	<Curve
	                        		convertX={{type: this.props.defaultSettings.units.Q, unit : 'q'}}
	                        		convertY={{type: this.props.defaultSettings.units.H, unit: 'h'}}
	                        		dutypoint={this.state.dutyPoint}
	                        		actualDutyPoint={this.state.actualCurveDutyPoint}
	                        		compare={this.props.compare}
									popup={this.props.popup}
	                        		title="Q / Opvoerhoogte"
	                        		series={this.state.QHSeries}
	                        		xDataKey="q"
	                        		yDataKey="h"
	                        		xLabel={'Q ' + '['+ convertQToOtherUnit[this.props.defaultSettings.units.Q].label + ']'}
	                        		yLabel={'H ' + '['+ convertHeadToOtherUnit[this.props.defaultSettings.units.H].label + ']'}
	                        		highestY={this.state.highestH}
	                        		highestQ={this.state.highestQ}
	                        		renderSearchedByQHCurves={((this.state.DutyPoint !== false) ? this._renderSearchedByQHCurves : false )}
	                        	/>
	                    	</div>
	                    	{ (this.state.dutyPoint && !this.props.compare ) ? <div className="dutypoint-box">
	                    		<div className="dutypoint-box__inner">
	                    			<span>Req. Duty Pt.</span>
		                    		Q: { round(convert(this.state.dutyPoint.q, this.props.defaultSettings.units.Q, 'q'), 2)} <br />
		                    		H: { round(convert(this.state.dutyPoint.h, this.props.defaultSettings.units.H, 'h'), 2)}
		                    	</div>
	                    	</div> : null }
	                    </div>
                    </div>

					<div className="curve__grid__curve curve__grid__curve--hydraulic" style={{marginTop: '50px'}}>
                        {<Curve
                        	yLabel={'H ' + '['+ convertHeadToOtherUnit[this.props.defaultSettings.units.H].label + ']'}
                        	convertX={{type: this.props.defaultSettings.units.Q, unit : 'q'}}
                        	convertY={{type: this.props.defaultSettings.units.H, unit: 'h'}}
                        	compare={this.props.compare}
                        	title="Q / NPSHr"
							popup={this.props.popup}
                        	series={this.state.NPSHSeries}
                        	xDataKey="npsh_q"
                        	yDataKey="npsh_h"
                        	xLabel={'Q ' + '['+ convertQToOtherUnit[this.props.defaultSettings.units.Q].label + ']'}
                        	highestQ={this.state.highestQ}
                        	renderSearchedByQHCurves={false}
                        />}
					</div>

					<div className="curve__grid__curve curve__grid__curve--hydraulic" style={{marginTop: '50px'}}>
						{<Curve
                        	convertX={{type: this.props.defaultSettings.units.Q, unit : 'q'}}
                        	compare={this.props.compare}
                        	title="Q / Hydr. Efficientie"
                        	series={this.state.EffSeries}
                        	xDataKey="q"
							popup={this.props.popup}
                        	yDataKey="eff"
                        	xLabel={'Q ' + '['+ convertQToOtherUnit[this.props.defaultSettings.units.Q].label + ']'}
                        	yLabel="Efficiency [%]"
                        	highestQ={this.state.highestQ}
                        	renderSearchedByQHCurves={false}
                        />}
					</div>

					<div className="curve__grid__curve curve__grid__curve--hydraulic" style={{marginTop: '50px'}}>
                        {<Curve
                        	convertX={{type: this.props.defaultSettings.units.Q, unit : 'q'}}
                        	compare={this.props.compare}
                        	title="Q / Gevraagd vermogen (P2)"
                        	series={this.state.P2Series}
                        	xDataKey="q"
							popup={this.props.popup}
                        	yDataKey="p"
                        	xLabel={'Q ' + '['+ convertQToOtherUnit[this.props.defaultSettings.units.Q].label + ']'}
                        	yLabel="P [kW]"
                        	highestQ={this.state.highestQ}
                        	renderSearchedByQHCurves={false}
                        />}
					</div>

				</div>

			</div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
    	defaultSettings : state.User.default_settings,
    }
}

export default withRouter(connect(mapStateToProps)(Graphs))
