import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import configuredProductSaga from './configuredProducts/saga';
import filterSaga from './filters/saga';
import configurationSaga from './configurations/saga';
import productsSaga from './products/saga';
import productListSaga from './productList/saga';
import layoutSaga from './layout/saga';
import listCategoriesSaga from './listCategories/saga';
import compareSaga from './compare/saga';
import priceRequestSaga from './priceRequests/saga';
import orderList from './orderList/saga';
export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		userSagas(),
		configuredProductSaga(),
		filterSaga(),
		configurationSaga(),
		productsSaga(),
		productListSaga(),
		layoutSaga(),
		listCategoriesSaga(),
		compareSaga(),
		priceRequestSaga(),
		orderList()
	]);
}
