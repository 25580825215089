import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as Sentry from '@sentry/browser';

if ( window.location.hostname !== 'localhost' ) {
	Sentry.init({dsn: "https://bc0b1885ec944bb7ae5dbe60abca1bf2@sentry.io/1495334"});
}
ReactDOM.render(<App />, document.getElementById('root'));

window.onbeforeunload = function() {
   //localStorage.clear();
}