import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { getToken } from '../../helpers/utility';
import actions from './actions';
import __ from '../../helpers/language';
import messageActions from '../message/actions';
import FirebaseHelper from '../../helpers/firebase';
import moment from 'moment';

const { rsfFirestore, firebase } = FirebaseHelper;

function userLoggedIn() {
	const localUserData = getToken();
	const user_id = localUserData.get('userId');
	return user_id;
}

export function* addPriceRequest() {
	yield takeEvery(actions.ADD_PRICE_REQUEST, function*(action) {
		const { type, address, comments, listId } = action.payload;

		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		const user_profile = localUserData.get('userProfile');

		const email = user_profile.email;
		const name = user_profile.displayName;

		if(user_id){
			let priceRequestData = {
				type,
				address,
				comments,
				listId,
				user_id,
				email,
				name,
				status: 'open',
				dateCreated: moment().format()
			}

			const newPriceRequest = yield call(rsfFirestore.addDocument, 'price_requests', priceRequestData);

			// if price request is succesfully added
			if(newPriceRequest.id){

				priceRequestData["request_id"] = newPriceRequest.id;
				const snapshot = yield call(rsfFirestore.getDocument, 'users/'+user_id);
				const user = snapshot.data();

				priceRequestData["user"] = {userProfileData : user.user_profile_data, name: user.name};

				yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'locked', true);
				yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'price_request_id', newPriceRequest.id);
				yield call(rsfFirestore.updateDocument, 'product_lists/' + listId, 'can_be_ordered', false);

				const data = JSON.stringify(priceRequestData);
				try {
					yield call(fetch, global.serverURL +'price_request/email', {
						'method': 'POST',
						body: data
					});

					yield put({
					  type: messageActions.MESSAGE,
					  payload: {
						  message: __.fs('redux.priceRequests.saga.price-request-received-successfully'),
						  type: 'success'
					  }
				  });
				}catch(error) {
					yield put({
					  type: messageActions.MESSAGE,
					  payload: {
						  message: __.fs('redux.priceRequests.saga.something-went-wrong'),
						  type: 'error'
					  }
				  });
				}

			}



		}

	});
}

export function* addPriceRequestSingle() {
	yield takeEvery(actions.ADD_PRICE_REQUEST_SINGLE, function*(action) {
		const { type, address, comments, configuration, email, name, company, phone } = action.payload;


		let priceRequestData = {
			type,
			address,
			comments,
			company,
			email,
			name,
			phone,
			configuration,
			status: 'open',
			dateCreated: moment().format()
		}

		try {
			yield call(fetch, global.serverURL +'price_request_single/email', {
	        	'method': 'POST',
	        	body: JSON.stringify(priceRequestData)
	        });


		    yield put({
				type: messageActions.MESSAGE,
				payload: {
					message: __.fs('redux.priceRequests.saga.price-request-received-successfully'),
					type: 'success'
				}
		    });
		}
		catch(error) {
			yield put({
			  type: messageActions.MESSAGE,
			  payload: {
				  message: __.fs('redux.priceRequests.saga.something-went-wrong'),
				  type: 'error'
			  }
		  });
		}



	});
}


export function* syncPriceRequestsCollection() {
	const user_id = userLoggedIn();

	if(user_id){

		yield fork(
		  	rsfFirestore.syncCollection,
		  	firebase.firestore().collection('price_requests').where('user_id', '==', user_id),
			{ successActionCreator: (snap) => actions.setListCategories(FirebaseHelper.processFireStoreCollection(snap))}
		)
	}

}

export default function* rootSaga() {
	yield all([
		fork(addPriceRequest),
		fork(addPriceRequestSingle)
		//fork(syncPriceRequestsCollection)
	]);
}
