import React, { Component } from 'react';
import Popup from '../Common/Popup';
import { findExistingFilterIndex, rebuildFilterArray } from '../../helpers/utility';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import userActions from '../../redux/user/actions';
import messageActions from '../../redux/message/actions';
import { connect } from 'react-redux';
import __ from '../../helpers/language';

class PresetPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
        	activeFilters : false,
        	groupName : 'Filterset - ' + moment().format("DD/MM/YYYY H:mm")
        }
    }

    componentWillMount() {
    	this.setState({
    		activeFilters : this.props.filters
    	})
    }

    _filterClicked = (event, filterObject) => {
    	event.preventDefault();
    	let activeFilters = [...this.state.activeFilters];
		if(activeFilters) {
    		const filterIndex = findExistingFilterIndex(filterObject, [...this.state.activeFilters]);

    		if ( filterIndex === -1 ) {
    			activeFilters.push(filterObject)
    		} else {
    			activeFilters = rebuildFilterArray(activeFilters, filterIndex);
    		}
		}

		if( activeFilters.length <= 0 ) {
			this.props.togglePopup();
		}

		this.setState({
			activeFilters : activeFilters
		})
    }

     _renderPresetFilters(item, key) {
    	return (<li key={key}><span>{item.category}</span> - {item.property}  <i className="fas fa-times" onClick={(event) => this._filterClicked(event, item)}></i></li>)
    }

    _changeGroupName = (e) => {
    	this.setState({
    		...this.state,
    		groupName : e.target.value
    	})
    }

    _saveFilterPopupProperties = (e) => {
    	if ( this.state.groupName.replace(' ', '') === '' ) {
            this.props.actions.setMessage({
                message: __.fs('Filters.PresetPopup.name-required'),
                type: 'error'
            })
    	} else {
    		this.props.actions.addFilterGroup(this.state);
    		this.props.togglePopup();
    	}
    }

    render() {
        return (
        	<Popup visible={this.props.popupOpen} popup_title={__.fs('Filters.PresetPopup.save-filter')} fullscreen={true} size={'large'} backdrop={true} submitButton={this._saveFilterPopupProperties} togglePopup={this.props.togglePopup}>
   				<div className="input input--small">
   					<label className="input__label">{__.fs('Filters.PresetPopup.name')}</label>
   					<input className="input__field input__field--wide" type="text" value={this.state.groupName} onChange={this._changeGroupName} />
   				</div>

        		<ul className="preset__filters__list">{this.state.activeFilters ? this.state.activeFilters.map((filter) => this._renderPresetFilters(filter)) : null}</ul>

	      	</Popup>
        );
    }

}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({...userActions, ...messageActions}, dispatch) })

export default connect(false,mapDispatchToProps)(PresetPopup)
