import actions from './actions';

const initState = { configurations : {} };

export default function compareReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_COMPARE_ITEMS:
			return {
				...state,
				configurations : action.payload
			};
		case 'LOGOUT' : 
			return initState
		default:
			return state;
	}
}
