import React from 'react';
import BackgroundImage from '../../assets/img/background_image.png';
import { withRouter } from 'react-router-dom';

const PageHeaderWithContent = (props) => {
    return (
    	<div className={"sub__header sub__header--with-content " + (props.alignCenter ? 'sub__header--align-center' : null)} style={{backgroundImage: `url(${BackgroundImage})`}}>
			<h1>{props.title}</h1>
			{props.children}
		</div>
    );
}

export default withRouter(PageHeaderWithContent)
