import React, { Component } from 'react';
import Popup from '../Common/Popup';
import { rebuildFilterArray } from '../../helpers/utility';
import userActions from '../../redux/user/actions';
import { connect } from 'react-redux';
import __ from '../../helpers/language';
const { setFilterGroups } = userActions; 

class PresetManagePopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
        	filterGroups : [],
        	areYouSure : false
        }
    }

    componentDidMount() {
    	this.setState({
    		filterGroups : this.props.filterGroups
    	})
    }

    _removeGroup = (e, key, doRemoval = false) => {
    	e.preventDefault();

    	if ( !doRemoval ) {

    		this.setState({
    			areYouSure : false
    		})
    		return true;
    	}

    	const filterGroups = rebuildFilterArray(this.state.filterGroups, key);

    	this.setState({
    		filterGroups,
    		areYouSure : false
    	})

    	this.props.setFilterGroups(filterGroups);


    	if ( !filterGroups.length ) {
    		this.props.togglePopup();
    	}

    }

    _checkRemoveGroup = (e, key) => {
    	this.setState({
    		areYouSure : key
    	})
    }

    _renderFilterGroup = (item, key) => {
    	return (
    		<li key={key}>
    			<span>{item.groupName}</span> <i className="fas fa-trash" onClick={(event) => this._checkRemoveGroup(event, key)}></i>

    			{this.state.areYouSure === key ? 
    				<div className="are-you-sure">
    					<span className="are-you-sure__question">{__.fs('Filters.PresetManagePopup.delete')}?</span>
    					<span className="are-you-sure__button are-you-sure__button--yes" onClick={(event) => this._removeGroup(event, key, true)}>{__.fs('Filters.PresetManagePopup.yes')}</span>
    					<span className="are-you-sure__button are-you-sure__button--no" onClick={(event) => this._removeGroup(event, key)}>{__.fs('Filters.PresetManagePopup.no')}</span>
    				</div>
    			: null
    			}
    		</li>)
    }
    
    render() {
        return (
        	<Popup visible={this.props.popupOpen} popup_title={__.fs('Filters.PresetManagePopup.removeFilterSets')} fullscreen={true} size={'large'} backdrop={true} submitButton={false} togglePopup={this.props.togglePopup}>
        		<ul className="preset__filters__list">{this.state.filterGroups ? this.state.filterGroups.map(this._renderFilterGroup) : null}</ul>
	      	</Popup>       
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
    	filterGroups : state.User.filter_groups
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    	setFilterGroups: filterGroups => dispatch(setFilterGroups(filterGroups)), 
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(PresetManagePopup)