import actions from './actions';
import { REHYDRATE } from 'redux-persist/lib/constants';
import filters from './output.json';
const initState = { configurations: { 'preconfig' : [], 'configurations' : []}, single_configuration : {}, full_filter_tree : filters, preconfig_configurations : [] };


export default function configurationReducer(state = initState, action) {
	switch (action.type) {
		 case REHYDRATE:
           return action.payload ? action.payload.configurationReducer ? action.payload.configurationReducer : state : state;
		case actions.SET_CONFIGURATIONS:
			return {
				...state,
				configurations : {
					...state.configurations,
					[action.payload.setToField] : [
						...state.configurations[action.payload.setToField],
						...action.payload.configurations
					]

				}
			};
		case actions.SET_PRECONFIG_CONFIGURATIONS:
			return {
				...state,
				preconfig_configurations : action.payload
			}
		case actions.SET_FULL_FILTER_TREE:
			return {
				...state,
				full_filter_tree : action.payload
			}
		case actions.REMOVE_SINGLE_CONFIGURATION:
			return {
				...state,
				single_configuration : {}
			}
		case actions.SET_SINGLE_CONFIGURATION:

			return {
				...state,
				single_configuration: action.payload
			}

		case actions.RESET_CONFIGURATIONS:
			return initState;


		case actions.ADD_CONFIGURATIONS:
			return {
				...state,
				configurations : [
					...state.configurations,
					...action.payload
				]
			};

		default:
			return state;
	}
}
