import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NestedFilterPopup from './NestedFilterPopup';
import PresetPopup from './PresetPopup';
import PresetManagePopup from './PresetManagePopup';
import { rebuildFilterArray, findExistingFilterIndex } from '../../helpers/utility';
import actions from '../../redux/user/actions';
import { bindActionCreators } from 'redux';
import __ from '../../helpers/language';

import ReactTooltip from 'react-tooltip';

class Filters extends Component {

    constructor(props) {
        super(props)

        this.state = {
        	configurations: [],
        	activeFilters: [],
        	availableFilters : [],
        	presetFilterPopupOpen : false,
        	presetFilterManagePopup : false,
        	openedItem : false,
        	activeFilterGroup : false,
        }
    }

    componentDidMount() {
    	this.setState({configurations: this.props.configurations})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.configurations !== this.props.configurations) {
        	this.setState({configurations: this.props.configurations})
        }
    }

    _openDropdown = (e, key) => {
    	e.preventDefault();
    	let openedItem = key;
    	if(this.state.openedItem === key) {
    		openedItem = false;
    	}
    	this.setState({
    		openedItem
    	})
    }

    _openNestedFilterPopup(e, filter, item) {
    	e.preventDefault();

        let title = item;
        if ( filter.hasOwnProperty('groupName') ) {
            title = filter.groupName;
        }
    	this.setState({
    		...this.state,
    		nestedPopupOpen: true,
    		nestedFilterPopup : {
    			class: 'popup--visible',
    			values : filter,
    			title : item,
                headerTitle : title
    		}
    	})
    }

    _closeNestedFilterPopup = () => {

    	this.setState({
    		...this.state,
    		nestedPopupOpen: false,
    		nestedFilterPopup : {
    			class: '',
    			values : false,
    			title : false,
    		}
    	})
    }

    _findDeepestAndMapPropertiesToNumber = (title, filter ) => {
		
    	let numTotalFilters = 0;
    	numTotalFilters = parseInt(Object.keys(filter).map((filterProp) => this._loopNestedFilters(filterProp, filter)));
    	return numTotalFilters;
    }

    _loopNestedFilters = (filterProp, filter, numResults = 0) => {
		
    	for ( let property in filter ) {
    		if ( filter.hasOwnProperty( property ) ) {
    			if ( filter[property].filterable ) {
    				if ( this._findNumFilterAvailable(filter[property].path.join('.') + '.' + property) > 0 ) {
    					numResults ++;
    				}
    			} else {
    				numResults += parseInt(Object.keys(filter[property]).map((filterProp) => this._loopNestedFilters(filterProp, filter[property])));

    			}
    		}
    	}

    	return numResults;
    }

    _renderAvailableFilters = (item, key) => {

    	let currentFilter = this.props.filterTree[item];
    	let itemLength = Object.keys(currentFilter).length;
    	let shouldRenderList = true;
    	let parentClass = '';
    	let numProperties = 0;
    	let onClick = (e) => this._openDropdown(e, key);
    	// -- If the item length is larger than two, we'll need to render a popup.
    	if ( itemLength > 2 ) {
    		shouldRenderList = false;
    		parentClass = 'filters__list__category--clickable'
    		onClick = (e) => this._openNestedFilterPopup(e, currentFilter, item);
    	} else {
    		// -- If it's smaller than 2, we'll need to check if it's a nested filter or single-level
    		for ( const property in currentFilter ) {
    			if ( currentFilter.hasOwnProperty( property ) ) {
    				if ( currentFilter[property]['filterable'] === true || currentFilter[property]['filterable'] === false) {
    					shouldRenderList = true;
    				} else {
    					shouldRenderList = false;
    				}
    			}
    		}
    	}
		
		
    	if( shouldRenderList === false ) {
    		parentClass = 'filters__list__category--clickable'
            if ( global.calcAvailableFilterProps ) {
        		numProperties = this._findDeepestAndMapPropertiesToNumber(item, currentFilter );
            }
    		onClick = (e) => this._openNestedFilterPopup(e, currentFilter, item);
    	} else {
            if ( global.calcAvailableFilterProps ) {
    		  numProperties += parseInt(Object.keys(currentFilter).map((filterProp) => this._mapFilterPropertiesToNumber(item, filterProp, currentFilter)))
            }
    	}

  		let openedClass = '';
  		if ( this.state.openedItem === key ) {
  			openedClass = 'filters__list__category--opened';
  		}

        let strNumProperties = '';
        if ( global.calcAvailableFilterProps ) {
            strNumProperties = ' (' + numProperties + ')';
        }
        let title = item;
        if ( currentFilter.groupName ) {
            title = currentFilter.groupName;
        }
		return (
    		<li filtergrouptitle={title} onClick={onClick} className={"filters__list__category " + parentClass + ' ' + openedClass} key={key}>{title}{strNumProperties}<i className="fas fa-arrow-down"></i>
    			{shouldRenderList ?
    				 <ul>						 
    				 	{Object.keys(currentFilter).map((filterProp) => this._mapFilterProperties(item, filterProp, currentFilter))}
    				 </ul>
    			: null }
    		</li>
    	)

    }

    _makeFilterObject = (category, property, path, filterable) => {
    	return {
    		path : path.join('.'),
    		arrPath : path,
    		category : category,
    		property : property,
    		filterable : filterable
    	}
    }

    _mapFilterProperties = (parent, item, currentFilter) => {
	
    	const filterObject = this._makeFilterObject(parent, item, currentFilter[item].path, currentFilter[item].filterable);
    	let activeClass = 'filters__list__item--active';
    	const filterIndex = findExistingFilterIndex(filterObject, [...this.state.activeFilters]);
    	let numFiltersAvailable = 0;
    	if(filterIndex === -1){
    		activeClass = '';
    	}

    	let greyedOutClass = '';
    	if ( !filterObject.filterable ) {
    		greyedOutClass = 'filters__list__item--greyed';
    	}


    	if ( !this._findFilterIsAvailable(filterObject.path + '.' + filterObject.property)) {
    		greyedOutClass = 'filters__list__item--greyed';
    	} else {
    		numFiltersAvailable = this._findNumFilterAvailable(filterObject.path + '.' + filterObject.property);

    	}


    	return (
    		<li key={item} className={"filters__list__item " + activeClass  + ' ' + greyedOutClass}>
				<a data-parent={parent} data-child={item} onClick={(e) => this._filterClicked(e, filterObject)} href="/#">{item} ({numFiltersAvailable})</a>
			</li>
    	)

    }

    _mapFilterPropertiesToNumber = (parent, item, currentFilter) => {
    	const filterObject = this._makeFilterObject(parent, item, currentFilter[item].path, currentFilter[item].filterable);
    	let numFiltersAvailable = 0;
    	if ( this._findFilterIsAvailable(filterObject.path + '.' + filterObject.property)) {
    		numFiltersAvailable++;
    	}
    	return numFiltersAvailable;
    }

    	_findFilterIsAvailable ( fullFilterPath ) {
		
		// -- fix for WRAS/ACS
		if(fullFilterPath == "Drinking water approval.WRAS/ACS")
		{
		
			fullFilterPath = 'Drinking water approval.WRAS\\/ACS';
		}

    	for ( let i = 0; i <= this.state.configurations.length; i++ ) {
    		if ( this.state.configurations[i] ) {

    			if ( this.state.configurations[i][global.filterOptionItem].includes(fullFilterPath) ) {
					return true;
				}
    		}
    	}

    	return false;
    }

    _findNumFilterAvailable ( fullFilterPath, configurations = false, fromNested = false ) {
		
    	let configurationsToUse = configurations;
    	if ( !configurations ) {
    		configurationsToUse = this.state.configurations;
    	}
		
		// -- fix for WRAS/ACS
		if(fullFilterPath == "Drinking water approval.WRAS/ACS")
		{
		
			fullFilterPath = 'Drinking water approval.WRAS\\/ACS';
		}

    	let results = 0;
    	for ( let i = 0; i <= configurationsToUse.length; i++ ) {
    		if ( configurationsToUse[i] ) {
				
    			if ( configurationsToUse[i][global.filterOptionItem].includes(fullFilterPath)) {
					results ++;
				}
    		}
    	}

    	return results;
    }

    _filterClicked = (event, filterObject) => {
    	event.preventDefault();

    	let activeFilters = [...this.state.activeFilters];

    	if ( activeFilters ) {
    		// -- Find current path + value in active filters, if exists => remove it.

    		const filterIndex = findExistingFilterIndex(filterObject, [...this.state.activeFilters]);

    		if ( filterIndex === -1 ) {
    			activeFilters.push(filterObject)
    		} else {
    			activeFilters = rebuildFilterArray(activeFilters, filterIndex);
    		}
    	}
    	this.setState({
			...this.state,
			activeFilterGroup : false
		})
    	this._setActiveFilters(activeFilters);
    }

    _setActiveFilters = (activeFilters) => {
    	this.setState({
    		activeFilters : activeFilters
    	})
    	this.props.filterConfigurations(activeFilters);
    }

    _renderFilterBreadcrumbs(item, key) {

        let tooltip = "";

        if(item.nestedArrPath){
            tooltip =
            (<ReactTooltip key={key + 'tip'} id={key + 'tip'} className='tooltip'>
                <span>{item.nestedArrPath.join(" - ")}</span>
            </ReactTooltip>);
        }

    	return (<React.Fragment key={key + 'fragment'}>{tooltip}<li key={key + 'notext'} data-tip data-for={key + 'tip'}><span>{item.category}</span> - {item.property}  <i className="fas fa-times" onClick={(event) => this._filterClicked(event, item)}></i></li></React.Fragment>)
    }

    _renderPreFilterBreadcrumbs = () => {

        if ( this.props.match.params.filterType === 'filter' ) {

            return (
                <React.Fragment>

                    <li  data-tip data-for={'prefilter-tip'}>
                        <span>{__.fs('Filters.index.numberOfStages')}</span> - {this.props.match.params.numberOfStages}
                    </li>
                    <li  data-tip data-for={'prefilter-tip'}>
                        <span>{__.fs('Filters.index.size')}</span> - {this.props.match.params.size}
                    </li>
                </React.Fragment>
            )
        } else {
            
            return (
                <React.Fragment>

                    <li  data-tip data-for={'prefilter-tip'}>
                        <span>{__.fs('Filters.index.q')}</span> - {this.props.match.params.Q}
                    </li>
                    <li  data-tip data-for={'prefilter-tip'}>
                        <span>{__.fs('Filters.index.h')}</span> - {this.props.match.params.H} (+ {this.props.match.params.plus}%, - {this.props.match.params.minus}%)
                    </li>
                </React.Fragment>
            )
        }
    }

    _togglePresetFilterPopup = () => {
    	this.setState({
    		...this.state,
    		presetFilterPopupOpen : !this.state.presetFilterPopupOpen
    	})
    }

    _togglePresetFilterManagePopup = () => {
    	this.setState({
    		...this.state,
    		presetFilterManagePopup : !this.state.presetFilterManagePopup
    	})
    }

      _mapFilterGroups = (item, key) => {
    	//const result = this.props.filterConfigurations(item.activeFilters, false);

    	let activeClass = '';
    	if ( this.state.activeFilterGroup !== false ) {
    		if( this.state.activeFilterGroup === key ) {
    			activeClass = 'filters__list__item--active';
    		}
    	}


		return (
    		<li key={key} className={"filters__list__item " + activeClass }>
				<a onClick={(event) => this._filterGroupClicked(key, item.activeFilters)} href="/#">{item.groupName}</a>
			</li>
    	)
    }

      _filterGroupClicked = (filterGroup, activeFilters) => {

    	this.setState({
    		activeFilterGroup : filterGroup
    	})

    	this._setActiveFilters(activeFilters)


    }

    _renderPresetFilters = () => {

    	let onClick = (e) => this._openDropdown(e, 'pre-select-filters');

    	let openedClass = '';
  		if ( this.state.openedItem === 'pre-select-filters' ) {
  			openedClass = 'filters__list__category--opened';
  		}

  		if ( !this.props.filterGroups.length ) {
  			return null;
  		}

    	return (
    		<li onClick={onClick} className={"filters__list__category " + openedClass} key={'presets'}>{__.fs('Filters.index.presets')}<i className="fas fa-arrow-down"></i>
    			<ul className="filters__input--grid filters__input--grid--2 filters__input--grid--2--wider">
    				{this.props.filterGroups ? this.props.filterGroups.map(this._mapFilterGroups) : null}
    			</ul>
    		</li>
    	)
    }

    _reorderFilters = (filters) => {

        if ( !filters.length ) {
            return [];
        }
        let orderedFilters = [];

        const filterGroupOrder = [
            'Drinking water approval',
            'Material wetted parts',
            'Temperature & Pressure',
            'Connection',
            'Sealing',
            'Plug',
            'Without motor',
            'Improved motor effeciency (IE5)',
            'Motor voltage',
            'Increased motor power',
            'Motor temperature sensor (PTC)'

        ];

        for ( let i = 0; i <= filterGroupOrder.length; i++ ) {
            if ( filterGroupOrder[i] ) {
                for ( let j = 0; j <= filters.length; j++ ) {
                    if ( filters[j] ) {
                        if ( filters[j].props.filtergrouptitle === filterGroupOrder[i] ) {
                            orderedFilters.push(filters[j]);
                        }
                    }
                }
            }
        }



        return orderedFilters;
    }

    _clearFilters = () => {
        this.setState({
            activeFilters : [],
        })

        this.props.filterConfigurations([]);
    }
    render() {


        let availableFilters = (this.props.filterTree ? Object.keys(this.props.filterTree).map(this._renderAvailableFilters) : []);
        availableFilters = this._reorderFilters(availableFilters);
        return (

           	<div>
           		<button className={"collapse__button " + this.props.filterCollapseButton} onClick={this.props.collapseIn}><i className="fas fa-arrow-up"></i></button>
           		<div className={"selected__filters collapse collapse--open"}>
           			<div className="collapse__inner">
	 					<div className="container">
		 					<h3>{__.fs('Filters.index.selection')}</h3>
		 					<h2>{this.props.activeFilterCategory}</h2>

		 					{this.props.filterGroups.length && this.props.isLoggedIn ?
                                <React.Fragment>
		 						<button data-tip data-for="tip_edit" className="selected__filters__edit-button" onClick={this._togglePresetFilterManagePopup}>
				               		<i className="fas fa-list"></i>
				               	</button>
                                <ReactTooltip id="tip_edit" className='tooltip'>
                                    <span>{__.fs('Filters.index.edit-preferred-filters')}</span>
                                </ReactTooltip>
                                </React.Fragment>
				            : null }

				            {this.state.activeFilters.length && this.props.isLoggedIn ?
			 					<React.Fragment>
                                <button data-tip data-for="tip_save" className="selected__filters__save-button" onClick={this._togglePresetFilterPopup}>
				               		<i className="far fa-save"></i>
				               	</button>
                                <ReactTooltip id="tip_save" className='tooltip'>
                                    <span>{__.fs('Filters.index.save-preferred-filters')}</span>
                                </ReactTooltip>
                                </React.Fragment>
				            : null }
		 				</div>
		 				<div className="container container--small">
		 					<ul className="filters__breadcrumbs"><li>{this.props.resultCount} {__.fs('Filters.index.results')}</li>{this._renderPreFilterBreadcrumbs()}{this.state.activeFilters ? this.state.activeFilters.map((filter, key) => this._renderFilterBreadcrumbs(filter, key)) : null}</ul>
		 				</div>
		 			</div>
		 		</div>

           		<div className={"available__filters " }>
           			<div className="container">
                        { this.state.activeFilters.length ?
                            <div className="reset-wrapper">
                                <span onClick={this._clearFilters} className="reset-button">{__.fs('Filters.index.reset-all-filters')}</span>
                            </div>
                        : null }
		          		<div className="full__width filters">
                            { Object.keys(this.props.filterTree).length ?

    		          			<ul className="filters__list filters__list--categorized">
    		          			     { this._renderPresetFilters() }
    		          				 {availableFilters}
    		          			</ul>

                            :
                                <ul className="filters__list filters__list--loader">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            }
		               	</div>

		            </div>
               	</div>

               	{this.state.nestedPopupOpen ?
	               	<NestedFilterPopup
	               		rebuildFilterArray={rebuildFilterArray}
	               		findFilterExists={findExistingFilterIndex}
	               		popupData={this.state.nestedFilterPopup}
	               		activeFilters={this.state.activeFilters}
	               		allFilters={this.state.availableFilters}
	               		closeFilterPopup={this._closeNestedFilterPopup}
	               		popupOpen={this.state.nestedPopupOpen}
	               		filterConfigurations={this.props.filterConfigurations}
	               		setActiveFilters={this._setActiveFilters}
	               		findFilterIsAvailable={this._findFilterIsAvailable}
	               		findNumFilterAvailable={this._findNumFilterAvailable}
	               		allConfigurations={this.state.configurations}
                        findDeepest={this._findDeepestAndMapPropertiesToNumber}
	               	/>
	            : null}

	                  {this.state.presetFilterPopupOpen ?
	               	<PresetPopup
	               		popupOpen={this.state.presetFilterPopupOpen}
	               		togglePopup={this._togglePresetFilterPopup}
	               		filters={this.state.activeFilters}
	               	/>
	            : null}

	             {this.state.presetFilterManagePopup ?
	               	<PresetManagePopup
	               		popupOpen={this.state.presetFilterManagePopup}
	               		togglePopup={this._togglePresetFilterManagePopup}
	               	/>
	            : null}
            </div>

            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        filters: state.Filters,
        isLoggedIn : state.User.logged_in,
        filterTree : state.Configurations.full_filter_tree,
        allConfigurations : state.Configurations.configurations,
        filterGroups : state.User.filter_groups,
        kwh_electricity: state.User.kwh_electricity,
        water_usage : state.User.water_usage,
     	defaultSettings : state.User.default_settings
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( Filters ))
